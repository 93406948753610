/**
 * @filePath        : src\components\pages\home\TabBtn.js
 * @fileName        : TabBtn.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 메인 홈 화면의 모델 분류 탭버튼 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		     GHYANG				        1.0
 */
export default function TabBtn({ isActive, onClick, children }) {
  return (
    <button
      className={`tab-button ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <p>{children}</p>
    </button>
  );
}