/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity1\Enneagram.js
 * @fileName        : Enneagram.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 애니어그램 선택 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import bg from "assets/unsupervised/celebrity/mbtiBg.png";
import { useState, useEffect } from "react";
import { setType1, setType2, setType3, getType1, getType2, getType3 } from "./CelebrityData";

export default function Enneagram({ onChange }) {
    const [selections, setSelections] = useState([]);

    useEffect(() => {
        let newSelections = [];
        if (getType1() !== 0) {
            newSelections.push(getType1());
        }
        if (getType2() !== 0) {
            newSelections.push(getType2());
        }
        if (getType3() !== 0) {
            newSelections.push(getType3());
        }
        setSelections(newSelections);
    }, []);

    const handleClick = (rank) => {
        let updatedSelections;
        if (selections.includes(rank)) {
            // 이미 선택한 순위를 다시 클릭하면 선택 해제
            updatedSelections = selections.filter((selectedRank) => selectedRank !== rank);
            setSelections(updatedSelections);
            if (updatedSelections.length === 0) {
                setType1(0);
                setType2(0);
                setType3(0);
            } else if (updatedSelections.length === 1) {
                setType1(updatedSelections[0]);
                setType2(0);
                setType3(0);
            } else if (updatedSelections.length === 2) {
                setType1(updatedSelections[0]);
                setType2(updatedSelections[1]);
                setType3(0);
            } else if (updatedSelections.length === 3) {
                setType1(updatedSelections[0]);
                setType2(updatedSelections[1]);
                setType3(updatedSelections[2]);
            }
        } else {
            // 최대 3개까지 선택 가능하며 선택한 순서에 따라 순위가 부여됨
            if (selections.length < 3) {
                updatedSelections = [...selections, rank];
                setSelections(updatedSelections);

                if (updatedSelections.length === 0) {
                    setType1(0);
                    setType2(0);
                    setType3(0);
                } else if (updatedSelections.length === 1) {
                    setType1(updatedSelections[0]);
                    setType2(0);
                    setType3(0);
                } else if (updatedSelections.length === 2) {
                    setType1(updatedSelections[0]);
                    setType2(updatedSelections[1]);
                    setType3(0);
                } else if (updatedSelections.length === 3) {
                    setType1(updatedSelections[0]);
                    setType2(updatedSelections[1]);
                    setType3(updatedSelections[2]);
                }
            }
        }
    };

    // css 적용을 위한 classname 반환
    // const getRankForElement = (rank) => {
    //     if (selections.includes(rank)) {
    //         if (selections.indexOf(rank) === 0) {
    //             console.log("rank", rank, "first");
    //             return "first-choice";
    //         } else if (selections.indexOf(rank) === 1) {
    //             console.log("rank", rank, "second");
    //             return "second-choice";
    //         } else {
    //             console.log("rank", rank, "third");
    //             return "third-choice";
    //         }
    //     }
    //     console.log("rank", rank, "none");
    //     return "";
    // };



    useEffect(() => {
        if (getType1() === 0 || getType2() === 0 || getType3() === 0) {
            onChange(false);
        } else {
            onChange(true);
        }
    }, [onChange, selections]);


    return (
        <>
            <div style={{ padding: "0% 0%" }}>
                <h3 style={{ marginBottom: "0%" }}>
                    <b style={{ color: "#FED800" }}>• </b>나의 애니어그램 성향 중 적합한 1, 2, 3순위에 체크해 주세요.
                </h3>
                <p style={{ marginLeft: "1rem" }}>클릭한 순서대로 순위가 정해집니다.</p>
                <div className="diagram">
                    <div className="diagramList">
                        <img src={bg} alt="background img" />
                        <div className="diagramWrap mbti01">
                            <span className="diagram-space-right" onClick={() => handleClick(1)}>
                                <div
                                    className="diagramTitle diagram-right"
                                    id={
                                        selections[0] === 1 ? "first-choice" : selections[1] === 1 ? "second-choice" : selections[2] === 1 ? "third-choice" : ""
                                    }
                                >
                                    <p>1</p>
                                    <p className="dTitle">개혁가</p>
                                    <p>P</p>
                                    {selections[0] === 1 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 1 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 1 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">완벽한 사람</div>
                            </span>
                        </div>
                        <div className="diagramWrap mbti02">
                            <span className="diagram-space-right" onClick={() => handleClick(2)}>
                                <div
                                    className="diagramTitle diagram-right"
                                    id={
                                        selections[0] === 2 ? "first-choice" : selections[1] === 2 ? "second-choice" : selections[2] === 2 ? "third-choice" : ""
                                    }
                                >
                                    <p>2</p>
                                    <p className="dTitle">조력가</p>
                                    <p>P</p>
                                    {selections[0] === 2 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 2 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 2 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">필요한 사람</div>
                            </span>
                        </div>
                        <div className="diagramWrap mbti03">
                            <span className="diagram-space-right" onClick={() => handleClick(3)}>
                                <div
                                    className="diagramTitle diagram-right"
                                    id={
                                        selections[0] === 3 ? "first-choice" : selections[1] === 3 ? "second-choice" : selections[2] === 3 ? "third-choice" : ""
                                    }
                                >
                                    <p>3</p>
                                    <p className="dTitle">성취가</p>
                                    <p>C</p>
                                    {selections[0] === 3 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 3 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 3 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">능력있는 사람</div>
                            </span>
                        </div>

                        <div className="diagramWrap mbti04">
                            <span className="diagram-space-center" onClick={() => handleClick(4)}>
                                <div
                                    className="diagramTitle diagram-center"
                                    id={
                                        selections[0] === 4 ? "first-choice" : selections[1] === 4 ? "second-choice" : selections[2] === 4 ? "third-choice" : ""
                                    }
                                >
                                    <p>4</p>
                                    <p className="dTitle">예술가</p>
                                    <p>R</p>
                                    {selections[0] === 4 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 4 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 4 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">남다른 사람</div>
                            </span>
                        </div>
                        <div className="diagramWrap mbti05">
                            <span className="diagram-space-center" onClick={() => handleClick(5)}>
                                <div
                                    className="diagramTitle diagram-center"
                                    id={
                                        selections[0] === 5 ? "first-choice" : selections[1] === 5 ? "second-choice" : selections[2] === 5 ? "third-choice" : ""
                                    }
                                >
                                    <p>5</p>
                                    <p className="dTitle">탐구가</p>
                                    <p>C</p>
                                    {selections[0] === 5 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 5 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 5 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">생각하는 사람</div>
                            </span>
                        </div>
                        <div className="diagramWrap mbti06">
                            <span className="diagram-space-center" onClick={() => handleClick(6)}>
                                <div
                                    className="diagramTitle diagram-center"
                                    id={
                                        selections[0] === 6 ? "first-choice" : selections[1] === 6 ? "second-choice" : selections[2] === 6 ? "third-choice" : ""
                                    }
                                >
                                    <p>6</p>
                                    <p className="dTitle">충성가</p>
                                    <p>R</p>
                                    {selections[0] === 6 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 6 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 6 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">성실한 사람</div>
                            </span>
                        </div>

                        <div className="diagramWrap mbti07">
                            <span className="diagram-space-left" onClick={() => handleClick(7)}>
                                <div
                                    className="diagramTitle diagram-left"
                                    id={
                                        selections[0] === 7 ? "first-choice" : selections[1] === 7 ? "second-choice" : selections[2] === 7 ? "third-choice" : ""
                                    }
                                >
                                    <p>7</p>
                                    <p className="dTitle">열정가</p>
                                    <p>P</p>
                                    {selections[0] === 7 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 7 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 7 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">재미있는 사람</div>
                            </span>
                        </div>
                        <div className="diagramWrap mbti08">
                            <span className="diagram-space-left" onClick={() => handleClick(8)}>
                                <div
                                    className="diagramTitle diagram-left"
                                    id={
                                        selections[0] === 8 ? "first-choice" : selections[1] === 8 ? "second-choice" : selections[2] === 8 ? "third-choice" : ""
                                    }
                                >
                                    <p>8</p>
                                    <p className="dTitle">도전가</p>
                                    <p>R</p>
                                    {selections[0] === 8 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 8 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 8 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">힘있는 사람</div>
                            </span>
                        </div>
                        <div className="diagramWrap mbti09">
                            <span className="diagram-space-left" onClick={() => handleClick(9)}>
                                <div
                                    className="diagramTitle diagram-left"
                                    id={
                                        selections[0] === 9 ? "first-choice" : selections[1] === 9 ? "second-choice" : selections[2] === 9 ? "third-choice" : ""
                                    }
                                >
                                    <p>9</p>
                                    <p className="dTitle">화합가</p>
                                    <p>P</p>
                                    {selections[0] === 9 ? (
                                        <div className="diagram-check">1</div>
                                    ) : selections[1] === 9 ? (
                                        <div className="diagram-check">2</div>
                                    ) : selections[2] === 9 ? (
                                        <div className="diagram-check">3</div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="diagramTxt">편한 사람</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
