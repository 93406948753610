/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece1\MasterpieceData.js
 * @fileName        : MasterpieceData.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 데이터 관리 함수 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */

export function setPainterindex(data) {
    sessionStorage.setItem("painterindex", data);
}
export function getPainterindex() {
    return sessionStorage.getItem("painterindex") * 1;
}

export function setResultImg(data) {
    sessionStorage.setItem("resultimg", data);
}
export function getResultImg() {
    return sessionStorage.getItem("resultimg");
}
