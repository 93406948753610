/**
 * @filePath        : src\components\Footer.js
 * @fileName        : Footer.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 하단 푸터 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Link } from 'react-router-dom';
export default function Footer() {
  return (
    <footer>
      <img src={require('assets/footerlogo.png')} alt="img"></img>

      <div className='footer-desc'>
        <div className='footer-desc-first'><Link to="/termsofuse"><p>이용약관</p></Link></div>
        <p style={{fontWeight:"500", letterSpacing:"-0.7px", marginBottom:"1%"}}>(우) 41061 대구광역시 동구 동내로 64 TEL 053-714-0114 / FAX 053-714-0192</p>
        <p style={{marginTop:"0%"}}><b>Copyright © 2023 KERIS. ALL Rights Reserved.</b></p>
      </div>

    </footer>
  );
}