/**
 * @filePath        : src\components\pages\unsupervised\writing\writing1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 키워드로 작문하기 모델의 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "Config.js";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function Main() {
    const [loading, setLoading] = useState(true);
    const [sliderValue, setSliderValue] = useState("30");
    const [keywordList, setkeywordList] = useState([]);
    const [WordSelected, setWordSelected] = useState();
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [btntext, setBtntext] = useState("다음 단계로");
    const [WordSelectedResult, setWordSelectedResult] = useState();
    const [state, setState] = useState(1);

    const handleSliderChange = (event) => {
        setSliderValue(event.target.value);
        setBtntext("다음 단계로");
        setWordSelectedResult("");
        setState(1);
    };

    const sendWord = (e) => {
        setWordSelected(e.target.innerText);
        setBtntext("다음 단계로");
        setWordSelectedResult("");
        setState(1);
    };
    const closeModal = () => {
        setShowModal(false);
    };

    const handleNextClick = () => {
        if (btntext === "체험 종료") {
            window.location.href = "/";
        } else {
            if (WordSelected === undefined || WordSelected === "undefined") {
                setModalMessage("키워드를 선택해 주세요.");
                setShowModal(true);
            } else {
                setLoading(true);
                setBtntext("다음 단계로");
                setState(2);
                axios
                    .post(`${URL}/api/unsupervised/writing/result`, {
                        KEYWORD: { WordSelected },
                        LENGTH: { sliderValue },
                    })
                    .then((response) => {
                        setWordSelectedResult(response.data.data[0].TEXT);
                    })
                    .catch((error) => {
                        console.error("error:", error);
                    })
                    .finally(() => {
                        setLoading(false);
                        setBtntext("체험 종료");
                        setState(3);
                    });
            }
        }
    };

    useEffect(() => {
        axios
            .get(`${URL}/api/unsupervised/writing/word`)
            .then((response) => {
                setkeywordList(response.data.data);
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            {" "}
            {loading && <ProgressBar />}
            <Header learning="비지도학습">키워드로 작문하기 (언어 모델)</Header>
            <HeaderNav to1="/writingconcept" to2="/writing" />
            <main>
                <div className="main-step-img">
                    {state === 1 && (
                        <Step
                            step1="데이터 입력"
                            state1={0}
                            step2="모델 추론"
                            state2={2}
                            step3="결과 확인"
                            state3={2}
                        >
                            사전 학습된 딥러닝 모델을 이용하여 입력 받은
                            키워드로 짧거나 긴 문장을 작문하는 프로그램 입니다.
                            <br></br>(딥러닝 모델로 만들어진 문장이므로 완전하지
                            않은 문장일 수 있습니다)
                        </Step>
                    )}
                    {state === 2 && (
                        <Step
                            step1="데이터 입력"
                            state1={1}
                            step2="모델 추론"
                            state2={0}
                            step3="결과 확인"
                            state3={2}
                        >
                            사전 학습된 딥러닝 모델을 이용하여 입력 받은
                            키워드로 짧거나 긴 문장을 작문하는 프로그램 입니다.
                            <br></br>(딥러닝 모델로 만들어진 문장이므로 완전하지
                            않은 문장일 수 있습니다)
                        </Step>
                    )}
                    {state === 3 && (
                        <Step
                            step1="데이터 입력"
                            state1={1}
                            step2="모델 추론"
                            state2={1}
                            step3="결과 확인"
                            state3={0}
                        >
                            사전 학습된 딥러닝 모델을 이용하여 입력 받은
                            키워드로 짧거나 긴 문장을 작문하는 프로그램 입니다.
                            <br></br>(딥러닝 모델로 만들어진 문장이므로 완전하지
                            않은 문장일 수 있습니다)
                        </Step>
                    )}
                </div>

                <div className="white-back">
                    <div className="writing1-main">
                        <div className="writing1-left">
                            <h3
                                style={{ textAlign: "left", marginTop: "1rem" }}
                            >
                                모델 설정
                            </h3>

                            <div className="writing1-left-range">
                                <p>문장 길이</p>
                                <div className="writing1-left-range-slider">
                                    <div className="writing1-range-text">
                                        {/* <div className={`writing1-range-ele ${sliderValue === "20" ? "range-this" : ""}`}>20</div> */}
                                        <div
                                            className={`writing1-range-ele ${
                                                sliderValue === "30"
                                                    ? "range-this"
                                                    : ""
                                            }`}
                                        >
                                            30
                                        </div>
                                        <div
                                            className={`writing1-range-ele2 ${
                                                sliderValue === "40"
                                                    ? "range-this"
                                                    : ""
                                            }`}
                                        >
                                            40
                                        </div>
                                        <div
                                            className={`writing1-range-ele ${
                                                sliderValue === "50"
                                                    ? "range-this"
                                                    : ""
                                            }`}
                                        >
                                            50
                                        </div>
                                    </div>
                                    <input
                                        type="range"
                                        min="30"
                                        max="50"
                                        step="10"
                                        className="writing1-input-slider"
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                    />
                                    <datalist id="writing1-tickmarks">
                                        {/* <option value="20">|</option>
                                        <option value="20">|</option>
                                        <option value="20">|</option>
                                        <option value="20">|</option>
                                        <option value="20">|</option> */}
                                        <option value="30">|</option>
                                        <option value="30">|</option>
                                        <option value="30">|</option>
                                        <option value="30">|</option>
                                        <option value="30">|</option>
                                        <option value="40">|</option>
                                        <option value="40">|</option>
                                        <option value="40">|</option>
                                        <option value="40">|</option>
                                        <option value="40">|</option>
                                        <option value="50">|</option>
                                    </datalist>
                                    <datalist id="writing1-tickmarks2">
                                        {/* <option value="20">20</option> */}
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                    </datalist>
                                </div>
                            </div>

                            <div className="writing1-left-keyword">
                                <p style={{ width: "15%" }}>키워드</p>
                                {WordSelected && (
                                    <div>
                                        <p className="writing1-left-keyword-text">
                                            [{WordSelected}]
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="writing1-left-keywordselec">
                                <p style={{ marginTop: "0" }}>
                                    아래 키워드 중 한가지를 골라주세요.
                                </p>

                                <div className="writing1-left-keyword-space">
                                    {keywordList.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={(e) => sendWord(e)}
                                            style={{
                                                color:
                                                    WordSelected ===
                                                    item.KEYWORD
                                                        ? "#fed800"
                                                        : "white",
                                            }}
                                        >
                                            <p>{item.KEYWORD}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="writing1-right">
                            {WordSelectedResult && (
                                <>
                                    <p>{WordSelectedResult}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/writing"></GreyBtn>
                    <BlueBtn onClick={handleNextClick}>{btntext}</BlueBtn>
                </div>
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}>
                                <b>닫기</b>
                            </button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}
