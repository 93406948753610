/**
 * @filePath        : src\components\pages\supervised\genderage\genderage1\GenderageData.js
 * @fileName        : GenderageData.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 데이터 관리 함수 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */

export function setTrueGender(index, data) {
    let true_gender = JSON.parse(sessionStorage.getItem("true_gender"));
    if (true_gender == null) {
        true_gender = [];
    }
    true_gender[index] = data;
    sessionStorage.setItem("true_gender", JSON.stringify(true_gender));
}
export function getTrueGender() {
    return JSON.parse(sessionStorage.getItem("true_gender"));
}

export function setNewTrueGender(index, data) {
    let new_true_gender = JSON.parse(sessionStorage.getItem("new_true_gender"));
    if (new_true_gender == null) {
        new_true_gender = [];
    }
    new_true_gender[index] = data;
    sessionStorage.setItem("new_true_gender", JSON.stringify(new_true_gender));
}
export function getNewTrueGender() {
    return JSON.parse(sessionStorage.getItem("new_true_gender"));
}

export function setTrueAge(index, data) {
    let true_age = JSON.parse(sessionStorage.getItem("true_age"));
    if (true_age == null) {
        true_age = [];
    }
    true_age[index] = data;
    sessionStorage.setItem("true_age", JSON.stringify(true_age));
}
export function getTrueAge() {
    return JSON.parse(sessionStorage.getItem("true_age"));
}

export function setNewTrueAge(index, data) {
    let new_true_age = JSON.parse(sessionStorage.getItem("new_true_age"));
    if (new_true_age == null) {
        new_true_age = [];
    }
    new_true_age[index] = data;
    sessionStorage.setItem("new_true_age", JSON.stringify(new_true_age));
}
export function getNewTrueAge() {
    return JSON.parse(sessionStorage.getItem("new_true_age"));
}

export function getALGID() {
    var score = 0;

    const true_gender = JSON.parse(sessionStorage.getItem("true_gender"));
    const new_true_gender = JSON.parse(sessionStorage.getItem("new_true_gender"));
    const true_age = JSON.parse(sessionStorage.getItem("true_age"));
    const new_true_age = JSON.parse(sessionStorage.getItem("new_true_age"));

    for (var i = 0; i < true_gender.length; i++) {
        if (new_true_gender[i] !== true_gender[i]) {
            score += 2;
        }
        if (new_true_age[i] !== true_age[i]) {
            score += 1;
        }
    }
    if (score > 40) {
        return "A500";
    } else if (score > 20) {
        return "A501";
    } else {
        return "A502";
    }
}
