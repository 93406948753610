/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 단어 맞추기 모델의 단어 맞추기 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState } from "react";
import { setDatasetId2 } from "components/pages/supervised/wordmatch/wordmatch1/WordmatchData";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Talk from "components/pages/supervised/wordmatch/wordmatch2/Talk.js";
import Result from "components/pages/supervised/wordmatch/wordmatch2/Result.js";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    const [datasetId, setDatasetId] = useState(null);
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    const handleDatasetId = (data) => {
        setDatasetId(data);

        setDatasetId2(data);
    };

    const handleNextClick = () => {
        setModalMessage("모든 질문에 응답해야 케듀가 정답을 맞출 수 있어요!");
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="지도학습">단어 맞추기 (의사결정트리 모델)</Header>
            <HeaderNav to1="/wordmatchconcept" to2="/wordmatch" />
            <main>
                <div className="main-step-img">
                    <Step step1="단어 선택" state1={1} step2="단어 맞추기" state2={0} step3="결과 확인" state3={2}>
                    케듀의 질문을 통해 앞에서 선택한 단어에 대해 예/아니오로 대답해 보세요.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="wordmatch2-main">
                        <div className="wordmatch2-main-left">
                            <Talk sendDatasetId={handleDatasetId} />
                        </div>

                        <div className="wordmatch2-main-right">
                            <Result receiveData={datasetId} />
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/wordmatch/1"></GreyBtn>
                    {datasetId === null ? <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn> : <BlueBtn to="/wordmatch/3">다음 단계로</BlueBtn>}
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}>
                                <b>닫기</b>
                            </button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}
