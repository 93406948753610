/**
 * @filePath        : src\components\pages\supervised\emotion\emotion4\Result.js
 * @fileName        : Result.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 결과 화면 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";
import { getModelindex, getMODELID } from "components/pages/supervised/emotion/emotion1/EmotionData";
import ResultTable from "components/pages/supervised/emotion/emotion4/ResultTable";
import User from "components/pages/supervised/emotion/emotion4/User";
export default function Result() {
    const [loading, setLoading] = useState(true); // 로딩 상태값
    const [data, setData] = useState([]);
    const [nicklist, setNicklist] = useState([]);
    const [selectedindex, setSelectedIndex] = useState(0);

    //선택된 모델에 따른 파라미터 값을 서버에 전달하고 그에 맞는 데이터 받기
    useEffect(() => {
        const params = { ALG_ID: getModelindex(), MODEL_ID: getMODELID() };
        axios
            .get(`${URL}/api/supervised/textmodel/result`, { params })
            .then((result) => {
                setData(result.data.list);
                setNicklist(result.data.nicklist);
            })
            .catch((error) => {
                console.error('error:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleChange = (newValue) => {
        setSelectedIndex(newValue);
    };

    return (
        <>
            {loading && <ProgressBar />}

            <div className="emotion4-main">
                <div className="emotion4-coment-list">
                    {data.map((item, index) => (
                        <User
                            key={index}
                            TRUE_LABEL={item.TRUE_LABEL}
                            NICKNAME={nicklist[index].NICKNAME}
                            DOCUMENT={item.DOCUMENT}
                            index={index}
                            onChange={handleChange}
                            selectedindex={selectedindex}
                        />
                    ))}

                </div>
                <div className="emotion4-analysis-rusult">
                    <ResultTable
                        data={data}
                        index={selectedindex}
                    />
                </div>
            </div>
        </>
    );
}

