/**
 * @filePath        : src\components\pages\supervised\weather\weather1\Element.js
 * @fileName        : Element.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 기상 요소 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from 'react';
import Cal from 'components/pages/supervised/weather/weather1/Cal';

import { setSelectedelementnames, getSelectedelementnames } from 'components/pages/supervised/weather/weather1/WeatherData';

export default function Element() {
    const [checkboxes, setCheckboxes] = useState({
        "평균 기온": false,
        "최저 기온": false,
        "최고 기온": false,
        "강수량": false,
        "습도": false,
        "요일": false,
        "일조 시간": false,
        "일출 시간": false
    });
    const [selectedCheckboxNames, setSelectedCheckboxNames] = useState(getSelectedelementnames());

    useEffect(() => {
        if (getSelectedelementnames().includes("평균 기온")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "평균 기온": true, })); }
        if (getSelectedelementnames().includes("최저 기온")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "최저 기온": true, })); }
        if (getSelectedelementnames().includes("최고 기온")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "최고 기온": true, })); }
        if (getSelectedelementnames().includes("강수량")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "강수량": true, })); }
        if (getSelectedelementnames().includes("습도")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "습도": true, })); }
        if (getSelectedelementnames().includes("요일")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "요일": true, })); }
        if (getSelectedelementnames().includes("일조 시간")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "일조 시간": true, })); }
        if (getSelectedelementnames().includes("일출 시간")) { setCheckboxes(prevCheckboxes => ({ ...prevCheckboxes, "일출 시간": true, })); }
    }, []);

    // 체크된 기상 요소 값 추가
    useEffect(() => {
        setSelectedelementnames(selectedCheckboxNames);
    }, [selectedCheckboxNames]);



    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;


        setCheckboxes(prevCheckboxes => ({
            ...prevCheckboxes,
            [name]: checked
        }));

        setSelectedCheckboxNames(prevNames => {
            if (checked) {
                return [...prevNames, name];
            } else {
                return prevNames.filter(item => item !== name);
            }
        });
    };

    const renderCheckboxes = () => {

        return Object.keys(checkboxes).map((checkboxName) => (
            <label key={checkboxName} className="checkbox-label">

                <span className={`checkbox-name ${checkboxes[checkboxName] ? 'checked' : ''}`}>
                    <input
                        type="checkbox"
                        id={checkboxName}
                        className="checkbox-input"
                        name={checkboxName}
                        checked={checkboxes[checkboxName]}
                        onChange={handleCheckboxChange}
                    />
                    <span className="checkbox-custom"></span>

                    {checkboxName}</span>

            </label>
        ));
    };

    return (
        <div className="calendar-space">
            <div className="calendar-space-left">
                <div className="calendar-check-compo">
                    {renderCheckboxes()}
                </div>
            </div>
            <div className="calendar-space-right">
                <Cal selectedElements={checkboxes} />
            </div>
        </div>
    );
}