/**
 * @filePath        : src\components\pages\home\Heading.js
 * @fileName        : Heading.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 메인 홈 화면 헤더
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
export default function Heading() {
    return (
        <div className="home-header">
            <div style={{ width: "60%" }}>
                <p>AI 체험 프로그램</p>
                <div className="home-header-underbar"></div>
                <p className="home-header-desc">
                    EDU AI는 다양한 AI프로그램을 온라인으로 체험할 수 있는 플랫폼 입니다.
                    <br />
                    프로그램을 통해 AI를 체험하고 개념을 이해해보세요.
                </p>
            </div>
            <img src={require("assets/homeheader.png")} alt="img"></img>
        </div>
    );
}
