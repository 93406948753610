/**
 * @filePath        : src\components\pages\reinforcement\selfdriving\selfdriving2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 자율 주행 자동차 만들기 모델의 모델 이해 단계 스텝형식으로 설명
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import greenboard from "assets/reinforcement/cartpole/greenboard.png";
import star from "assets/reinforcement/selfdriving/underline.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn"
import Step from "components/sections/boxs/Step";
import { useState } from "react";

export default function Main() {
    const [step, setStep] = useState(0);
    return (
        <>
            <Header learning="강화학습">자율 주행 자동차 만들기</Header>
            <HeaderNav to1="/selfdrivingconcept" to2="/selfdriving" />

            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={1} step2="모델 이해" state2={0} step3="결과 확인" state3={2}>
                        아래 선생님의 설명을 읽고 강화학습의 개념을 이해해봅시다.
                    </Step>
                </div>
                {step === 0 && <>
                    <div className="selfdriving2-main">
                        <img src={greenboard} alt="greenboard" />
                        <div className="selfdriving2-main-desc">
                            <div className="selfdriving2-underline-zone">
                                <img src={star} alt="underline" />
                                <h2>강화 학습은 어떠한 원리로 이루어질까요?</h2>
                            </div>
                            <h3>1. 실험과 보상</h3>
                            <div className="selfdriving2-main-step-desc">
                                <p>
                                    자동차가 똑똑해지려면 먼저 실험과 보상을 이해해야 해요. 이것은 자동차가 어떤 일을
                                    해서 좋은 보상을 받는지, 그리고 나쁜 일을 해서 어떤 처벌을 받는지 알아야 한답니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </>}
                {step === 1 && <>
                    <div className="selfdriving2-main">
                        <img src={greenboard} alt="greenboard" />
                        <div className="selfdriving2-main-desc">
                            <h3>2. 자동차의 선택</h3>
                            <div className="selfdriving2-main-step-desc">
                                <p>
                                    자동차는 주변 상황에서 어떤 선택을 해야 할지 고민해요. 예를 들어, 신호등이 빨간 불일 때
                                    멈춰야 하는지, 초록 불일 때 가야 하는지를 스스로 결정하려고 해요.
                                </p>
                                <p>
                                    <b>올바른 선택을 보상</b><br />
                                    자동차가 올바른 선택을 하면, 컴퓨터가 그것을 옳은 일로 인정하고 보상을 줘요.
                                    이렇게 하면 자동차는 똑똑하게 선택하는 방법을 배우게 되어요.
                                </p>
                                <p>
                                    <b>잘못된 선택을 처벌</b><br />
                                    하지만 자동차가 잘못된 선택을 하면, 컴퓨터가 그것을 깨달음과 함께 처벌을 줘요.
                                    그래서 자동차는 다음에는 그런 선택을 하지 않게 되어요.
                                </p>
                            </div>
                        </div>
                    </div>
                </>}
                {step === 2 && <>
                    <div className="selfdriving2-main">
                        <img src={greenboard} alt="greenboard" />
                        <div className="selfdriving2-main-desc">
                            <h3>3. 계속해서 학습</h3>
                            <div className="selfdriving2-main-step-desc">
                                <p>
                                    이런 방식으로 자동차는 계속해서 주행 중에 배우게 되어요. 그래서 더 나은 운전자가
                                    되어서 우리를 안전하게 목적지로 데려다 줄 수 있어요.
                                </p><p>
                                    이렇게 강화학습은 자동차가 주변 상황을 이해하고 어떻게 행동해야 하는지를 스스로
                                    학습하는 과정이에요. 이 과정을 통해 자동차는 우리 모두를 안전하게 운송해 줄 수 있게
                                    됩니다.
                                </p>

                            </div>
                        </div>
                    </div>
                </>}
                {step === 3 && <>
                    <div className="white-back">
                        <div>
                            <h3>강화 학습 모델이 1시간 학습되는 과정입니다.</h3>
                        </div>

                        <video
                            muted
                            autoPlay
                            height="100%"
                            className="selfdriving2-simulation">
                            <source src={"/videos/selfdriving/1hr_training.mp4"} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                    </div>
                </>}


                <div className="main-btn">
                    {step === 0 && <>
                        <GreyBtn to="/selfdriving/1"></GreyBtn>
                        <BlueBtn onClick={() => setStep(step + 1)}>다음 단계로</BlueBtn>
                    </>}
                    {(step > 0 && step < 3) && <>
                        <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                        <BlueBtn onClick={() => setStep(step + 1)}>다음 단계로</BlueBtn>
                    </>}

                    {step === 3 && <>
                        <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                        <BlueBtn to="/selfdriving/3">모델 실행</BlueBtn>
                    </>}
                </div>
            </main>
        </>
    );
}