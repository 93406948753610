/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient3\Table.js
 * @fileName        : Table.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 모델 데이터 테이블 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import { getModelindex } from "components/pages/supervised/coldpatient/coldpatient2/Modelindex";
import ProgressBar from "components/sections/boxs/ProgressBar";
export default function Table() {
    const [data, setData] = useState(null);

    //선택된 모델에 따른 파라미터 값을 서버에 전달하고 그에 맞는 데이터 받기
    useEffect(() => {
        const modelindex = getModelindex();
        let modelnum = null;

        if (modelindex === 1) { modelnum = "A400" }
        else if (modelindex === 2) { modelnum = "A401" }
        else if (modelindex === 3) { modelnum = "A404" }
        else if (modelindex === 4) { modelnum = "A402" }
        else if (modelindex === 5) { modelnum = "A403" }

        const params = { ALG_ID: modelnum };

        axios
            .get(`${URL}/api/model/algresult`, { params })
            .then((result) => {
                const responseData = JSON.parse(result.data.detailinfo.MODEL_EVAL_RESULT);
                setData(responseData);
            })
            .catch((error) => {
                console.error('error:', error);
            });
    }, []);

    if (data === null) {
        return <ProgressBar />;
    }

    return (
        <>
            <div className="coldpatient3-table-space">
                <div className="coldpatient3-table-container">
                    <table className="coldpatient3-table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Precision</th>
                                <th scope="col">Recall</th>
                                <th scope="col">F1-score</th>
                                <th scope="col">Support</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>0</td>
                                <td>{data["0"]["precision"].toFixed(2)}</td>
                                <td>{data["0"]["recall"].toFixed(2)}</td>
                                <td>{data["0"]["f1-score"].toFixed(2)}</td>
                                <td>{data["0"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>{data["1"]["precision"].toFixed(2)}</td>
                                <td>{data["1"]["recall"].toFixed(2)}</td>
                                <td>{data["1"]["f1-score"].toFixed(2)}</td>
                                <td>{data["1"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>Accuracy</td>
                                <td></td>
                                <td></td>
                                <td>{data["accuracy"].toFixed(2)}</td>
                                <td>{data["macro avg"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>Macro avg</td>
                                <td>{data["macro avg"]["precision"].toFixed(2)}</td>
                                <td>{data["macro avg"]["recall"].toFixed(2)}</td>
                                <td>{data["macro avg"]["f1-score"].toFixed(2)}</td>
                                <td>{data["macro avg"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>Weighted avg</td>
                                <td>{data["weighted avg"]["precision"].toFixed(2)}</td>
                                <td>{data["weighted avg"]["recall"].toFixed(2)}</td>
                                <td>{data["weighted avg"]["f1-score"].toFixed(2)}</td>
                                <td>{data["weighted avg"]["support"].toFixed(0)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}


