/**
 * @filePath        : src\components\pages\supervised\emotion\emotion4\ResultTable.js
 * @fileName        : ResultTable.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 선택된 댓글에 따른 결과 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useEffect, useState } from "react";
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function ResultTable({ index, data }) {
    const [labeldata, setLabelData] = useState([]);
    const [tabledata, setTableData] = useState([]);


    useEffect(() => {
        if (data[index]) {
            setLabelData(JSON.parse(data[index].LABEL));
            setTableData(JSON.parse(data[index].ATTR.replace(/\\n/g, "\\n").replace(/'/g, '"')));
        }
    }, [index, data]);

    if (labeldata[0] === undefined || tabledata === undefined) return <ProgressBar />;


    return (
        <>
            <div className="emotion4-resulttable-space">
                <h3><b style={{ color: "#FED800" }}>• </b> 감정 분석 결과</h3>

                <div className="emotion4-grid-top">
                    <div className="emotion4-tree-top">
                        <div className="emotion4-tree-top-text">
                            <div id="emotion4-neg"></div><p>Negative</p>
                            <div id="emotion4-neu"></div><p>Neutral</p>
                            <div id="emotion4-pos"></div><p>Positive</p>
                        </div>
                    </div>

                    <div>
                        <div className="emotion4-top-table">
                            <div id="border">True<br />Label</div>
                            <div id="border">Predicted<br />Label</div>
                            <div id="border">Attribution<br />Label</div>
                            <div id="border">Attribution<br />Score</div>
                            <div id="border">Word Importance</div>


                            <>
                                <span>
                                    <p>{labeldata[0]["True Label"]}</p>
                                </span>
                                <span>
                                    <p>{labeldata[0]["Predicted Label"]}</p>
                                </span>
                                <span>
                                    <p>{labeldata[0]["Attribution Label"]}</p>
                                </span>
                                <span>
                                    <p>{labeldata[0]["Attribution Score"]}</p>
                                </span>
                                <span>
                                    <p dangerouslySetInnerHTML={{ __html: data[index].WORD }}></p>
                                </span>
                            </>

                        </div>
                    </div>
                </div>


                <div className="emotion4-grid-container">
                    <div className="emotion4-grid" >
                        <p>Tokenize</p>
                        <p style={{ borderLeft: "solid 3px #ddd" }}>Attribution</p>
                    </div>


                    <div className="emotion4-grid-inside">
                        {tabledata.map((item, index) => (
                            <div className="emotion4-grid" key={index}>
                                <p style={{ textAlign: "center" }}>{item.token}</p>
                                <p>{item.attr}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
