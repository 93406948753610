/**
 * @filePath        : src\components\pages\supervised\weather\weather1\Cal.js
 * @fileName        : Cal.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 달력 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import styled from "styled-components";
import Num from "components/pages/supervised/weather/weather1/Num";
import { useEffect, useState } from "react";
import axios from "axios";
import { URL } from 'Config.js';


const Day = styled.div`
padding : 5px;
background-color: #EEEEEE;
color : black;
height : 2rem;
font-size: 15px;
font-weight: bold;
display:flex;
justify-content: center;
align-items: center;
`;

export default function Cal({ selectedElements }) {
  const [averagetemp, setAveragetemp] = useState([]);
  const [lowesttemp, setLowesttemp] = useState([]);
  const [highesttemp, setHighesttemp] = useState([]);
  const [rainfall, setRainfall] = useState([]);
  const [humidity, setHumidity] = useState([]);
  const [day, setDay] = useState([]);
  const [light, setLight] = useState([]);
  const [sunrise, setSunrise] = useState([]);
  const [weather, setWeather] = useState([]);

  const date = [
    '8/1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
  ];

  useEffect(() => {
    axios
      .get(`${URL}/api/supervised/weather/elementdata`)
      .then((result) => {
        //console.log(result.data.realdata);
        setAveragetemp(result.data.averagetemp);
        setLowesttemp(result.data.lowesttemp);
        setHighesttemp(result.data.highesttemp);
        setRainfall(result.data.rainfall);
        setHumidity(result.data.humidity);
        setDay(result.data.day);
        setLight(result.data.sunshine);
        setSunrise(result.data.sunrise);
        setWeather(result.data.weather);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  }, []);


  return (
    <div className="calender">
      <Day><p style={{color:"#ff0000"}}>일</p></Day>
      <Day>월</Day>
      <Day>화</Day>
      <Day>수</Day>
      <Day>목</Day>
      <Day>금</Day>
      <Day><p style={{color:"#0084ff"}}>토</p></Day>
      <div className="cal-blank" />
      {date.map((date, index) => (
        <Num
          key={date}
          weather={weather[index]}
          date={date}
          averagetemp={selectedElements['평균 기온'] ? averagetemp[index] : false}
          lowesttemp={selectedElements['최저 기온'] ? lowesttemp[index] : false}
          highesttemp={selectedElements['최고 기온'] ? highesttemp[index] : false}
          rainfall={selectedElements['강수량'] ? rainfall[index] : false}
          humidity={selectedElements['습도'] ? humidity[index] : false}
          day={selectedElements['요일'] ? day[index] : false}
          light={selectedElements['일조 시간'] ? light[index] : false}
          sunrise={selectedElements['일출 시간'] ? sunrise[index] : false}
        >
        </Num>
      ))}
      <div className="cal-blank" />
      <div className="cal-blank" />
      <div className="cal-blank" />
    </div>
  );
}