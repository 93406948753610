/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrityintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import Card from "components/sections/cards/Card";
import inputData from "assets/unsupervised/celebrity/inputData.png";
import analyzeClustering from "assets/unsupervised/celebrity/analyzeClustering.png";
import checkResult from "assets/unsupervised/celebrity/checkResult.png";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    const handleNextClick = () => {
        window.location.href = "/celebrity/1";
        sessionStorage.clear();
    };
    return (
        <>
            <Header learning="비지도학습">나와 유사한 유명인 찾기 (군집화 모델)</Header>
            <HeaderNav to1="/celebrityconcept" to2="/celebrity" />
            <Conceptcheck>클러스터링은 비지도학습의 대표적인 방법으로 주어진 데이터를 비슷한 유형끼리 군집화하여 구별하는 기법입니다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            본 프로그램은 유명인의 MBTI와 9가지 성격유형을 나타내는 에니어그램의 데이터를 바탕으로 군집화하고 나의 데이터를 입력하여 나와 비슷한
                            성향을 가진 유명인들을 찾아보는 과정을 체험할 수 있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/01_나와 유사한 유명인 찾기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={inputData}>
                            데이터 입력
                        </Card>
                        <Card btn="Step 2" img={analyzeClustering}>
                            군집 분석
                        </Card>
                        <Card btn="Step 3" img={checkResult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn onClick={handleNextClick}>시작하기</BlueBtn>
            </main>
        </>
    );
}
