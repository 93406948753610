/**
 * @filePath        : src\components\pages\unsupervised\news\news2\PretrainedModel.js
 * @fileName        : PretrainedModel.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 학습 모델 결과 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Models from "components/pages/unsupervised/news/news2/Models";
import Grid from "components/pages/unsupervised/news/news2/Grid";
import ProgressBar from "components/sections/boxs/ProgressBar";
import { useState } from "react";
import axios from "axios";
import { URL } from "Config.js";

export default function PretrainedModel({ step, onStateChange }) {
    const [ItemSelected, setItemSelected] = useState([]);
    const [ModelSelected, setModelSelected] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [newstext, setNewstext] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleItemSelected = (text) => {
        setItemSelected(text);
    };

    const handleModelSelected = (model) => {
        setModelSelected(model);
    };

    const onStateHandleChange = (value) => {
        onStateChange(value);
    };

    const handleNextClick = () => {
        const requestData = {
            selectedmodel: ModelSelected,
            selecteditem: ItemSelected,
        };
        if (step === 1) {
            if (ModelSelected === null && ItemSelected.length === 0) {
                setModalMessage("모델과 단어를 선택해주세요 !");
                setShowModal(true);
            } else if (ModelSelected === null) {
                setModalMessage("모델을 선택해주세요 !");
                setShowModal(true);
            } else if (ItemSelected.length === 0) {
                setModalMessage("단어를 최소 한 가지 이상 선택해주세요 !");
                setShowModal(true);
            } else {
                setLoading(true);
                onStateChange(step + 1);

                //키워드 오름차순 정렬
                const temp = requestData.selecteditem.sort((a, b) => {
                    if (a.index > b.index) return 1;
                    if (a.index < b.index) return -1;
                    return 0;
                });
                requestData.selecteditem = temp.map((item) => {
                    return item.text;
                });

                axios
                    .post(`${URL}/api/unsupervised/news/newsdata`, requestData)
                    .then((response) => {
                        setNewstext(response.data.data);
                    })
                    .catch((error) => {
                        console.log("error: " + error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } else {

        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {loading && <ProgressBar />}
            <div className="white-back">
                <div className="news2">
                    <div className="news2-main">
                        <div className="news2-main-content" id="news2-main-content-left">
                            <p style={{ textAlign: "left" }}>
                                {/* <b>ㅤㅤ🟡 1. 모델 설정</b> */}
                                <b>ㅤㅤ1. 사전 학습된 모델 중 하나 선택</b>
                            </p>
                            <div className="news-models">
                                <Models onModelSelected={handleModelSelected} step={step} onStateChange={onStateHandleChange}></Models>
                            </div>

                            <div style={{ marginTop: "2rem" }}>
                                <p style={{ textAlign: "left" }}>
                                    <b>ㅤㅤ2. 글 쓸 주제를 한 가지 이상 선택</b>
                                </p>

                                <div className="news2-gridzone">
                                    <Grid onItemSelected={handleItemSelected} step={step} onStateChange={onStateHandleChange} />
                                </div>
                            </div>
                        </div>

                        <div className="news2-main-content" id="news2-main-content-right">
                            {step === 2 && (
                                <div className="news2-textzone">
                                    <p>{newstext && newstext}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                {step === 1 && (
                    <>
                        <GreyBtn to="/news/1" />
                        <BlueBtn onClick={handleNextClick}>분석 시작</BlueBtn>
                    </>
                )}
                {step === 2 && (
                    <>
                        <GreyBtn to="/news/1" />
                        <BlueBtn to="/">체험 종료</BlueBtn>
                    </>
                )}
            </div>
            {showModal && (
                <div className="modal-back">
                    <div className="modal">
                        <h3>{modalMessage}</h3>
                        <button onClick={closeModal}>
                            <b>닫기</b>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
