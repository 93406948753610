/**
 * @filePath        : src\components\pages\supervised\emotion\emotion1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 데이터 입력 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import Grid from "components/pages/supervised/emotion/emotion1/Grid";
import Result from "components/pages/supervised/emotion/emotion1/Result";
import { getNewTrueEmotion } from "components/pages/supervised/emotion/emotion1/EmotionData";
import { useState } from "react";

export default function Main() {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [step, setStep] = useState(0);


    //선택되지 않은 셀렉트가 있을 경우 모달창, 그렇지 않으면 다음 스텝
    const handleClick = () => {
        if (step === 0) {
            const trueemotion = getNewTrueEmotion();
            let flag = true;

            for (var i = 0; i < trueemotion.length; i++) {
                if (trueemotion[i] === null || trueemotion[i] === "") {
                    flag = false;
                }
            }
            if (flag) { setStep(1); }
            else {
                setModalMessage("Label을 모두 선택해주세요.");
                setShowModal(true);
            }
        }
        else {
            setStep(0);
        }
    }
    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <Header learning="지도학습">텍스트 감정 분석하기 (텍스트 분류모델)</Header>
            <HeaderNav to1="/emotionconcept" to2="/emotion" />
            <main>
                <div className="main-step-img">

                    {step === 0 && <>
                        <Step step1="데이터 입력" state1={0} step2="텍스트 감정 분석" state2={2} step3="모델 평가" state3={2} step4="결과 확인" state4={2}>
                            텍스트 감정 분석 모델의 학습에 활용하는 데이터입니다. <br />
                            훈련 데이터에 각각 레이블(정답)을 지정하는 단계입니다.
                            리뷰를 읽고 해당 리뷰가 긍정적인지 부정적인지 선택해 봅시다.
                        </Step>
                    </>}
                    {step === 1 && <>
                        <Step step1="데이터 입력" state1={0} step2="텍스트 감정 분석" state2={2} step3="모델 평가" state3={2} step4="결과 확인" state4={2}>
                            컴퓨터는 자연어를 그대로 이해하기 어렵습니다. 그렇기 때문에 컴퓨터가 이해할 수 있도록 처리하는 과정이 반드시 필요합니다.
                            <br />이 과정을 위해 주어진 문장을 더 작은 단위인 토큰으로 분해하여 분석합니다.
                        </Step>
                    </>}

                </div>

                <div className="white-back">
                    {step === 0 && <>
                        <Grid />
                    </>}
                    {step === 1 && <>
                        <Result />
                    </>}
                </div>


                <div className="main-btn">
                    {step === 0 && <>
                        <GreyBtn to="/emotion"></GreyBtn>
                        <BlueBtn onClick={handleClick}>다음 단계로</BlueBtn>
                    </>}
                    {step === 1 && <>
                        <GreyBtn onClick={handleClick}></GreyBtn>
                        <BlueBtn to="/emotion/2">다음 단계로</BlueBtn>
                    </>}

                </div>
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}

