/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic1\GridCell.js
 * @fileName        : GridCell.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델 게임 얼음판 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
const GridCell = ({ isPenguin, isMine, isMineHit, isGoal}) => {
    const cellClass = `grid-cell 
    ${isPenguin ? 'penguin' : ''} 
    ${isMine ? 'mine' : ''} 
    ${isMine && isMineHit ? 'hit' : ''} 
    ${isGoal ? 'goal' : ''} `;


    return <div className={cellClass}></div>;
};
export default GridCell;

/*holes
(0,3) (1,1) (3,1) (3,2) (2,4) (4,3)
*/

