/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity2\Chart.js
 * @fileName        : Chart.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 군집분석 결과 차트 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from "chart.js";
import { Scatter } from "react-chartjs-2";

export default function Chart(props) {
    const { receiveLabels, receiveData } = props;

    ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

    const options = {
        scales: {
            x: {
                beginAtZero: true,
                max: 18,
                title: {
                    display: true,
                    text: "애니어그램",
                },
            },
            y: {
                beginAtZero: true,
                max: 18,
                title: {
                    display: true,
                    text: "MBTI",
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const id = context.raw.id;
                        const x = context.raw.x.toFixed(2);
                        const y = context.raw.y.toFixed(2);
                        return id + ": " + x + ", " + y;
                    },
                },
            },
            datalabels: {
                display: false,
            },
        },
    };

    const backgroundColors = ["orange", "green", "yellow", "blue", "purple"];
    const datasets = receiveLabels.map((item, index) => {
        const label = "군집 " + item.LABEL;
        const list = receiveData.filter((i) => i.LABEL === item.LABEL);
        const data = list.map((item2, index2) => {
            return { x: item2.ATYPE, y: item2.PERSONALITY, id: item2.DATASET_ID };
        });

        return { label: label, data: data, backgroundColor: backgroundColors[index] };
    });

    const data = {
        datasets: datasets,
    };

    return (
        <>
            <div className="celebrity2-chart">
                <h3><b>•</b>군집분석 결과</h3>
                <Scatter options={options} data={data} />
            </div>
        </>
    );
}
