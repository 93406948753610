/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic3\Learningmodel.js
 * @fileName        : Learningmodel.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델의 학습 결과 화면 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState } from "react";
import videofirst from "assets/reinforcement/arctic/video_firstimg.png";
import resultfirst from "assets/reinforcement/arctic/resultfirst.png";
import result100 from "assets/reinforcement/arctic/arctic_100_result.png";
import result1000 from "assets/reinforcement/arctic/arctic_1000_result.png";
import result5000 from "assets/reinforcement/arctic/arctic_5000_result.png";


export default function Learningmodel({ src1, src2, src3 }) {
    const [selectedSrc, setSelectedSrc] = useState("");

    const handleSelect = (event) => {
        setSelectedSrc(event.target.value);
    };

    return (
        <>
            <div className="white-back">
                <div style={{ height: "4rem" }}>
                    <h2 style={{ margin: "0" }}>학습 결과 확인</h2>
                </div>
                <div className="arctic3-main">
                    <div className="arctic3-main-left">

                        {selectedSrc === "" && <img src={videofirst} alt="firstimg" />}
                        {selectedSrc === src1 &&
                            <div className="arctic3-simulation">
                                <video muted autoPlay height="100%">
                                    <source src={src1} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>}
                        {selectedSrc === src2 &&
                            <div className="arctic3-simulation">
                                <video muted autoPlay height="100%">
                                    <source src={src2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>}
                        {selectedSrc === src3 &&
                            <div className="arctic3-simulation">
                                <video muted autoPlay height="100%">
                                    <source src={src3} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>}

                    </div>
                    <div className="arctic3-main-right">
                        <h4 style={{ margin: "0" }}>⬇️ 버튼을 클릭해 학습 결과를 확인하세요 !</h4>
                        <select value={selectedSrc} onChange={handleSelect} className="arctic3-select">
                            <option value={""}>선택</option>
                            <option value={src1}>길찾기 100회 학습모델</option>
                            <option value={src2}>길찾기 1000회 학습모델</option>
                            <option value={src3}>길찾기 5000회 학습모델</option>
                        </select>

                        {selectedSrc === "" && <img src={resultfirst} alt="result img" />}
                        {selectedSrc === src1 && <img src={result100} alt="result img" />}
                        {selectedSrc === src2 && <img src={result1000} alt="result img" />}
                        {selectedSrc === src3 && <img src={result5000} alt="result img" />}

                    </div>
                </div>
            </div>
        </>
    );
}