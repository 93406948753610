/**
 * @filePath        : src\components\sections\boxs\CarouselItem.js
 * @fileName        : CarouselItem.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 개념학습 예시 캐러셀 개별 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function CarouselItem({ image, title, desc1, desc2,page, onPrevSlide, onNextSlide }) {
    return (
        <div className="carousel">
            <div className="carousel-img" >
                <img src = {image} alt = "example img"/>
            </div>
            <div className="carousel-caption">
                <div className="carousel-caption-desc">
                    <h3 style={{ marginBottom: "0" }}>{title}</h3>
                    <img src={require('assets/point.png')} alt="img" />
                    <p>{desc1}</p>
                    <p>{desc2}</p>
                </div>
                <div className="carousel-caption-button">
                    <div>
                        <p>{page}</p>
                    </div>
                    <div>
                        <button onClick={onPrevSlide}>&lt;</button>
                        <button onClick={onNextSlide}>&gt;</button>
                    </div>

                </div>
            </div>
        </div>
    );
}