/**
 * @filePath        : src\components\pages\supervised\weather\weather2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 모델 이해 단계 메인 화면 설명
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import multinomial from "assets/supervised/weather/multinomialmodel.png";
import Step from "components/sections/boxs/Step";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">날씨에 따른 전기 사용량 예상 (회귀 모델)</Header>
            <HeaderNav to1="/weatherconcept" to2="/weather" />
            <main>
                <div className="main-step-img">
                    <Step step1="변수 선택" state1={1} step2="모델 이해" state2={0} step3="모델 학습" state3={2} step4="모델 평가" state4={2}>
                        데이터의 관계를 예측하는 데에는 사용되는 데이터에 따라 여러가지 회귀 모형을 사용할 수 있습니다.<br />
                        이번 체험에서는 다중 선형 회귀 모델을 이용하여 분석을 진행하겠습니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="multinomial-model">
                        <div className="multinomial-model-left">
                            <img src={multinomial} alt="multinomial-model" />
                        </div>
                        <div className="multinomial-model-right">
                            <h2 style={{ margin: "0" }}>다중 선형 회귀</h2>
                            <img src={require('assets/point.png')} alt="point" />
                            <p>
                                다중 선형 회귀 모델은 회귀는 여러 개의 독립 변수와 하나의 종속 변수의 선형 관계를 분석하는 것입니다.
                                쉽게 말해, 둘 이상의 변수들이 함께 종속 변수를 예상하거나 설명하는 관계를 찾아내는 것을 의미합니다.
                            </p>
                            <p>
                                이번 체험에서 종속 변수인 <b>전기 사용량</b>을 예상하기 위해 독립 변수인
                                <b>평균 기온, 강수량, 습도</b> 등 여러 가지 날씨 변수들을 사용하고 있습니다.
                                이와 같은 회귀 분석을 다중 선형 회귀식이라고 합니다.
                            </p>
                        </div>
                    </div>

                </div>


                <div className="main-btn">
                    <GreyBtn to="/weather/1"></GreyBtn>
                    <BlueBtn to="/weather/3">모델 학습 실행</BlueBtn>
                </div>
            </main>
        </>
    );
}