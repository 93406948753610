/**
 * @filePath        : src\components\pages\supervised\object\object3\Result.js
 * @fileName        : Result.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 결과 이미지 그리드 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import ResultItem from "components/pages/supervised/object/object3/ResultItem";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';

export default function Result() {
  const [imgs, setImgs] = useState([]);
  const [resultimgs, setResultImgs] = useState([]);
  const [state, setState] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const imgindex = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    axios
      .get(`${URL}/api/supervised/object/s3/results`)
      .then(response => {
        const urls = response.data; // Assuming the API returns the list of URLs directly

        setImgs(urls);
      })
      .catch(error => {
        console.error('Error fetching image URLs:', error);
      });
  }, []);

  const onClick = (num) => {
    setIsClicked(true);
    setState(num);

    axios
      .get(`${URL}/api/supervised/object/s3/result${num}`)
      .then(response => {
        const urls = response.data; // Assuming the API returns the list of URLs directly


        setResultImgs(urls);
      })
      .catch(error => {
        console.error('Error fetching image URLs:', error);
      });

  };

  const handleNextClick = () => {
    if (!isClicked) {
      setModalMessage("이미지를 클릭해 분석 결과를 확인해보세요 !");
      setShowModal(true);
    } else {
      window.location.href = ("/");
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {state === 0 && <>
        <div className="white-back">
          <div className="object3-grid">
            {imgs.map((item, index) => (
              <div key={index} onClick={() => onClick(imgindex[index])}><img src={item} alt="resut-img"></img></div>
            ))}

          </div>
        </div>

        <div className="main-btn">
          <GreyBtn to="/object/2"></GreyBtn>
          <BlueBtn onClick={handleNextClick}>체험 완료</BlueBtn>
        </div>
      </>}

      {showModal && (
        <div className="modal-back">
          <div className="modal">
            <h3>{modalMessage}</h3>
            <button onClick={closeModal}><b>닫기</b></button>
          </div>
        </div>
      )}


      {!(state === 0) && <>
        <ResultItem
          result={resultimgs[0]} person={resultimgs[1]} bag={resultimgs[2]}
          book={resultimgs[3]} computer={resultimgs[4]} final={resultimgs[5]}
          onChange={onClick}
        ></ResultItem></>}
    </div>

  );
}