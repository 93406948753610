/**
 * @filePath        : src\components\pages\supervised\weather\weather4\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 모델 평가 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import arrow from "assets/supervised/weather/arrow.png"
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Cal1 from "components/pages/supervised/weather/weather4/Cal1";
import Cal2 from "components/pages/supervised/weather/weather4/Cal2";
import mouse from "assets/supervised/weather/mouse.png";
import green from "assets/supervised/weather/green.png";
import yellow from "assets/supervised/weather/yellow.png";
import red from "assets/supervised/weather/red.png";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Step from "components/sections/boxs/Step";
export default function Main() {
    const [newSignal, setNewSignal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showPopup, setShowPopup] = useState(true);
    const [modalMessage, setModalMessage] = useState("");
    const navigate = useNavigate();

    const handleNewSignal = (signal) => {
        setNewSignal(signal);
    };

    const handleNextClick = () => {
        if (newSignal) {
            navigate('/weather/5');
        } else {
            setModalMessage("다시 표시해주세요 !");
            setShowModal(true);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setShowPopup(false);
    };

    return (
        <>
            <Header learning="지도학습">날씨에 따른 전기 사용량 예상 (회귀 모델)</Header>
            <HeaderNav to1="/weatherconcept" to2="/weather" />
            <main>
                <div className="main-step-img">
                    <Step step1="변수 선택" state1={1} step2="모델 이해" state2={1} step3="모델 학습" state3={1} step4="모델 평가" state4={0}>
                        앞에서 만든 모델을 이용하여 전기 사용을 예측해보았습니다.<br />
                        이 예측 값이 실제로 사용된 전기 사용량을 잘 나타내는지 확인해봅시다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="grey-back" style={{ letterSpacing: "-1.5px" }}>
                        <p style={{ display: "flex", alignItems: "center" }}>ㅤㅤ•
                            전력 값의 실제 데이터와 예측 데이터가 2 미만으로 차이 나면 초록 불
                            ( <img src={green} alt="img" className="weather4-descimg" />),
                            2 이상 4 미만으로 차이 나면 노란 불
                            ( <img src={yellow} alt="img" className="weather4-descimg" />),
                            4 이상으로 차이 나면 빨간 불
                            ( <img src={red} alt="img" className="weather4-descimg" />)
                            로 표시해주세요.
                        </p>
                        <p style={{ display: "flex", alignItems: "center" }}>
                            ㅤㅤㅤ <img src={mouse} alt="img" className="weather4-descimg" />
                            ㅤ마우스로 원 도형을 클릭하면 색을 표시할 수 있습니다.
                        </p>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", fontSize: "1.2rem", fontWeight: "bold" }}>
                        <p style={{ width: "100%", color: "#777777" }}>실제 전력량 데이터</p>
                        <div />
                        <p style={{ width: "100%", color: "#333333" }}>내가 만든 모델이 예측한 전력량 🤖</p>
                    </div>

                    <div className="calendar-space2">
                        <Cal1 />

                        <img src={arrow} alt="arrow" style={{ margin: "0rem 0.5rem" }} />

                        <Cal2 handleNewSignal={handleNewSignal} />
                    </div>
                </div>

                <div className="main-btn" style={{ margin: "1rem 0rem" }}>
                    <GreyBtn to="/weather/3"></GreyBtn>
                    <button className="bluebtn" onClick={handleNextClick} >
                        결과 확인
                    </button>
                </div>


                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
                {showPopup && (
                    <div className="modal-back">
                        <div className="weather4-popup">
                            <p>실제 전력량과 예측 전력량의 차이를 비교해보고자 합니다. </p>
                            <p>처음 3일에 대한 표시를 알맞게 해주세요.</p>
                            <p>마우스로 원 도형을 <b style={{color:"red"}}>클릭</b>하면 원의 색을 변경할 수 있습니다.</p>
                            <video
                                muted
                                autoPlay
                                loop
                                className="weather4-popup-video">
                                <source src="/videos/weather/weather_popup.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}