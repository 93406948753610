/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient1\Table.js
 * @fileName        : Table.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 입력 데이터 테이블 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function Table() {
    const [data, setData] = useState([]);
    const [listcount, setListcount] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태값

    useEffect(() => {
        axios
            .get(`${URL}/api/supervised/classification/data`)
            .then((result) => {
                setData(result.data.list);
                setListcount(result.data.listcount);
            })
            .catch((error) => {
                console.error('error:', error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    }, []);

    return (
        <>
            {loading && <ProgressBar />} {/* loading 값이 true일 때만 프로그래스바 컴포넌트 표시 */}
            
            <p style={{ textAlign: "left", marginLeft: "3rem" }}>전체 : <b style={{ color: "orange" }}>{listcount}</b> 건</p>
            <div className="coldpatient1-table-space">
                <div className="coldpatient1-table-container">
                    <table className="coldpatient1-table">
                        <thead>
                            <tr>
                                <th>근육통</th>
                                <th>기침 </th>
                                <th>피로감</th>
                                <th>목아픔</th>
                                <th>콧물</th>
                                <th>코막힘</th>
                                <th>열</th>
                                <th>메쓰꺼움</th>
                                <th>구토</th>
                                <th>설사</th>
                                <th>짧은 호흡</th>
                                <th>호흡 어려움</th>
                                <th>미각 상실</th>
                                <th>후각 상실</th>
                                <th>코 가려움</th>
                                <th>눈 가려움</th>
                                <th>입 가려움</th>
                                <th>귓속 가려움</th>
                                <th>재채기</th>
                                <th>충혈</th>
                                <th>TARGET</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.MUSCLE_ACHES}</td>
                                    <td>{item.COUGH}</td>
                                    <td>{item.TIREDNESS}</td>
                                    <td>{item.SORE_THROAT}</td>
                                    <td>{item.RUNNY_NOSE}</td>
                                    <td>{item.STUFFY_NOSE}</td>
                                    <td>{item.FEVER}</td>
                                    <td>{item.NAUSEA}</td>
                                    <td>{item.VOMITING}</td>
                                    <td>{item.DIARRHEA}</td>
                                    <td>{item.SHORTNESS_OF_BREATH}</td>
                                    <td>{item.DIFFICULTY_BREATHING}</td>
                                    <td>{item.LOSS_OF_TASTE}</td>
                                    <td>{item.LOSS_OF_SMELL}</td>
                                    <td>{item.ITCHY_NOSE}</td>
                                    <td>{item.ITCHY_EYES}</td>
                                    <td>{item.ITCHY_MOUTH}</td>
                                    <td>{item.ITCHY_INNER_EAR}</td>
                                    <td>{item.SNEEZING}</td>
                                    <td>{item.PINK_EYE}</td>
                                    <td>{item.TRUE_LABEL}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}