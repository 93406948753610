/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 단어 맞추기 모델의 결과 환인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Chart from "components/pages/supervised/wordmatch/wordmatch3/Chart.js";
import Result from "components/pages/supervised/wordmatch/wordmatch3/Result.js";
import { getDatasetId2 } from "components/pages/supervised/wordmatch/wordmatch1/WordmatchData";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">단어 맞추기 (의사결정트리 모델)</Header>
            <HeaderNav to1="/wordmatchconcept" to2="/wordmatch" />
            <main>
                <div className="main-step-img">
                    <Step step1="단어 선택" state1={1} step2="단어 맞추기" state2={1} step3="결과 확인" state3={0}>
                        케듀가 선택한 단어를 맞추는 과정을 아래와 같이 그림으로 나타내어 보았습니다.
                        <br />
                        의사결정트리는 데이터를 분석하여 이들 사이에 존재하는 패턴을 예측 가능한 규칙들의 조합으로 나타내며 그 모양이 나무와 같다고 해서 의사결정트리라 불립니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="wordmatch3-main">
                        <div className="wordmatch3-main-left">
                            <Chart />
                        </div>

                        <div className="wordmatch3-main-right">
                            <Result receiveData={getDatasetId2()} />
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/wordmatch/2"></GreyBtn>
                    <BlueBtn to="/">체험 종료</BlueBtn>
                </div>
            </main>
        </>
    );
}
