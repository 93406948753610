/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient4\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Table from "components/pages/supervised/coldpatient/coldpatient4/Table";
import { Link } from "react-router-dom";
import Step from "components/sections/boxs/Step";

export default function Main() {
  return (
    <>
      <Header learning="지도학습">감기환자 분류하기 (분류 모델)</Header>
      <HeaderNav to1="/coldpatientconcept" to2="/coldpatient" />
      <main>
        <div className="main-step-img">
          <Step
            step1="데이터 확인"
            state1={1}
            step2="모델 선택"
            state2={1}
            step3="모델성능평가"
            state3={1}
            step4="결과 확인"
            state4={0}
          >
            테스트 데이터를 이용하여 모델이 잘 학습되었는지 확인해 봅시다.
            <br />
            데이터를 보고 감기인지, 정상인지 생각해 보고 인공지능 모델의
            예측결과와 비교해 봅시다.
          </Step>
        </div>

        <div className="white-back">
          <div className="grey-back">
            <p>
              ㅤㅤ • 테스트 데이터를 이용하여 모델이 잘 학습되었는지 확인해
              봅시다. <br />
              ㅤㅤㅤ데이터를 보고 감기인지, 정상인지 생각해 보고 인공지능 모델의
              예측결과와 비교해 봅시다.
            </p>
          </div>
          <div className="coldpatient4-re-btn">
            <Link onClick={() => (window.location.href = "/coldpatient")}>
              다시하기
            </Link>
          </div>
          <Table />
        </div>

        <div className="main-btn">
          <GreyBtn to="/coldpatient/2"></GreyBtn>
          <BlueBtn to="/">체험 종료</BlueBtn>
        </div>
      </main>
    </>
  );
}
