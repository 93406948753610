/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 데이터 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Desc from "components/pages/unsupervised/seasons/seasons1/Desc";
import Table from "components/pages/unsupervised/seasons/seasons1/Table";
import { useState } from "react";

export default function Main() {
    const [step, setStep] = useState(1);
    return (
        <>
            <Header learning="비지도학습">사계절 분류하기 (군집화 모델)</Header>
            <HeaderNav to1="/seasonsconcept" to2="/seasons" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={0} step2="모델 이해" state2={2} step3="결과 확인" state3={2} step4="평가하기" state4={2}>
                        아래와 같이 일자별 날씨 데이터를 준비해줍니다.<br/>
                        준비한 데이터를 엑셀 파일로 만든 후, 클러스터링 모델에 넣어줍니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="grey-back">
                        {step === 1 && (
                            <p>
                                ㅤㅤ • 기상청 날씨누리 홈페이지에서 분석에 필요한 날씨 데이터를
                                확보합니다.ㅤ
                                <a href="https://www.weather.go.kr/plus/land/current/past_tendays.jsp" target="_blank" rel="noopener noreferrer"
                                    style={{ textDecorationLine: "underline", color:"#0084ff" }}>(https://www.weather.go.kr/plus/land/current/past_tendays.jsp)</a>
                            </p>
                        )}
                        {step === 2 && (
                            <p>ㅤㅤ • 분석에 필요한 데이터인 <b style={{color:"#503cb2"}}>날짜별로 평균 기온, 강수량, 습도</b>를 수집하여 아래와 같이 사용 가능한 형태로 가공합니다.<br />
                            ㅤㅤㅤ이제 분석에 필요한 데이터 준비가 완료되었습니다.
                            </p>
                        )}
                    </div>

                    {step === 1 && (<Desc />)}
                    {step === 2 && (<Table />)}
                </div>

                <div className="main-btn">
                    {step === 1 && (
                        <>
                            <GreyBtn to="/seasons"></GreyBtn>
                            <BlueBtn onClick={() => setStep(step + 1)}>다음 단계로</BlueBtn>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                            <BlueBtn to="/seasons/2">다음 단계로</BlueBtn>
                        </>
                    )}

                </div>
            </main>
        </>
    );
}