/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpieceintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import selectpainter from "assets/unsupervised/masterpiece/selectpainter.png";
import selectimg from "assets/unsupervised/masterpiece/selectimg.png";
import checkModel from "assets/unsupervised/masterpiece/checkModel.png";
import checkresult from "assets/unsupervised/masterpiece/checkresult.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="비지도학습">사진 명화풍 만들기 (GAN 모델)</Header>
            <HeaderNav to1="/masterpiececoncept" to2="/masterpiece" />
            <Conceptcheck>GAN(Generative Adversarial Network)은 2개의 네트워크가 서로 경쟁하며 모델의 성능을 개선해 나가는 딥러닝 모델입니다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>본 프로그램은 일반 사진을 유명한 화가의 작품처럼 만들어보는 프로그램으로 딥러닝을 이용한 이미지 합성을 체험할 수 있습니다.</p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/02_사진 명화풍 만들기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={selectpainter}>
                            화가 선택
                        </Card>
                        <Card btn="Step 2" img={selectimg}>
                            사진 선택
                        </Card>
                        <Card btn="Step 3" img={checkModel}>
                            모델 확인
                        </Card>
                        <Card btn="Step 4" img={checkresult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/masterpiece/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
