/**
 * @filePath        : src\components\pages\supervised\emotion\emotion1\GridItem.js
 * @fileName        : GridItem.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 리뷰데이터에 따른 감정 라벨링 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import { setTrueEmotion, setNewTrueEmotion, setText, setAnsText } from "components/pages/supervised/emotion/emotion1/EmotionData"
import { getNewTrueEmotionIndex } from "components/pages/supervised/emotion/emotion1/EmotionData";

export default function GridItem({ text, index, true_emotion, ans_text }) {
    const [selectedEmotion, setSelectedEmotion] = useState(getNewTrueEmotionIndex(index)); // 초기 선택값

    const handleChangeEmotion = (event) => {
        setSelectedEmotion(event.target.value);
    };

    useEffect(() => {
        setNewTrueEmotion(index, selectedEmotion);
        setTrueEmotion(index, true_emotion);
        setText(index, text);
        setAnsText(index, ans_text);
    }, [index, true_emotion, selectedEmotion, text, ans_text]);

    return (
        <>
            <div className="emotion1-grid">
                <p style={{ textAlign: "right" }}>{text}</p>
                <div style={{ paddingLeft: "5%"}}>
                    <select value={selectedEmotion} onChange={handleChangeEmotion}>
                        <option value={""}>선택</option>
                        <option value={1}>긍정</option>
                        <option value={0}>부정</option>
                    </select>
                </div>
            </div>
        </>
    );
}

