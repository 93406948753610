/**
 * @filePath        : src\components\pages\unsupervised\writing\writingconcept1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 키워드로 작문하기 모델의 개념학습 영상
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="비지도학습">키워드로 작문하기 (언어 모델)</Header>
            <HeaderNav to1="/writingconcept" to2="/writing" />
            <Conceptcheck>사전에 대규모 데이터로 학습시켜 두고 이를 적용, 응용할 수 있는 것이 언어 모델입니다.
                    영상을 통해서 자세히 알아보도록 합니다. </Conceptcheck>
                    
            <main>

                <div className="youtubezone">
                    <iframe width="80%" height="500"
                        src="https://www.youtube.com/embed/3iPYyjqTx78"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ margin: '2rem', border: "none" }}
                    ></iframe>
                </div>

                <BlueBtn to="/writing">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}