/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity1\CelebrityData.js
 * @fileName        : CelebrityData.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 데이터 관리 함수 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */

export function setEI(data) {
    sessionStorage.setItem("EI", data);
}
export function getEI() {
    return sessionStorage.getItem("EI") * 1;
}

export function setSN(data) {
    sessionStorage.setItem("SN", data);
}
export function getSN() {
    return sessionStorage.getItem("SN") * 1;
}

export function setTF(data) {
    sessionStorage.setItem("TF", data);
}
export function getTF() {
    return sessionStorage.getItem("TF") * 1;
}

export function setJP(data) {
    sessionStorage.setItem("JP", data);
}
export function getJP() {
    return sessionStorage.getItem("JP") * 1;
}

// export function setMbti(data) {
//     mbti = data;
// }
export function getMbti() {
    let mbti = "";

    const EI = sessionStorage.getItem("EI") * 1;
    const SN = sessionStorage.getItem("SN") * 1;
    const TF = sessionStorage.getItem("TF") * 1;
    const JP = sessionStorage.getItem("JP") * 1;

    if (EI === 0 || EI === 1) {
        mbti += "E";
    } else {
        mbti += "I";
    }
    if (SN === 0 || SN === 1 || SN === 2) {
        mbti += "S";
    } else {
        mbti += "N";
    }
    if (TF === 0 || TF === 1) {
        mbti += "T";
    } else {
        mbti += "F";
    }
    if (JP === 0 || JP === 1) {
        mbti += "J";
    } else {
        mbti += "P";
    }

    return mbti;
}

export function setType1(data) {
    sessionStorage.setItem("type1", data);
}
export function getType1() {
    return sessionStorage.getItem("type1") * 1;
}

export function setType2(data) {
    sessionStorage.setItem("type2", data);
}
export function getType2() {
    return sessionStorage.getItem("type2") * 1;
}

export function setType3(data) {
    sessionStorage.setItem("type3", data);
}
export function getType3() {
    return sessionStorage.getItem("type3") * 1;
}

export function setCluster2(data) {
    sessionStorage.setItem("cluster2", data);
}
export function getCluster2() {
    let cluster2 = sessionStorage.getItem("cluster2") * 1;
    if (cluster2 === 0) {
        cluster2 = 2;
    }
    return cluster2;
}
