/**
 * @filePath        : src\components\pages\unsupervised\news\newsconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/unsupervised/news/news_exapmle1.png";
import example2 from "assets/unsupervised/news/news_exapmle2.png";
import example3 from "assets/unsupervised/news/news_exapmle3.png";
import Carousel from "components/sections/boxs/Carousel";


export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "GPT",
            desc1: "GPT 모델은 거대한 양의 텍스트 데이터를 사용하여 사전 학습됩니다. 이런 데이터를 통해 모델은 언어의 다양한 특성과 문맥을 파악하고 학습하게 됩니다. 따라서 GPT는 주어진 입력 문장이나 단어를 이해하고, 그 다음에 나올 적절한 텍스트를 생성하는 데에 우수한 능력을 갖추게 됩니다.",
            desc2: "이러한 특성은 자동 문장 생성, 요약, 번역, 질문 응답 등 다양한 자연어 처리 작업에서 활용됩니다. GPT는 기존 모델들보다 더욱 자연스러운 문장을 생성하고 의미 있는 결과물을 만들어냄으로써, 현대 자연어 처리 분야에서 중요한 역할을 하고 있는 혁신적인 사전 학습모델입니다.",
            page: "1/3"
        },
        {
            image: example2,
            title: "금융 예측",
            desc1: "금융 분야에서 사전학습된 모델의 활용은 주가 예측과 경제 지표 분석을 통해 투자 결정을 지원하는 중요한 역할을 하고 있습니다. 주가 예측은 주식 시장에서 투자자들이 미래 주가의 움직임을 예측하여 수익을 극대화하려는 노력의 일환으로 이루어집니다.",
            desc2: "사전학습된 모델은 대량의 금융 데이터를 분석하여 주가의 트렌드나 패턴을 파악하고 예측 모델을 개발하는 데에 활용됩니다. 이를 통해 투자자들은 더 정확한 주가 예측을 바탕으로 리스크를 최소화하고 수익을 극대화하는 전략을 수립할 수 있습니다.",
            page: "2/3"
        },
        {
            image: example3,
            title: "번역기",
            desc1: "일정 규모의 특정 언어로 표현된 말뭉치를 가지고 모델을 학습 시켜 사전학습을 만들고, 학습된 모델을 통해 한 언어에서 다른 언어로의 번역을 수행합니다.",
            page: "3/3"
        },
    ];
    return (
        <>
            <Header learning="비지도학습">AI를 이용한 여러 종류의 글쓰기 (사전학습 모델)</Header>
            <HeaderNav to1="/newsconcept" to2="/news" />
            <Conceptcheck> 사전학습모델은 생활에서 활용되고 있습니다.
                    가장 대표적인 예로 Chat-GPT, 번역기 등이 있습니다. </Conceptcheck>

            <main >        
                <Carousel items={carouselItems} />

                <BlueBtn to="/newsconcept/1">
                    개념영상 시청하기
                </BlueBtn>
            </main>
        </>
    );
}
