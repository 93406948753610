/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 군집 분석 단계 메인 화면 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import { getCluster2, setCluster2 } from "components/pages/unsupervised/celebrity/celebrity1/CelebrityData";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/ProgressBar";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Chart from "components/pages/unsupervised/celebrity/celebrity2/Chart";
import Table from "components/pages/unsupervised/celebrity/celebrity2/Table";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    const [cluster, setCluster] = useState(getCluster2());

    const [chartList, setChartList] = useState([]);
    const [countList, setCountList] = useState([]);

    const [flag, setFlag] = useState(false);

    const [loading, setLoading] = useState(false); // 로딩 상태값

    useEffect(() => {
        if (flag) {
            handleExecuteClick();
        }
        // eslint-disable-next-line
    }, [flag]);

    const handleClusterChange = (e) => {
         if (!isNaN(e.target.value) && e.target.value >= 2 && e.target.value <= 5) {
             setCluster(e.target.value);
             setCluster2(e.target.value);
         }
    };

    const handleExecuteClick = () => {
        setLoading(true);

        const params = { CLUSTER: cluster };
        axios
            .post(`${URL}/api/unsupervised/clustering/excute`, params)
            .then((result) => {
                setChartList(result.data.chartlist);
                setCountList(result.data.countlist);

                setFlag(false);
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    };

    const handleFindClick = () => {
        // if (!confirm("최적의 수를 탐색합니다.")) return;
        setLoading(true);
        axios
            .get(`${URL}/api/unsupervised/clustering/getClusterCnt`)
            .then((result) => {
                setCluster(result.data.data);
                setCluster2(result.data.data);

                setFlag(true);
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    };

    return (
        <>
            {loading && <ProgressBar />} {/* loading 값이 true일 때만 프로그래스바 컴포넌트 표시 */}
            <Header learning="비지도학습">나와 유사한 유명인 찾기 (군집화 모델)</Header>
            <HeaderNav to1="/celebrityconcept" to2="/celebrity" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 입력" state1={1} step2="군집 분석" state2={0} step3="결과 확인" state3={2}>
                        주어진 유명인 데이터의 분포를 그래프로 확인합니다. [최적의 수 찾기]를 통해 가장 성능이 좋은 군집 모델을 만들 수 있습니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div>
                        <div className="celebrity2-top">
                            <label>군집 수</label>

                            <div className="celebrity2-pulsminus" onClick={() => handleClusterChange({ target: { value: cluster - 1 } })}>-</div>
                            <input type="text" value={cluster} onChange={handleClusterChange} />
                            <div className="celebrity2-pulsminus" onClick={() => handleClusterChange({ target: { value: cluster + 1 } })}>+</div>

                            <div className="celebrity2-execute" onClick={handleExecuteClick}>실행</div>
                            <div className="celebrity2-find" onClick={handleFindClick}>최적의 수 찾기</div>

                        </div>
                    </div>

                    <div className="celebrity2-cluster-space">
                        <div className="celebrity2-cluster-space-left">
                            <Chart receiveLabels={countList} receiveData={chartList} />
                        </div>

                        <div className="celebrity2-cluster-space-right">
                            <Table receiveData={countList} />
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/celebrity/1"></GreyBtn>
                    <BlueBtn to="/celebrity/3">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}
