/**
 * @filePath        : src\components\pages\supervised\object\object1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 모델의 객체 라벨링 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import { useState, createContext } from "react";
import Personlabeling from "components/pages/supervised/object/labeling/Personlabeling";
import Baglabeling from "components/pages/supervised/object/labeling/Baglabeling";
import Booklabeling from "components/pages/supervised/object/labeling/Booklabeling";
import Computerlabeling from "components/pages/supervised/object/labeling/Computerlabeling";
import personitem from "assets/supervised/object/personitem.png";
import bagitem from "assets/supervised/object/bagitem.png";
import bookitem from "assets/supervised/object/bookitem.png";
import computeritem from "assets/supervised/object/computeritem.png";
import Step from "components/sections/boxs/Step";

export const LabelingContext = createContext();

export default function Main() {
    const [state, setState] = useState(0);
    const [person, setPerson] = useState(false);
    const [bag, setBag] = useState(false);
    const [book, setBook] = useState(false);
    const [computer, setComputer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClick = (num) => {
        setState(num);
    };

    const handleNextClick = () => {
        setModalMessage("라벨링을 모두 완료해주세요 !");
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="지도학습">사진에서 서로 다른 대상 찾아내기 (객체 검출)</Header>
            <HeaderNav to1="/objectconcept" to2="/object" />

            <main>
                <div className="main-step-img">
                    <Step step1="객체 라벨링" state1={0} step2="모델 이해" state2={2} step3="결과 확인" state3={2}>
                        객체 검출 모델링을 위해서는 우선 객체의 종류에 맞게 라벨링하는 과정이 필요합니다.<br />
                        모델에 넣을 이미지 데이터에 사람, 가방, 책, 노트북에 대한 라벨링을 모두 진행해주세요. 박스를 클릭하면 과정이 시작됩니다.
                    </Step>
                </div>


                <div className="object-labeling-grid">
                    {state === 0 &&
                        <>
                            <div>
                                <div className={`object-labeling-grid-item ${person === true ? "labelingtrue" : ""}`}
                                    onClick={() => handleClick(1)}
                                    style={{ backgroundImage: `url('${personitem}')` }}>
                                </div>
                                <br /><b>사람 라벨링</b>
                            </div>
                            <div>
                                <div className={`object-labeling-grid-item ${bag === true ? "labelingtrue" : ""}`}
                                    onClick={() => handleClick(2)}
                                    style={{ backgroundImage: `url('${bagitem}')`, marginTop: "2rem" }}>
                                </div>
                                <br /><b>가방 라벨링</b>
                            </div>
                            <div>
                                <div className={`object-labeling-grid-item ${book === true ? "labelingtrue" : ""}`}
                                    onClick={() => handleClick(3)}
                                    style={{ backgroundImage: `url('${bookitem}')` }}>
                                </div>
                                <br /><b>책 라벨링</b>
                            </div>
                            <div>
                                <div className={`object-labeling-grid-item ${computer === true ? "labelingtrue" : ""}`}
                                    onClick={() => handleClick(4)}
                                    style={{ backgroundImage: `url('${computeritem}')`, marginTop: "2rem" }}>
                                </div>
                                <br /><b>노트북 라벨링</b>
                            </div>
                        </>}
                </div>
                <LabelingContext.Provider
                    value={{ person, setPerson, bag, setBag, book, setBook, computer, setComputer, state, setState }}>
                    {state === 1 && <Personlabeling />}
                    {state === 2 && <Baglabeling />}
                    {state === 3 && <Booklabeling />}
                    {state === 4 && <Computerlabeling />}
                </LabelingContext.Provider>


                {state === 0 &&
                    <div className="main-btn">
                        <GreyBtn to="/object"></GreyBtn>

                        {(person && bag && book && computer) ? (
                            <BlueBtn to="/object/2">라벨링 완료</BlueBtn>) : (
                            <BlueBtn onClick={handleNextClick}>라벨링 완료</BlueBtn>)}
                    </div>
                }
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}