/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatchconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 단어 맞추기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/supervised/wordmatch/wordmatch_example1.png";
import example2 from "assets/supervised/wordmatch/wordmatch_example2.png";
import example3 from "assets/supervised/wordmatch/wordmatch_example3.png";
import Carousel from "components/sections/boxs/Carousel";


export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "병원 진료",
            desc1: "의사결정트리 모델은 병원 진료에서 병을 식별하는데 활용되기도 합니다. 예를 들면, 실제 폐렴환자의 증상데이터와 폐렴과 유사한 증상데이터를 수집한 후, 기침, 가슴 통증, 고열과 같은 증상이 있는 환자를 폐렴으로 레이블링하여 이를 기반으로 의사결정트리를 생성합니다.",
            desc2: "이 모델은 의사가 환자의 증상을 보고 폐렴 여부를 정확하게 식별하는 데에 활용됩니다.",
            page: "1/3"
        },
        {
            image: example2,
            title: "대출 상담",
            desc1: "대출 상담에서 의사결정트리 모델은 기존 은행 대출자들의 성별, 직업, 연봉 등을 토대로 대출금을 갚았는지 갚지 않은 것인지에 대한 데이터를 모아 분석하고 성별, 직업, 연봉 등의 바탕으로 의사결정트리를 만듭니다.",
            desc2: "이를 활용하여 새롭게 대출을 신청하는 사람들의 성별, 직업, 연봉 등을 의사결정트리 모델에 적용해보고 최종적으로 대출여부를 판단하는데 사용됩니다.",
            page: "2/3"
        },
        {
            image: example3,
            title: "아키네이터",
            desc1: "아키네이터는 웹 기반 게임으로, 플레이어가 실제 또는 만들어낸 캐릭터의 이름을 맞추는 것을 목표로 합니다.",
            desc2: "아키네이터의 의사결정트리 모델은 플레이어가 제시한 답변을 기반으로 캐릭터 후보들을 추려가며 추리를 합니다. 게임을 진행할수록 다양한 유명인 및 캐릭터를 맞출 수 있으며, 아키네이터게임은 계속 진화하고 향상됩니다.",
            page: "3/3"
        },
    ];
    return (
        <>
            <Header learning="지도학습">단어 맞추기 (의사결정트리 모델)</Header>
            <HeaderNav to1="/wordmatchconcept" to2="/wordmatch" />
            <Conceptcheck> 의사결정트리 모델은 생활에서 활용되고 있습니다. 가장 대표적인 예로 병원 진료, 대출 상담, 아키네이터 등이 있습니다.
            </Conceptcheck>

            <main >
                <Carousel items={carouselItems} />

                <BlueBtn to="/wordmatch">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}
