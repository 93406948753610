/**
 * @filePath        : src\components\Routing.js
 * @fileName        : Routing.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 페이지 지정 라우팅 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Routes, Route } from "react-router-dom";
import Home from "components/pages/home/Main";
import TermsofUse from "components/pages/TermsofUse";
import Introduce from "components/pages/Introduce";

// supervised

import Weatherconceptintro from "components/pages/supervised/weather/weatherconceptintro/Main";
import Weatherconcept1 from "components/pages/supervised/weather/weatherconcept1/Main";
import Weatherintro from "components/pages/supervised/weather/weatherintro/Main";
import Weather1 from "components/pages/supervised/weather/weather1/Main";
import Weather2 from "components/pages/supervised/weather/weather2/Main";
import Weather3 from "components/pages/supervised/weather/weather3/Main";
import Weather4 from "components/pages/supervised/weather/weather4/Main";
import Weather5 from "components/pages/supervised/weather/weather5/Main";

import Objectconceptintro from "components/pages/supervised/object/objectconceptintro/Main";
import Objectintro from "components/pages/supervised/object/objectintro/Main";
import Object1 from "components/pages/supervised/object/object1/Main";
import Object2 from "components/pages/supervised/object/object2/Main";
import Object3 from "components/pages/supervised/object/object3/Main";

import Coldpatientconceptintro from "components/pages/supervised/coldpatient/coldpatientconceptintro/Main";
import Coldpatientintro from "components/pages/supervised/coldpatient/coldpatientintro/Main";
import Coldpatient1 from "components/pages/supervised/coldpatient/coldpatient1/Main";
import Coldpatient2 from "components/pages/supervised/coldpatient/coldpatient2/Main";
import Coldpatient3 from "components/pages/supervised/coldpatient/coldpatient3/Main";
import Coldpatient4 from "components/pages/supervised/coldpatient/coldpatient4/Main";

import Wordmatchconceptintro from "components/pages/supervised/wordmatch/wordmatchconceptintro/Main";
import Wordmatchintro from "components/pages/supervised/wordmatch/wordmatchintro/Main";
import Wordmatch1 from "components/pages/supervised/wordmatch/wordmatch1/Main";
import Wordmatch2 from "components/pages/supervised/wordmatch/wordmatch2/Main";
import Wordmatch3 from "components/pages/supervised/wordmatch/wordmatch3/Main";

import Predictionconceptintro from "components/pages/supervised/prediction/predictionconceptintro/Main";
import Predictionintro from "components/pages/supervised/prediction/predictionintro/Main";
import Prediction1 from "components/pages/supervised/prediction/prediction1/Main";
import Prediction2 from "components/pages/supervised/prediction/prediction2/Main";
import Prediction3 from "components/pages/supervised/prediction/prediction3/Main";

import Genderageconceptintro from "components/pages/supervised/genderage/genderageconceptintro/Main";
import Genderageintro from "components/pages/supervised/genderage/genderageintro/Main";
import Genderage1 from "components/pages/supervised/genderage/genderage1/Main";
import Genderage2 from "components/pages/supervised/genderage/genderage2/Main";
import Genderage3 from "components/pages/supervised/genderage/genderage3/Main";
import Genderage4 from "components/pages/supervised/genderage/genderage4/Main";

import Emotionconceptintro from "components/pages/supervised/emotion/emotionconceptintro/Main";
import Emotionintro from "components/pages/supervised/emotion/emotionintro/Main";
import Emotion1 from "components/pages/supervised/emotion/emotion1/Main";
import Emotion2 from "components/pages/supervised/emotion/emotion2/Main";
import Emotion3 from "components/pages/supervised/emotion/emotion3/Main";
import Emotion4 from "components/pages/supervised/emotion/emotion4/Main";


// unsupervised

import Seasonsconceptintro from "components/pages/unsupervised/seasons/seasonsconceptintro/Main";
import Seasonsconcept1 from "components/pages/unsupervised/seasons/seasonsconcept1/Main";
import Seasonsintro from "components/pages/unsupervised/seasons/seasonsintro/Main";
import Seasons1 from "components/pages/unsupervised/seasons/seasons1/Main";
import Seasons2 from "components/pages/unsupervised/seasons/seasons2/Main";
import Seasons3 from "components/pages/unsupervised/seasons/seasons3/Main";
import Seasons4 from "components/pages/unsupervised/seasons/seasons4/Main";

import Newsconceptintro from "components/pages/unsupervised/news/newsconceptintro/Main";
import Newsconcept1 from "components/pages/unsupervised/news/newsconcept1/Main";
import Newsintro from "components/pages/unsupervised/news/newsintro/Main";
import News1 from "components/pages/unsupervised/news/news1/Main";
import News2 from "components/pages/unsupervised/news/news2/Main";

import Celebrityconceptintro from "components/pages/unsupervised/celebrity/celebrityconceptintro/Main";
import Celebrityconcept1 from "components/pages/unsupervised/celebrity/celebrityconcept1/Main";
import Celebrityintro from "components/pages/unsupervised/celebrity/celebrityintro/Main";
import Celebrity1 from "components/pages/unsupervised/celebrity/celebrity1/Main";
import Celebrity2 from "components/pages/unsupervised/celebrity/celebrity2/Main";
import Celebrity3 from "components/pages/unsupervised/celebrity/celebrity3/Main";

import Masterpiececonceptintro from "components/pages/unsupervised/masterpiece/masterpiececonceptintro/Main";
import Masterpieceintro from "components/pages/unsupervised/masterpiece/masterpieceintro/Main";
import Masterpiece1 from "components/pages/unsupervised/masterpiece/masterpiece1/Main";
import Masterpiece2 from "components/pages/unsupervised/masterpiece/masterpiece2/Main";
import Masterpiece3 from "components/pages/unsupervised/masterpiece/masterpiece3/Main";
import Masterpiece4 from "components/pages/unsupervised/masterpiece/masterpiece4/Main";

import Writingconceptintro from "components/pages/unsupervised/writing/writingconceptintro/Main";
import Writingconcept1 from "components/pages/unsupervised/writing/writingconcept1/Main";
import Writingintro from "components/pages/unsupervised/writing/writingintro/Main";
import Writing1 from "components/pages/unsupervised/writing/writing1/Main";

// reinforcement
import Cartpoleconceptintro from "components/pages/reinforcement/cartpole/cartpoleconceptintro/Main";
import Cartpoleintro from "components/pages/reinforcement/cartpole/cartpoleintro/Main";
import Cartpole1 from "components/pages/reinforcement/cartpole/cartpole1/Main";
import Cartpole2 from "components/pages/reinforcement/cartpole/cartpole2/Main";
import Cartpole3 from "components/pages/reinforcement/cartpole/cartpole3/Main";

import Arcticconceptintro from "components/pages/reinforcement/arctic/arcticconceptintro/Main";
import Arcticintro from "components/pages/reinforcement/arctic/arcticintro/Main";
import Arctic1 from "components/pages/reinforcement/arctic/arctic1/Main";
import Arctic2 from "components/pages/reinforcement/arctic/arctic2/Main";
import Arctic3 from "components/pages/reinforcement/arctic/arctic3/Main";

import Selfdrivingconceptintro from "components/pages/reinforcement/selfdriving/selfdrivingconceptintro/Main";
import Selfdrivingintro from "components/pages/reinforcement/selfdriving/selfdrivingintro/Main";
import Selfdriving1 from "components/pages/reinforcement/selfdriving/selfdriving1/Main";
import Selfdriving2 from "components/pages/reinforcement/selfdriving/selfdriving2/Main";
import Selfdriving3 from "components/pages/reinforcement/selfdriving/selfdriving3/Main";

import Flappyconceptintro from "components/pages/reinforcement/flappy/flappyconceptintro/Main";
import Flappyintro from "components/pages/reinforcement/flappy/flappyintro/Main";
import Flappyin1 from "components/pages/reinforcement/flappy/flappy1/Main";


export default function Routing() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/termsofuse" element={<TermsofUse />} />
            <Route path="/introduce" element={<Introduce />} />

            {/* supervised */}
            <Route path="/weatherconcept" element={<Weatherconceptintro />} />
            <Route path="/weatherconcept/1" element={<Weatherconcept1 />} />
            <Route path="/weather" element={<Weatherintro />} />
            <Route path="/weather/1" element={<Weather1 />} />
            <Route path="/weather/2" element={<Weather2 />} />
            <Route path="/weather/3" element={<Weather3 />} />
            <Route path="/weather/4" element={<Weather4 />} />
            <Route path="/weather/5" element={<Weather5 />} />

            <Route path="/objectconcept" element={<Objectconceptintro />} />
            <Route path="/object" element={<Objectintro />} />
            <Route path="/object/1" element={<Object1 />} />
            <Route path="/object/2" element={<Object2 />} />
            <Route path="/object/3" element={<Object3 />} />

            <Route path="/coldpatientconcept" element={<Coldpatientconceptintro />} />
            <Route path="/coldpatient" element={<Coldpatientintro />} />
            <Route path="/coldpatient/1" element={<Coldpatient1 />} />
            <Route path="/coldpatient/2" element={<Coldpatient2 />} />
            <Route path="/coldpatient/3" element={<Coldpatient3 />} />
            <Route path="/coldpatient/4" element={<Coldpatient4 />} />

            <Route path="/wordmatchconcept" element={<Wordmatchconceptintro />} />
            <Route path="/wordmatch" element={<Wordmatchintro />} />
            <Route path="/wordmatch/1" element={<Wordmatch1 />} />
            <Route path="/wordmatch/2" element={<Wordmatch2 />} />
            <Route path="/wordmatch/3" element={<Wordmatch3 />} />

            <Route path="/predictionconcept" element={<Predictionconceptintro />} />
            <Route path="/prediction" element={<Predictionintro />} />
            <Route path="/prediction/1" element={<Prediction1 />} />
            <Route path="/prediction/2" element={<Prediction2 />} />
            <Route path="/prediction/3" element={<Prediction3 />} />

            <Route path="/genderageconcept" element={<Genderageconceptintro />} />
            <Route path="/genderage" element={<Genderageintro />} />
            <Route path="/genderage/1" element={<Genderage1 />} />
            <Route path="/genderage/2" element={<Genderage2 />} />
            <Route path="/genderage/3" element={<Genderage3 />} />
            <Route path="/genderage/4" element={<Genderage4 />} />

            <Route path="/emotionconcept" element={<Emotionconceptintro />} />
            <Route path="/emotion" element={<Emotionintro />} />
            <Route path="/emotion/1" element={<Emotion1 />} />
            <Route path="/emotion/2" element={<Emotion2 />} />
            <Route path="/emotion/3" element={<Emotion3 />} />
            <Route path="/emotion/4" element={<Emotion4 />} />

            {/* unsupervised */}
            <Route path="/seasonsconcept" element={<Seasonsconceptintro />} />
            <Route path="/seasonsconcept/1" element={<Seasonsconcept1 />} />
            <Route path="/seasons" element={<Seasonsintro />} />
            <Route path="/seasons/1" element={<Seasons1 />} />
            <Route path="/seasons/2" element={<Seasons2 />} />
            <Route path="/seasons/3" element={<Seasons3 />} />
            <Route path="/seasons/4" element={<Seasons4 />} />

            <Route path="/newsconcept" element={<Newsconceptintro />} />
            <Route path="/newsconcept/1" element={<Newsconcept1 />} />
            <Route path="/news" element={<Newsintro />} />
            <Route path="/news/1" element={<News1 />} />
            <Route path="/news/2" element={<News2 />} />

            <Route path="/celebrityconcept" element={<Celebrityconceptintro />} />
            <Route path="/celebrityconcept/1" element={<Celebrityconcept1 />} />
            <Route path="/celebrity" element={<Celebrityintro />} />
            <Route path="/celebrity/1" element={<Celebrity1 />} />
            <Route path="/celebrity/2" element={<Celebrity2 />} />
            <Route path="/celebrity/3" element={<Celebrity3 />} />

            <Route path="/masterpiececoncept" element={<Masterpiececonceptintro />} />
            <Route path="/masterpiece" element={<Masterpieceintro />} />
            <Route path="/masterpiece/1" element={<Masterpiece1 />} />
            <Route path="/masterpiece/2" element={<Masterpiece2 />} />
            <Route path="/masterpiece/3" element={<Masterpiece3 />} />
            <Route path="/masterpiece/4" element={<Masterpiece4 />} />

            <Route path="/writingconcept" element={<Writingconceptintro />} />
            <Route path="/writingconcept/1" element={<Writingconcept1 />} />
            <Route path="/writing" element={<Writingintro />} />
            <Route path="/writing/1" element={<Writing1 />} />

            {/* reinforcement */}
            <Route path="/cartpoleconcept" element={<Cartpoleconceptintro />} />
            <Route path="/cartpole" element={<Cartpoleintro />} />
            <Route path="/cartpole/1" element={<Cartpole1 />} />
            <Route path="/cartpole/2" element={<Cartpole2 />} />
            <Route path="/cartpole/3" element={<Cartpole3 />} />

            <Route path="/arcticconcept" element={<Arcticconceptintro />} />
            <Route path="/arctic" element={<Arcticintro />} />
            <Route path="/arctic/1" element={<Arctic1 />} />
            <Route path="/arctic/2" element={<Arctic2 />} />
            <Route path="/arctic/3" element={<Arctic3 />} />

            <Route path="/selfdrivingconcept" element={<Selfdrivingconceptintro />} />
            <Route path="/selfdriving" element={<Selfdrivingintro />} />
            <Route path="/selfdriving/1" element={<Selfdriving1 />} />
            <Route path="/selfdriving/2" element={<Selfdriving2 />} />
            <Route path="/selfdriving/3" element={<Selfdriving3 />} />

            <Route path="/flappyconcept" element={<Flappyconceptintro />} />
            <Route path="/flappy" element={<Flappyintro />} />
            <Route path="/flappy/1" element={<Flappyin1 />} />

        </Routes>
    );
}
