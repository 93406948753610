/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch3\Chart.js
 * @fileName        : Chart.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 단어 맞추기 모델 의사 결정 트리 차트 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import { getCateId, getDatasetId2 } from "components/pages/supervised/wordmatch/wordmatch1/WordmatchData";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/CustomProgressBar";
import { Chart as Charts } from "react-google-charts";

export default function Chart() {
    const [list, setList] = useState([]);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true); // 로딩 상태값

    useEffect(() => {
        const params = { MODEL_ID: getCateId() };
        axios
            .get(`${URL}/api/concept/wordmatch/result`, { params })
            .then((result) => {
                setList(result.data.list);
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    }, []);

    useEffect(() => {
        if (list.length > 0) {
            viewChart(getDatasetId2());
        }
        // eslint-disable-next-line
    }, [list]);

    const viewChart = (datasetId) => {
        const chartList = [];

        let id = 0;

        // list.map((item, index) => {
        for (let index = 0; index < list.length; index++) {
            const item = list[index];

            let parentId = id - 1;

            for (let i = 1; i <= 10; i++) {
                const currQstn = item["QSTN_" + i];
                const currAnsr = i === 1 ? "" : item["ANSR_" + (i - 1)];
                const prevQstn = i === 1 ? "" : item["QSTN_" + (i - 1)];
                const prevPrevQstn = i === 2 ? "" : item["QSTN_" + (i - 2)];
                const prevAnsr = i === 2 ? "" : item["ANSR_" + (i - 2)];
                // const nextQstn = item["QSTN_" + (i + 1)];

                let level = i;

                if (prevQstn) {
                    const parents = chartList.filter((x) => x.QSTN === prevQstn && x.ANSR === prevAnsr && x.level === level - 1 && x.PQSTN === prevPrevQstn);

                    if (parents.length > 0) {
                        parentId = parents[0].id;
                        level = parents[0].level + 1;
                    }
                }

                if (!currQstn) {
                    chartList.push({
                        id,
                        QSTN: item.DATASET_ID,
                        ANSR: currAnsr,
                        parentId,
                        level,
                        PQSTN: prevQstn,
                        index,
                    });

                    id++;
                    break;
                }

                let exist = chartList.filter((x) => x.QSTN === currQstn && x.ANSR === currAnsr && x.level === level && x.PQSTN === prevQstn);

                if (exist.length === 0) {
                    chartList.push({
                        id,
                        QSTN: currQstn,
                        ANSR: currAnsr,
                        parentId,
                        level,
                        PQSTN: prevQstn,
                        index,
                    });

                    id++;
                }
            }
        }

        const colors = ["orange", "green", "red", "black"];

        const tempData = chartList.map((item, index) => {
            let size = index === 0 ? 1 : 2;
            let weight = 0;
            let color = index === 0 ? colors[3] : "예" === item.ANSR ? colors[0] : colors[1];

            const exist = chartList.filter((x) => x.QSTN === item.QSTN && x.parentId === item.parentId && x.id < item.id);

            let str = "";
            for (let i = 0; i < exist.length; i++) {
                str += " ";
            }

            return [item.id, item.QSTN + str, item.parentId, size, weight, color];
        });

        // tempData.map((item, index) => {
        for (let index = 0; index < tempData.length; index++) {
            const item = tempData[index];

            if (item[1] === datasetId) {
                item[3] = 13;
                item[4] = 1;
                item[5] = colors[2];
            }
        }

        tempData.unshift(["id", "childLabel", "parent", "size", "weight", { role: "style" }]);
        setData(tempData);
    };

    const [options, setOptions] = useState({
        maxFontSize: 12,
        wordtree: {
            format: "explicit",
            type: "suffix",
            showTip: false,
        },
    });
    useEffect(() => {
        if (getCateId() === "M902") {
            setOptions(prevOptions => ({
                ...prevOptions,
                maxFontSize: 10.5,
            }));
        }
    }, []);

    return (
        <>
            {loading && <ProgressBar />}
            <div className="wordmatch3-chart">
                <div className="wordmatch3-tree-top">
                    <h3 style={{ textAlign: "left" }}>
                        <b style={{ color: "#FED800" }}>• </b> 의사결정트리
                    </h3>
                    <div className="wordmatch3-tree-top-text">
                        <div id="wordmatch3-tree-yes"></div>
                        <p>예</p>
                        <div id="wordmatch3-tree-no"></div>
                        <p>아니오</p>
                        <div id="wordmatch3-tree-ans"></div>
                        <p>정답</p>
                    </div>
                </div>

                <div className="wordmatch3-tree-bottom">
                    <Charts width={"100%"} height={"400px"} chartType="WordTree" data={data} options={options} />
                </div>
            </div>
        </>
    );
}
