/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch2\Result.js
 * @fileName        : Result.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 단어 맞추기 모델의 대화 후 정답 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import kedu from "assets/unsupervised/masterpiece/keduIco.png";
export default function Result(props) {
    const { receiveData } = props;

    return (
        <>
            <h3 style={{ textAlign: "left" }}><b style={{ color: "#FED800" }}>• </b> 단어 맞추기 결과</h3>

            {receiveData && <>
                <div className="wordmatch2-kedu">
                    <img src={kedu} alt="kedu icon" />
                    <div className="wordmatch2-kedu-textdiv">
                        <p>{receiveData}</p>
                    </div>
                </div>
            </>}
        </>
    );
}
