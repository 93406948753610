/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 단어 맞추기 모델 카테고리 및 단어 선택 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState } from "react";
import fruitImg from "assets/supervised/wordmatch/fruitImg.png";
import sportsImg from "assets/supervised/wordmatch/sportsImg.png";
import movieImg from "assets/supervised/wordmatch/movieImg.png";
import { setCateId, setCateName, getCateName } from "components/pages/supervised/wordmatch/wordmatch1/WordmatchData";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Selectpicture from "components/pages/supervised/wordmatch/wordmatch1/Selectpicture";
import kedu from "assets/unsupervised/masterpiece/keduIco.png";

export default function Main() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(0);

    const category = [
        { CATE_ID: "M900", CATE_NAME: "과일", IMG: { fruitImg } },
        { CATE_ID: "M901", CATE_NAME: "스포츠", IMG: { sportsImg } },
        { CATE_ID: "M902", CATE_NAME: "영화", IMG: { movieImg } },
    ];

    const handleImageClick = (index) => {
        setSelectedImage(index);

        setCateId(category[index].CATE_ID);
        setCateName(category[index].CATE_NAME);
    };

    const handleNextClick = () => {
        if (selectedImage === null) {
            setModalMessage("카테고리를 선택해 주세요.");
            setShowModal(true);
        }
        else {
            setStep(1);
        }
    };
    const handleBeforeClick = () => {
        setStep(0);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="지도학습">단어 맞추기 (의사결정트리 모델)</Header>
            <HeaderNav to1="/wordmatchconcept" to2="/wordmatch" />
            <main>
                <div className="main-step-img">
                    <Step step1="단어 선택" state1={0} step2="단어 맞추기" state2={2} step3="결과 확인" state3={2}>
                        단어 선택 단계에서는 의사결정트리 모델을 이용하여 맞출 단어의 카테고리 및 단어를 선택합니다.
                    </Step>
                </div>

                <div className="white-back">
                    {step === 0 && <>
                        <h2 style={{ textAlign: "left", margin: "1rem 2rem 0rem 2rem" }}>단어 맞추기를 시작할 카테고리를 고르세요.</h2>
                        <div className="wordmatch1-select-painter">
                            <div className={`masterpiece1-painter ${selectedImage === 0 ? "selected" : ""}`} onClick={() => handleImageClick(0)}>
                                <div className="masterpiece1-painter-text">
                                    <p>• 과일</p>
                                </div>
                                <img src={fruitImg} alt="fruitImg" />
                            </div>
                            <div className={`masterpiece1-painter ${selectedImage === 1 ? "selected" : ""}`} onClick={() => handleImageClick(1)}>
                                <div className="masterpiece1-painter-text">
                                    <p>• 스포츠</p>
                                </div>
                                <img src={sportsImg} alt="sportsImg" />
                            </div>
                            <div className={`masterpiece1-painter ${selectedImage === 2 ? "selected" : ""}`} onClick={() => handleImageClick(2)}>
                                <div className="masterpiece1-painter-text">
                                    <p>• 영화</p>
                                </div>
                                <img src={movieImg} alt="movieImg" />
                            </div>
                        </div>
                    </>}

                    {step === 1 && <>

                        <div className="wordmatch1-kedu">
                            <img src={kedu} alt="kedu icon" />
                            <div className="wordmatch1-kedu-textdiv">
                                <p> 아래 [{getCateName()}] 중 하나를 <span>생각</span>해 주세요.</p>
                            </div>
                        </div>
                        <Selectpicture />
                    </>}

                </div>

                <div className="main-btn">
                    {step === 0 && <>
                        <GreyBtn to="/wordmatch"></GreyBtn>
                        <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>
                    </>}
                    {step === 1 && <>
                        <GreyBtn onClick={handleBeforeClick}></GreyBtn>
                        <BlueBtn to="/wordmatch/2">다음 단계로</BlueBtn>
                    </>}
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}>
                                <b>닫기</b>
                            </button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}
