/**
 * @filePath        : src\components\pages\supervised\emotion\emotionintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import inputdata from "assets/supervised/emotion/inputdata.png";
import analysis from "assets/supervised/emotion/analysis.png";
import checkresult from "assets/supervised/emotion/checkresult.png";
import learningmodel from "assets/supervised/emotion/learningmodel.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">텍스트 감정 분석하기 (텍스트 분류모델)</Header>
            <HeaderNav to1="/emotionconcept" to2="/emotion" />
            <Conceptcheck>네이버에서 공개한 영화 리뷰데이터를 활용한 모델을 만들어 리뷰데이터의 텍스트 감정을 분류하여 봅시다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>텍스트 감정 분석 모델은 주어진 텍스트에서 긍정적인 감정과 부정적인 감정을 학습하여 새롭게 제시된 텍스트의 감정을 분석합니다.</p>
                        <p>본 프로그램을 활용하여 리뷰데이터의 긍정적인 감정과 부정적인 감정을 분류하는 체험할 수 있습니다.</p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/06_텍스트 감정 분석하기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={inputdata}>
                            데이터 입력
                        </Card>
                        <Card btn="Step 2" img={analysis}>
                            텍스트 감정 분석
                        </Card>
                        <Card btn="Step 3" img={learningmodel}>
                            모델 평가
                        </Card>
                        <Card btn="Step 4" img={checkresult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/emotion/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
