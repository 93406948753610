/**
 * @filePath        : src\components\pages\supervised\genderage\genderageconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/supervised/genderage/genderage_example1.png";
import example2 from "assets/supervised/genderage/genderage_example2.png";
import example3 from "assets/supervised/genderage/genderage_example3.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "자동차 번호판 인식",
            desc1:
                "이미지 분류 모델은 자동차 번호판 인식을 통해 이미지 수집, 전처리, 문자 검출, 문자 인식, 번호판 문자열 복원 및 번호판 인식 결과 같은 이미지 데이터를 확보하여 이를 바탕으로 대상 자동차 번호판을 분류하는데 사용됩니다.",
            desc2:
                "자동차 번호판 인식을 통해 교통 관리를 효율적으로 하고 보안 및 법 집행 기관에 중요한 역할을 하는데 사용됩니다.",
            page: "1/3"
        },
        {
            image: example2,
            title: "농업 및 작물 감지",
            desc1:
                "이미지 분류 모델은 농업에서 드론이나 원격 센서를 사용하여 농작물의 성장 단계, 건강 상태 및 수확 시기를 예측하는 데이터를 수집하고 이를 활용하여 농부가 적절한 조치를 취하도록 돕는데 사용됩니다.",
            desc2:
                "이러한 농업에서 응용 사례는 농업과 작물 관리 분야에서 이미지 분류 모델을 통해 자동화와 효율성을 증대시키는 데 도움이 되며, 작물 생산량을 최적화하고 농업 생산성을 향상시킵니다.",
            page: "2/3"
        },
        {
            image: example3,
            title: "얼굴 인식",
            desc1:
                "얼굴 인식에 활용되는 이미지 분류 모델은 얼굴 특징과 패턴을 추출하여 해당 얼굴을 분류하고 이를 바탕으로 새로운 얼굴 이미지를 분석하고 식별함으로써 보안, 인증, 엑세스 컨트롤, 감시 및 감지 등의 목적으로 다양하게 활용됩니다.",
            desc2:
                "이러한 이미지 분류 모델이 활용된 얼굴 인식은 최신 스마트폰에는 얼굴 인식으로 잠금해제 할 수 있는 기능이 있고, 공항 및 출입국 관리, 의료 서비스 등 여러 방면에서 중요한 기술로 사용되고 있습니다.",
            page: "3/3"
        },

    ];
    return (
        <>
            <Header learning="지도학습">성별 나이 분류하기 (이미지 분류모델)</Header>
            <HeaderNav to1="/genderageconcept" to2="/genderage" />
            <Conceptcheck> 이미지 분류 모델은 생활에서 활용되고 있습니다.
                가장 대표적인 예로 자동차 번호판 인식, 농업 및 작물 감지, 얼굴 인식 등이 있습니다.
            </Conceptcheck>
            <main>

                <Carousel items={carouselItems} />

                <BlueBtn to="/genderage">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}