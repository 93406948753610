/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatientconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/supervised/emotion/emotion_example2.png";
import example2 from "assets/unsupervised/seasons/seasons_example1.png";
import example3 from "assets/unsupervised/seasons/seasons_example2.png";
import example4 from "assets/supervised/object/object_example5.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "스팸 메일 분류",
            desc1:
                "스팸 메일이란 인터넷에서 일반 사람들에게 일방적으로 전달되는 광고용 메일입니다. 대부분 광고용이지만, 개인정보를 유출하거나 악성 프로그램을 허락없이 설치하여 컴퓨터를 사용하기 어렵게 만드는 나쁜 스팸 메일도 있습니다.",
            desc2:
                "하지만 대부분의 이메일 서비스 제공자들은 사용자들을 위해 스팸 메일을 분류하고 차단해 주고 있습니다. 이때 스팸 여부를 판별하여 메일을 자동으로 분류해 주는데 분류모델이 사용됩니다.",
            page: "1/4"
        },
        {
            image: example2,
            title: "추천 시스템",
            desc1:
                "추천시스템은 딥러닝의 분류모델을 활용하여 소셜 네트워크 서비스(SNS) 사용자들을 공통된 관심사를 가진 그룹으로 묶습니다. 묶은 그룹에서 발견되는 유사한 관심사와 행동 패턴을 통해 특정 주제에 대해 추천을 하는 시스템을 만듭니다.",
            desc2:
                "예를 들어, 특정 관심사를 가진 사용자 그룹에게 해당 관심사에 관련된 제품이나 콘텐츠를 추천하거나, 그룹 내에서 인기 있는 항목을 강조하여 보여줄 수 있습니다.",
            page: "2/4"
        },
        {
            image: example3,
            title: "검색 엔진",
            desc1: "검색 엔진은 핵심어를 이용해서 인터넷상의 정보를 찾아 주는 도구 또는 서비스를 말합니다.",
            desc2: "인터넷상의 문자 데이터 내에서 핵심적인 정보를 찾아내고 관련성이 있는 내용을 그룹화 또는 분류합니다. 이런 그룹화와 분류를 통해 사용자가 요구하는 특정 주제에 대한 효과적인 정보를 연결하여 제공합니다. 이런 과정을 통하여 개인에게 맞는 정보 검색 결과를 제공해주는 시스템이 검색 엔진입니다. ",
             page: "3/4"
        },
        {
            image: example4,
            title: "의료 데이터 분석",
            desc1:
                "의료 데이터 분석 모델은 환자의 암, 병변, 해부 구조와 같은 중요한 정보를 식별하고 정확하게 분석하는 데에 활용됩니다. ",
            desc2:
                "이는 조기 발견과 정확한 진단을 통해 환자의 생존률을 높이고 치료 과정을 개선하는 데에 도움을 줍니다. 이처럼 의료 분야에서 분류 모델은 의사들의 판단을 보완하고 환자 치료에 높은 수준의 정확성과 효율성을 제공하는 데에 큰 역할을 하며, 현대 의료 기술의 발전에 기여하고 있습니다.",
            page: "4/4"
        },
    ];
    return (
        <>
            <Header learning="지도학습">감기환자 분류하기 (분류 모델)</Header>
            <HeaderNav to1="/coldpatientconcept" to2="/coldpatient" />
            <Conceptcheck> 분류 모델은 생활에서 다양하게 활용되고 있습니다.
                가장 대표적인 예로 스팸 메일 분류, 추천 시스템, 검색 엔진 등이 있습니다.</Conceptcheck>
            <main>
                <Carousel items={carouselItems} />

                <BlueBtn to="/coldpatient">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}