/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 모델 선택 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import model1 from "assets/supervised/coldpatient/coldSlideImg1.png";
import model2 from "assets/supervised/coldpatient/coldSlideImg2.png";
import model3 from "assets/supervised/coldpatient/coldSlideImg3.png";
import model4 from "assets/supervised/coldpatient/coldSlideImg4.png";
import model5 from "assets/supervised/coldpatient/coldSlideImg5.png";
import { useState } from "react";
import ClusterItem from "./ClustersItem";
import { setModelindex } from "components/pages/supervised/coldpatient/coldpatient2/Modelindex";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const carouselItems = [
        {
            image: model1,
            title: "로지스틱 회귀",
            desc: "로지스틱 회귀는 회귀(Regression)을 사용하여 데이터가 어떤 범주에 속할 확률을 0에서 1사이의 값으로 예측하고 해당 확률을 이용해 더 높은 클래스에 속하는 것으로 분류하는 알고리즘 입니다.",
        },
        {
            image: model2,
            title: "KNN 분류",
            desc: "KNN 알고리즘은 새로운 데이터 포인트가 주어졌을 때 훈련 데이터세트에서 가장 가까운 데이터 포인트인 ‘최근접 이웃’을 찾습니다. 이때 k는 찾고자 하는 최근접 이웃의 개수를 뜻합니다.",
        },
        {
            image: model3,
            title: "LightGBM",
            desc: "lightGBM은 의사결정 트리를 이용한 앙상블모델 중 하나로 다른 앙상블 모델과 달리 의사결정 트리를 수직적으로 확장하며 학습합니다. 정형데이터에 대해 강력한 성능을 보여주며, 이름에 light가 붙은 만큼 가볍고, 빠릅니다.",
        },
        {
            image: model4,
            title: "서포트 벡터 머신",
            desc: "서포트 벡터 머신은 지도학습에서 데이터 분포를 나누는 기준을 결정하는 알고리즘으로 패턴으로 데이터를 분류한 후 데이터 사이의 거리에 따라 어떤 카테고리에 속할 것인지를 판단합니다.",
        },
        {
            image: model5,
            title: "랜덤포레스트",
            desc: "랜덤포레스트는 의사결정 트리의 그룹입니다. 각각의 의사결정 트리를 만들 때 사용될 속성을 제한하여 다양성을 갖는 트리를 생성한 뒤 각각의 트리로부터 분류 결과를 취합하여 결론을 내는 방식으로 작동합니다.",
        },
    ];
    const currentItem = carouselItems[currentIndex];

    const prevItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length);
    };
    const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };

    const handleNextClick = () => {
        setModalMessage(`${currentItem.title}모델을 선택하셨습니다 !`);
        setModelindex(currentIndex);
        setShowModal(true);
    };

    return (
        <>
            <Header learning="지도학습">감기환자 분류하기 (분류 모델)</Header>
            <HeaderNav to1="/coldpatientconcept" to2="/coldpatient" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 선택" state2={0} step3="모델성능평가" state3={2} step4="결과 확인" state4={2}>
                        감기환자 분류에 사용할 머신러닝 알고리즘 입니다. RandomForest, Logistic Regression, Lightgbm 등 다양한 모델을 준비했습니다.<br/>
                        모델의 장,단점을 알고 선택하여 실행해보세요.
                    </Step>
                </div>

                <div className="white-back">

                    <div className="coldpatient2-carousel">
                        <button onClick={prevItem} style={{ fontSize: "2rem", color: "gray", cursor: "pointer" }}>◀</button>

                        <div style={{ width: "80%", height: "30rem", display: "flex" }}>
                            <ClusterItem
                                image={currentItem.image}
                                title={currentItem.title}
                                desc={currentItem.desc} />
                        </div>
                        <button onClick={nextItem} style={{ fontSize: "2rem", color: "gray", cursor: "pointer" }}>▶</button>
                    </div>

                </div>

                <div className="main-btn">
                    <GreyBtn to="/coldpatient/1"></GreyBtn>
                    <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>
                </div>


                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3 style={{ margin: "0" }}>{modalMessage}</h3>
                            <h3 style={{ margin: "0" }}>학습을 시작합니다.</h3>
                            <BlueBtn to="/coldpatient/3">모델 실행</BlueBtn>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}