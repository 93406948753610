/**
 * @filePath        : src\components\pages\supervised\weather\weather1\Num.js
 * @fileName        : Num.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 날짜 개별 컴포넌트 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
export default function Num({ date, weather, averagetemp, lowesttemp, highesttemp, rainfall, humidity, day, light, sunrise }) {
    return (
        <div className="num">
            <div className="num-ele">
                <b>{date}</b>
                {weather && <img src={require(`assets/supervised/weather/${weather}.png`)} alt="weather" />}
            </div>

            <div className="num-span">
                <div style={{color:"grey"}}>
                    {averagetemp && <p>평균 기온</p>}
                    {lowesttemp && <p>최저 기온</p>}
                    {highesttemp && <p>최고 기온</p>}
                    {(rainfall===0||rainfall) && <p>강수량</p>}
                    {humidity && <p>습도</p>}
                    {day && <p>요일</p>}
                    {(light===0||light) && <p>일조 시간</p>}
                    {sunrise && <p>일출시간</p>}
                </div>
                <div style={{fontWeight:"bold"}}>
                    {averagetemp && <p>{averagetemp}°C</p>}
                    {lowesttemp && <p style={{ color: "#0084ff" }}>{lowesttemp}°C</p>}
                    {highesttemp && <p style={{ color: "#ff0000" }}>{highesttemp}°C</p>}
                    {(rainfall===0||rainfall) && <p>{rainfall}mm</p>}
                    {humidity && <p>{humidity}%</p>}
                    {day && <p>{day}요일</p>}
                    {(light===0||light) && <p>{light}시간</p>}
                    {sunrise && <p>{sunrise}</p>}
                </div>
            </div>
        </div>
    );
}