/**
 * @filePath        : src\components\pages\reinforcement\cartpole\cartpole3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 막대 중심 잡기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn"
import Learingmodel from "components/pages/reinforcement/cartpole/cartpole3/Learningmodel";
import Step from "components/sections/boxs/Step";
import { useState } from "react";
export default function Main() {
    const [state, setState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleNextClick = () => {
        if (state === false) {
            setModalMessage("학습 결과를 모두 확인해주세요 !");
            setShowModal(true);
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const handleChange = (newValue) => {
        setState(newValue);
    };
    return (
        <>
            <Header learning="강화학습">막대 중심 잡기</Header>
            <HeaderNav to1="/cartpoleconcept" to2="/cartpole" />

            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={1} step2="모델 이해" state2={1} step3="결과 확인" state3={0}>
                        모델 학습이 완료되었습니다.<br/>
                        각각 횟수만큼 훈련 시켰을 때 컴퓨터 게임을 얼마나 잘하게 되었는지 확인해보세요
                    </Step>
                </div>

                <Learingmodel
                    try1="게임 10회 학습모델"
                    try2="게임 30회 학습모델"
                    try3="게임 100회 학습모델"
                    src1={"/videos/cartpole/cartpole_10.mp4"}
                    src2={"/videos/cartpole/cartpole_30_x4.mp4"}
                    src3={"/videos/cartpole/cartpole_100_x4.mp4"}
                    onChange={handleChange}
                />


                <div className="main-btn">
                    <GreyBtn to="/cartpole/2"></GreyBtn>
                    {state ? (
                        <BlueBtn to="/">체험 종료</BlueBtn>
                    ) : (
                        <BlueBtn onClick={handleNextClick}>체험 종료</BlueBtn>
                    )}
                </div>
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}