/**
 * @filePath        : src\components\pages\home\SelectedTabData.js
 * @fileName        : SelectedTabData.js
 * @author          : GHYANG
 * @date            : 2023.11.22
 * @description     : 선택된 탭 분류 관리하는 데이터
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.22		 GHYANG				 1.0
 */

export function setSelectedTabData(data) {
    sessionStorage.setItem("selectednum", data);
}
export function getSelectedTabData() {
    return sessionStorage.getItem("selectednum") * 1;
}
