/**
 * @filePath        : src\components\pages\supervised\weather\weather3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 모델 학습 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import GreenB from "components/pages/supervised/weather/weather3/GreenB";
import ProgressBar from "components/sections/boxs/CustomProgressBar";
import greenboard from "assets/supervised/weather/greenboard_withteacher.png";
import { useState, useEffect } from "react";
import { getSelectedelementnames } from 'components/pages/supervised/weather/weather1/WeatherData';
import axios from "axios";
import { URL } from 'Config.js';
import { setRealdata, setPredictiondata, setScore } from "components/pages/supervised/weather/weather4/Energydata";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [elementNames, setElementNames] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);


    //선택된 기상요소 데이터를 서버에 전달하고 그에 따른 데이터 받기
    useEffect(() => {
        const elementToAdd = [];
        const selectedCheckboxNames = getSelectedelementnames();

            if (selectedCheckboxNames.includes("평균 기온")) {
                elementToAdd.push("temp_mean");
            }
            if (selectedCheckboxNames.includes("최고 기온")) {
                elementToAdd.push("temp_high");
            }
            if (selectedCheckboxNames.includes("최저 기온")) {
                elementToAdd.push("temp_low");
            }
            if (selectedCheckboxNames.includes("강수량")) {
                elementToAdd.push("rain");
            }
            if (selectedCheckboxNames.includes("습도")) {
                elementToAdd.push("humid");
            }
            if (selectedCheckboxNames.includes("요일")) {
                elementToAdd.push("day");
            }
            if (selectedCheckboxNames.includes("일조 시간")) {
                elementToAdd.push("light");
            }
            if (selectedCheckboxNames.includes("일출 시간")) {
                elementToAdd.push("sunrise");
            }
            setElementNames((prevElementNames) => {
                const newElementNames = [...new Set([...prevElementNames, ...elementToAdd])];
                return newElementNames;
            });
    }, []);

    const handleNextClick = () => {
        setLoading(true);

        const requestData = {
            elementname: elementNames,
        };

        axios
            .post(`${URL}/api/supervised/weather/energydata`, requestData)
            .then((response) => {
                //console.log("서버 응답:", response.data);
                setRealdata(response.data.realdata);
                setPredictiondata(response.data.values.Vals);
                setScore(response.data.values.SCORE.toFixed(1));
            })
            .catch((error) => {
                console.log('error: ' + error);
            });

        setTimeout(() => {
            setModalMessage("분석이 완료되었습니다 ! 결과를 확인해볼까요?");
            setShowModal(true);
            setLoading(false);
        }, 2000);
    };



    return (
        <>
            <Header learning="지도학습">날씨에 따른 전기 사용량 예상 (회귀 모델)</Header>
            <HeaderNav to1="/weatherconcept" to2="/weather" />
            <main>

                <div className="main-step-img">
                    <Step step1="변수 선택" state1={1} step2="모델 이해" state2={1} step3="모델 학습" state3={0} step4="모델 평가" state4={2}>
                        모델링에 사용할 날씨 변수 선택과 회귀 모형 확인을 모두 마쳤습니다.<br />
                        지금까지 완료한 내용을 바탕으로 회귀 모델을 만들어 전기 사용량 예상을 진행해 봅시다.
                    </Step>
                </div>
                <div className="weather3-boardzone">
                    <div className="board-wrap">
                        <div className="board-img">
                            <img src={greenboard} alt="img3" />
                        </div>

                        <div className="board-text">
                            <GreenB />
                        </div>
                        {loading && <ProgressBar text1="분석중입니다." text2="잠시만 기다려주세요." />}
                    </div>

                </div>



                <div className="main-btn">
                    <GreyBtn to="/weather/2"></GreyBtn>
                    <BlueBtn onClick={handleNextClick}>분석 시작</BlueBtn>
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <BlueBtn to="/weather/4">결과 확인</BlueBtn>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}