/**
 * @filePath        : src\components\pages\unsupervised\news\news2\Models.js
 * @fileName        : Models.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 모델의 모델 선택 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import student from "assets/unsupervised/news/student.png";
import robot from "assets/unsupervised/news/robot.png";
import grandfa from "assets/unsupervised/news/grandfa.png";
import king from "assets/unsupervised/news/king.png";
import student_selec from "assets/unsupervised/news/student_selec.png";
import robot_selec from "assets/unsupervised/news/robot_selec.png";
import grandfa_selec from "assets/unsupervised/news/grandfa_selec.png";
import king_selec from "assets/unsupervised/news/king_selec.png";

import { useState, useEffect } from "react";

export default function Models({ onModelSelected, step, onStateChange }) {
    const [selectedModel, setSelectedModel] = useState(null);

    const handleModelClick = (model) => {
        if(step === 2){
            onStateChange(step-1);
          }
  
        setSelectedModel((prevModel) => (prevModel === model ? null : model));
    };

    useEffect(() => {
        onModelSelected(selectedModel);
    }, [selectedModel, onModelSelected]);

    return (
        <>
            <div
                className={`news-model-choice ${selectedModel === "student" ? "selected" : ""}`}
                onClick={() => handleModelClick("student")}>
                {selectedModel==="student"? 
                ( <img src={student_selec} alt="pretrainedmodel" />):( <img src={student} alt="pretrainedmodel" />)}
                학생
            </div>
            <div
                className={`news-model-choice ${selectedModel === "robot" ? "selected" : ""}`}
                onClick={() => handleModelClick("robot")}>
                {selectedModel==="robot"? 
                ( <img src={robot_selec} alt="pretrainedmodel" />):( <img src={robot} alt="pretrainedmodel" />)}
                옛날 로봇
            </div>
            <div
                className={`news-model-choice ${selectedModel === "king" ? "selected" : ""}`}
                onClick={() => handleModelClick("king")}>
                {selectedModel==="king"? 
                ( <img src={king_selec} alt="pretrainedmodel" />):( <img src={king} alt="pretrainedmodel" />)}
                임금님
            </div>
            <div
                className={`news-model-choice ${selectedModel === "grandfa" ? "selected" : ""}`}
                onClick={() => handleModelClick("grandfa")}>
                {selectedModel==="grandfa"? 
                ( <img src={grandfa_selec} alt="pretrainedmodel" />):( <img src={grandfa} alt="pretrainedmodel" />)}
                할아버지
            </div>
        </>
    );
}