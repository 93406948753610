/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons4\Date.js
 * @fileName        : Date.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델 날짜 선택 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState } from "react";

export default function Date({ onDateChange }) {
    const [selectedMonth, setSelectedMonth] = useState("01"); // 초기 월 설정 (01: 1월)
    const [selectedDay, setSelectedDay] = useState("01"); // 초기 일 설정 (01: 1일)

    const months = [
        { value: "01", label: "1" },
        { value: "02", label: "2" },
        { value: "03", label: "3" },
        { value: "04", label: "4" },
        { value: "05", label: "5" },
        { value: "06", label: "6" },
        { value: "07", label: "7" },
        { value: "08", label: "8" },
        { value: "09", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
    ];

    const daysInMonth = (month) => {
        if (["04", "06", "09", "11"].includes(month)) {
            return 30;
        } else if (month === "02") {
            return 28;
        } else {
            return 31;
        }
    };

    const days = Array.from({ length: daysInMonth(selectedMonth) }, (_, i) => {
        const day = (i + 1).toString().padStart(2, "0");
        return { value: day, label: i+1 };
    });

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        const formattedDate = `2022-${event.target.value}-${selectedDay}`;
        onDateChange(formattedDate); // 선택한 날짜를 전달
    };

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
        const formattedDate = `2022-${selectedMonth}-${event.target.value}`;
        onDateChange(formattedDate); // 선택한 날짜를 전달
    };

    return (
        <div>
            <b style={{ marginRight: "1rem" }}>2022년</b>
            <div className="seasons4-dateselect">
                <select value={selectedMonth} onChange={handleMonthChange}>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.label}
                        </option>
                    ))}
                </select>
                월
            </div>
            <div className="seasons4-dateselect">
                <select value={selectedDay} onChange={handleDayChange}>
                    {days.map((day) => (
                        <option key={day.value} value={day.value}>
                            {day.label}
                        </option>
                    ))}
                </select>
                일
            </div>
        </div>
    );
}