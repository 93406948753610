/**
 * @filePath        : src\components\pages\unsupervised\news\newsintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import inputdata from "assets/unsupervised/news/inputdata.png";
import modelinference from "assets/unsupervised/news/modelinference.png";
import checkresult from "assets/unsupervised/news/checkresult.png";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="비지도학습">AI를 이용한 여러 종류의 글쓰기 (사전학습 모델)</Header>
            <HeaderNav to1="/newsconcept" to2="/news" />
            <Conceptcheck>학생, 옛날 로봇, 임금님, 할아버지처럼 글을 써주는 AI 모델을 만들어보자</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            최근 Chat-GPT와 같은 생성형 AI가 많은 주목을 받고, 생활에서 사용되고 있습니다. 이 같은 프로그램에 사용된 사전 학습 모델은 컴퓨터가
                            많은 데이터를 학습하여 지식을 습득한 뒤, 이를 활용해 다양한 작업을 수행하는 프로그램입니다.
                        </p>
                        <p>
                            본 프로그램은 데이터를 전처리하는 과정을 학습한 후, 만들어진 모델을 이용해서 학생이 새로운 단어를 제시해 주었을 때 학생, 옛날 로봇,
                            임금님, 할아버지들을 대신하여 AI가 글을 작성해 주는 과정을 체험할 수 있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/2차/04_AI를 이용한 여러 종류의 글쓰기_사용자 안내서_v1.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={inputdata}>
                            데이터 입력
                        </Card>
                        <Card btn="Step 2" img={modelinference}>
                            모델 선택
                        </Card>
                        <Card btn="Step 3" img={checkresult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/news/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
