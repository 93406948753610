/**
 * @filePath        : src\components\pages\unsupervised\news\news1\NewsTextdata.js
 * @fileName        : NewsTextdata.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 전처리 텍스트 데이터
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
const data = [
    { category: "king", validtext: "어제는 피곤해서 일찍 잠에 들었소.", errortext: "내일은 피곤해서 일찍 잠에 들었소." },
    { category: "student", validtext: "우유를 마시면서 아침 식사를 했어.", errortext: "우유는 마시면서 아침 식사를 했어." },
    { category: "robot", validtext: "나는.어제.사과를.먹었다", errortext: "먹었다.나는.어제.사과를" },
    { category: "halbae", validtext: "내일은...공원에서...산책을...할거야...", errortext: "어제은...공원을...산책을...할거야..." },
    { category: "king", validtext: "벗들과 이야기를 나누며 밥을 먹으면 즐겁소.", errortext: "벗들과 이야기를 나누며 밥을 먹을까 즐겁소." },
    { category: "student", validtext: "비가 오는 날에는 실내에서 영화 보는 게 좋아.", errortext: "비가 오는 날에는 실내에서 영화 봤다 좋아." },
    { category: "robot", validtext: "일본.여행.재밌었다.", errortext: "재밌었다.여행.일본." },
    {
        category: "halbae",
        validtext: "요즘...새로운...취미를...찾고...있는데...그림...그리기에...관심을...가지게...되었어...",
        errortext: "요즘...새로운...취미를...찾아...있는데...그림...그리기에...관심을...가지게...되었어...",
    },
];

export function getNewsTextdata() {
    return data;
}
