/**
 * @filePath        : src\components\pages\supervised\emotion\emotionconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/supervised/emotion/emotion_example1.png";
import example2 from "assets/supervised/emotion/emotion_example2.png";
import example3 from "assets/supervised/emotion/emotion_example3.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "뉴스 기사 분류",
            desc1:
                "텍스트 분류 모델은 뉴스의 텍스트 즉 단어를 분석하여 비슷한 주제의 뉴스들을 분류하는데 사용됩니다.",
            desc2:
                "텍스트 분류 모델을 활용한 뉴스 기사 분류는 디지털 기술의 확산으로 온라인 뉴스 생산량이 급증하고 있고, 그로 인해 대량으로 생산되는 언론 보도를 빠르게 구분하여 연관성이 있는 기사를 묶어냄으로써 독자가 원하는 다양한 정보를 빠르게 제공할 수 있습니다.",
            page: "1/3"
        },
        {
            image: example2,
            title: "스팸 메일 분류",
            desc1:
                "스팸 메일이란 인터넷에서 일반 사람들에게 일방적으로 전달되는 광고용 메일입니다. 대부분 광고용이지만, 개인정보를 유출하거나 악성 프로그램을 허락없이 설치하여 컴퓨터를 사용하기 어렵게 만드는 나쁜 스팸 메일도 있습니다.",
            desc2:
                "하지만 대부분의 이메일 서비스 제공자들은 사용자들을 위해 스팸 메일을 분류하고 차단해 주고 있습니다. 이때 스팸 여부를 판별하여 메일을 자동으로 분류해 주는데 분류모델이 사용됩니다.",
             page: "2/3"
        },
        {
            image: example3,
            title: "댓글 검열",
            desc1:
                "댓글 검열에 활용되는 텍스트 분류 모델은 대량의 댓글 데이터를 분석하여 주어진 댓글이 악성 댓글인지 아닌지를 판별하는 데에 활용됩니다. ",
            desc2:
                "텍스트 분류 모델을 사용한 댓글 검열은 온라인 플랫폼에서 사용자 경험을 개선하고 불쾌한 내용으로부터 사용자를 보호하는데 중요한 역할을 합니다.",
            page: "3/3"
        },

    ];
    return (
        <>
            <Header learning="지도학습">텍스트 감정 분석하기 (텍스트 분류모델)</Header>
            <HeaderNav to1="/emotionconcept" to2="/emotion" />
            <Conceptcheck> 텍스트 분류 모델은 생활에서 활용되고 있습니다.
                가장 대표적인 예로 뉴스 기사 분류, 스팸 메일 분류, 댓글 검열 등이 있습니다. </Conceptcheck>
            <main>

                <Carousel items={carouselItems} />

                <BlueBtn to="/emotion">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}