/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatientintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import checkdata from "assets/supervised/coldpatient/checkdata.png";
import learningmodel from "assets/supervised/coldpatient/learningmodel.png";
import learningmodel2 from "assets/supervised/coldpatient/learningmodel2.png";
import checkresult from "assets/supervised/coldpatient/checkresult.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">감기환자 분류하기 (분류 모델)</Header>
            <HeaderNav to1="/coldpatientconcept" to2="/coldpatient" />
            <Conceptcheck>감기환자 데이터를 분석하여 감기환자를 분류하여봅시다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            지도학습의 분류는 훈련 데이터에 각각 레이블(정답)을 지정하여 학습하고 새로운 데이터가 주어졌을 때 알맞은 레이블로 분류하는
                            방법입니다.
                        </p>
                        <p>본 프로그램을 활용하여 감기환자 데이터를 분석하여 정상인과 감기환자를 분류하는 모델을 체험할 수 있습니다.</p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/04_감기환자 분류하기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={checkdata}>
                            데이터 확인
                        </Card>
                        <Card btn="Step 2" img={learningmodel2}>
                            모델 선택
                        </Card>
                        <Card btn="Step 3" img={learningmodel}>
                            모델성능평가
                        </Card>
                        <Card btn="Step 4" img={checkresult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/coldpatient/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
