/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons1\Desc.js
 * @fileName        : Desc.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 예시 이미지 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import dataexample1 from "assets/unsupervised/seasons/dataexample1.png";
import dataexample2 from "assets/unsupervised/seasons/dataexample2.png";
import dataexample3 from "assets/unsupervised/seasons/dataexample3.png";

export default function Desc() {
    return (
        <>
            <div className="seasons1-main">
                {/* <iframe
                    src="https://www.weather.go.kr/plus/land/current/past_table.jsp" 
                    width="90%"                   
                    height="100%"                  
                    title="weather example data site"
                    frameBorder="none"
                ></iframe> */}

                <img src={dataexample1} alt="dataexample img" />
                <img src={dataexample2} alt="dataexample img" />
                <img src={dataexample3} alt="dataexample img" />
            </div>
        </>
    );
}