/**
 * @filePath        : src\components\pages\supervised\weather\weatherintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import selectdata from "assets/supervised/weather/selectdata.png";
import checkmodel from "assets/supervised/weather/understandmodel.png";
import learningmodel from "assets/supervised/weather/learningmodel.png";
import checkresult from "assets/supervised/weather/evaluationmodel.png";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">날씨에 따른 전기 사용량 예상 (회귀 모델)</Header>
            <HeaderNav to1="/weatherconcept" to2="/weather" />
            <Conceptcheck>날씨에 따른 전기 사용량을 예상해보자</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            폭염과 열대야 등의 기상 이변으로 인해 여름철 전기 사용량이 꾸준히 증가하고 있습니다. 그러나 전기를 생산하고 공급하는 계획을 정확히
                            세우는 것은 여전히 어려운 일로, 이전에는 전기 생산량을 실제 사용량보다 작게 잡아서 기업들에게 여러 차례 전기 사용량을 줄이라고
                            요청하기도 했습니다. 이와 같은 사태를 방지하기 위해 회귀 모델을 활용하여 전기 사용량을 미리 예상해보고자 합니다.
                        </p>
                        <p>
                            본 프로그램에서는 온도, 습도 등의 날씨 데이터를 기반으로 전기 사용량을 예상하는 회귀 모델을 만들어보고, 이 모델을 활용하여 전기
                            사용량의 예상 값을 구하는 과정을 체험할 수 있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/2차/01_날씨에 따른 전기 사용량 예상_사용자 안내서_v1.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={selectdata}>
                            변수 선택
                        </Card>
                        <Card btn="Step 2" img={checkmodel}>
                            모델 이해
                        </Card>
                        <Card btn="Step 3" img={learningmodel}>
                            모델 학습
                        </Card>
                        <Card btn="Step 4" img={checkresult}>
                            모델 평가
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/weather/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
