/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델의 모델 이해 단계 스텝형식으로 설명
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import game_step1 from "assets/reinforcement/arctic/game_step1.png";
import game_step2 from "assets/reinforcement/arctic/game_step2.png";
import game_step3 from "assets/reinforcement/arctic/game_step3.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import { useState } from "react";

export default function Main() {
    const [step, setStep] = useState(1);
    return (
        <>
            <Header learning="강화학습">북극에서 살아남기</Header>
            <HeaderNav to1="/arcticconcept" to2="/arctic" />

            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={1} step2="모델 이해" state2={0} step3="결과 확인" state3={2}>
                        아래의 설명을 읽고 강화학습의 개념을 이해해봅시다.<br />
                    </Step>
                </div>
                <div className="white-back">
                    {step === 1 && (
                        <div className="arctic2-main">
                            <div className="arctic2-main-left">
                                <img src={game_step1} alt="game_step1"></img>
                            </div>

                            <div className="arctic2-main-right">
                                <p>
                                    S는 시작 지점, F는 일반 빙판 길, G가 목표지점 그리고 H가 구멍입니다.
                                </p>
                                <p>
                                펭귄은 왼쪽 그림에서 S(시작지점)에서 출발하여 H(구멍)을 피해 G(목표지점)에 도달하면 됩니다.
                                </p>
                            </div>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="arctic2-main">
                            <div className="arctic2-main-left">
                                <img src={game_step2} alt="game_step2"></img>
                            </div>
                            <div className="arctic2-main-right">
                                <p>
                                단, 펭귄 친구는 얼음 위에 있어서 현재 상태만 알 수 있고 전체 길을 볼 수는 없어요. 
                                    또한, 펭귄이 어떤 행동을 하면 그 결과가 잘한 건지, 아닌지 알 수 없어요.
                                </p>
                                <p>
                                    에이전트인 펭귄은 여러 가지 행동을 시도하다가 목적지에 도착하거나
                                    구멍에 빠져서 게임이 끝날 때까지 계속 노력하면서 가장 좋은 방법을 찾아내는거에요.
                                </p>
                                <p>
                                    이렇게 펭귄은 끊임없이 노력하고, 실패하며 학습하면서 최고의 길을 찾아냅니다.
                                    그리고 목적지에 도착하면 보상을 받아요.
                                    에이전트인 펭귄은 이와 같이 게임을 계속 반복하다 보면 점점 더 똑똑해져 빨리 길을 찾게 됩니다.
                                </p>
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div className="arctic2-main">
                            <div className="arctic2-main-left">
                                <img src={game_step3} alt="game_step3"></img>
                            </div>
                            <div className="arctic2-main-right">
                                <p>
                                    강화학습은 펭귄처럼 여러 가지 행동을 시도하고,
                                    그 결과로 보상을 받으면서 가장 좋은 방법을 배우는 특별한 학습 방법이에요.
                                </p>
                            </div>
                        </div>
                    )}
                    {step === 4 && (
                        <>
                            <div>
                                <h3>강화 학습 모델이 100회 학습되는 과정입니다.</h3>
                            </div>

                            <video
                                muted
                                autoPlay
                                height="100%"
                                className="cartpole-learningmodel-simulation">
                                <source src={"/videos/arctic/training_video.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </>
                    )}
                </div>

                <div className="main-btn">
                    {step === 1 && (
                        <>
                            <GreyBtn to="/arctic/1"></GreyBtn>
                            <BlueBtn onClick={() => setStep(step + 1)}>다음 단계</BlueBtn>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                            <BlueBtn onClick={() => setStep(step + 1)}>다음 단계</BlueBtn>
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                            <BlueBtn onClick={() => setStep(step + 1)}>다음 단계</BlueBtn>
                        </>
                    )}
                    {step === 4 && (
                        <>
                            <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                            <BlueBtn to="/arctic/3">모델 실행</BlueBtn>
                        </>
                    )}
                </div>
            </main>
        </>
    );
}