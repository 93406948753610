/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch1\WordmatchData.js
 * @fileName        : WordmatchData.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 단어 맞추기 모델의 데이터 관리 함수 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */

export function setCateId(data) {
    sessionStorage.setItem("cateId", data);
}
export function getCateId() {
    return sessionStorage.getItem("cateId");
}

export function setCateName(data) {
    sessionStorage.setItem("cateName", data);
}
export function getCateName() {
    return sessionStorage.getItem("cateName");
}

export function setDatasetId2(data) {
    sessionStorage.setItem("datasetId2", data);
}
export function getDatasetId2() {
    return sessionStorage.getItem("datasetId2");
}
