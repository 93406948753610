/**
 * @filePath        : src\components\pages\supervised\emotion\emotion4\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import { Link } from "react-router-dom";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import Result from "components/pages/supervised/emotion/emotion4/Result";

export default function Main() {
  return (
    <>
      <Header learning="지도학습">
        텍스트 감정 분석하기 (텍스트 분류모델)
      </Header>
      <HeaderNav to1="/emotionconcept" to2="/emotion" />
      <main>
        <div className="main-step-img">
          <Step
            step1="데이터 입력"
            state1={1}
            step2="텍스트 감정 분석"
            state2={1}
            step3="모델 평가"
            state3={1}
            step4="결과 확인"
            state4={0}
          >
            학습한 모델을 활용해 실제로 문장이 긍정적인지 부정적인지
            예측해봅시다.
            <br />
            예측에 사용할 데이터는 영화 리뷰데이터 입니다.
          </Step>
        </div>

        <div className="white-back">
          <div className="emotion4-re-btn-space">
            <div className="emotion4-re-btn">
              <Link onClick={() => (window.location.href = "/emotion")}>
                다시하기
              </Link>
            </div>
          </div>

          <Result />
        </div>

        <div className="main-btn">
          <GreyBtn to="/emotion/3"></GreyBtn>
          <BlueBtn to="/">체험 종료</BlueBtn>
        </div>
      </main>
    </>
  );
}
