/**
 * @filePath        : src\components\pages\supervised\genderage\genderage3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 모델 평가 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import emotionEstimation1 from "assets/supervised/genderage/emotionEstimation01.png";
import emotionEstimation2 from "assets/supervised/genderage/emotionEstimation03.png";
import TableGender from "components/pages/supervised/genderage/genderage3/TableGender";
import TableAge from "components/pages/supervised/genderage/genderage3/TableAge";
import { useState } from "react";

export default function Main() {
    const [step, setStep] = useState(0);

    return (
        <>
            <Header learning="지도학습">성별 나이 분류하기 (이미지 분류모델)</Header>
            <HeaderNav to1="/genderageconcept" to2="/genderage" />
            <main>
                <div className="main-step-img">
                    {step === 0 && (
                        <Step step1="데이터 확인" state1={1} step2="모델 선택" state2={1} step3="모델 평가" state3={0} step4="결과 확인" state4={2}>
                            모델이 잘 학습되었는지 평가지표를 통해 확인해봅시다.
                            먼저 모델이 성별을 잘 예측하는지 평가해봅시다. 성별은 남자/여자 두가지 이므로 이진분류에 해당합니다. <br />
                            이진분류모델의 성능을 평가하는 지표에는 정확도, 정밀도, 재현율 등이 있습니다.
                            학습한 모델의 평가지표를 보고 모델이 적절히 학습되었는지 평가해봅시다. </Step>
                    )}
                    {step === 1 && (
                        <Step step1="데이터 확인" state1={1} step2="모델 선택" state2={1} step3="모델 평가" state3={0} step4="결과 확인" state4={2}>
                            다음은 모델이 나이를 얼마나 잘 예측하는지 평가해봅시다.
                            나이는 0-2세, 3-8세 … 60-100세 까지 총 8가지로 예측하게 됩니다. <br />
                            이처럼 예측하는 클래스가 여러 개인 경우 다중분류모델이라고 합니다.
                            다중분류모델의 성능평가에는 어떤 방법이 있는지 확인해봅시다. </Step>
                    )}

                </div>

                <div className="white-back">
                    <div className="coldpatient3-main">

                        <div className="coldpatient3-main-left">
                            {step === 0 && (<img src={emotionEstimation1} alt="chart" />)}
                            {step === 1 && (<img src={emotionEstimation2} alt="chart" />)}
                        </div>
                        <div className="coldpatient3-main-right">
                            {step === 0 && (<>
                                <img src={require(`assets/supervised/genderage/A500_M500.png`)} alt="chart" />
                                <TableGender />
                            </>)}
                            {step === 1 && (<>
                                <img src={require(`assets/supervised/genderage/A500_M501.png`)} alt="chart" />
                                <TableAge />
                            </>)}

                        </div>
                    </div>
                </div>


                <div className="main-btn">
                    {step === 0 && (
                        <>
                            <GreyBtn to="/genderage/2"></GreyBtn>
                            <BlueBtn onClick={() => setStep(step + 1)}>다음 단계로</BlueBtn>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                            <BlueBtn to="/genderage/4">다음 단계로</BlueBtn>
                        </>
                    )}
                </div>
            </main >
        </>
    );
}

