/**
 * @filePath        : src\components\pages\reinforcement\flappy\flappy1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : Flappy Bird 모델의 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Learingmodel from "components/pages/reinforcement/flappy/flappy1/Learningmodel";
import Playgame from "components/pages/reinforcement/flappy/flappy1/Playgame";

export default function Main() {

    return (
        <>
            <Header learning="강화학습">Flappy Bird</Header>
            <HeaderNav to1="/flappyconcept" to2="/flappy" />
            <main>

                <div className="white-back">
                    <div className="grey-back">
                        <h3 style={{ color: "#76A7D4" }}>ㅤㅤ게임 플레이 방법</h3>
                        <p>ㅤㅤ• [ SPACE BAR ] 로 새를 점프하여 바닥과 다가오는 파이프를 피해 최대한 멀리 이동해 보세요.</p>
                    </div>


                    <div className="flappy1-main">
                        <div className="flappy1-main-left">
                            <h3>게임하기</h3>
                            <Playgame/>
                        </div>

                        <div className="flappy1-main-right">
                            <h3>강화학습</h3>
                            <Learingmodel
                                try1="게임 100회 학습모델"
                                try2="게임 1000회 학습모델"
                                try3="게임 10000회 학습모델"
                                src1={"/videos/flappy/score50_x2.mp4"}
                                src2={"/videos/flappy/score250_x4.mp4"}
                                src3={"/videos/flappy/score4000_x4.mp4"}
                            />
                        </div>
                    </div>

                </div>

                <div className="main-btn">
                    <GreyBtn to="/flappy"></GreyBtn>
                    <BlueBtn to="/">체험 종료</BlueBtn>
                </div>
            </main>
        </>
    );
}