/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic1\Penguin.js
 * @fileName        : Penguin.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델 게임의 펭귄 이동 제어 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useEffect } from "react";
import arrow_up from "assets/reinforcement/arctic/arrow_up.png";
import arrow_down from "assets/reinforcement/arctic/arrow_down.png";
import arrow_left from "assets/reinforcement/arctic/arrow_left.png";
import arrow_right from "assets/reinforcement/arctic/arrow_right.png";

const Penguin = ({ onMove }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          onMove(0, -1);
          break;
        case 'ArrowLeft':
          onMove(-1, 0);
          break;
        case 'ArrowRight':
          onMove(1, 0);
          break;
        case 'ArrowDown':
          onMove(0, 1);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onMove]);
  return (
    <>
      <div className="penguin-btn-controls">
        <div />
        <img src={arrow_up} alt="up" onClick={() => onMove(0, -1)} />
        <div />
        <img src={arrow_left} alt="left" onClick={() => onMove(-1, 0)} />
        <img src={arrow_down} alt="down" onClick={() => onMove(0, 1)} />
        <img src={arrow_right} alt="right" onClick={() => onMove(1, 0)} />
      </div>
    </>
  );
};

export default Penguin;