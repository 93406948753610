/**
 * @filePath        : src\components\pages\supervised\prediction\prediction1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 데이터 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Table from "components/pages/supervised/prediction/prediction1/Table";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">공유자전거 수요 예측 (딥러닝 모델)</Header>
            <HeaderNav to1="/predictionconcept" to2="/prediction" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={0} step2="모델 확인" state2={2} step3="결과 확인" state3={2}>
                        먼저 데이터를 확인해봅시다. 공유자전거 데이터는 날짜와 날씨데이터 그리고 공유자전거 수요로 이루어져 있습니다.
                        <br />
                        이번 체험에서 학습될 모델은 날짜와 날씨데이터를 입력으로 하여 공유자전거 수요를 예측하는 모델입니다.
                    </Step>
                </div>

                <div className="white-back">
                    <Table />
                </div>

                <div className="main-btn">
                    <GreyBtn to="/prediction"></GreyBtn>
                    <BlueBtn to="/prediction/2">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}
