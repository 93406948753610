/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 사진 선택 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Selectpicture from "components/pages/unsupervised/masterpiece/masterpiece2/Selectpicture";
import { useState } from "react";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [nextflag, setNextFlag] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");


    const handleChange = (newValue) => {
        setNextFlag(newValue);
    };
    const handleNextClick = () => {
        setModalMessage("사진을 선택해주세요 !");
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="비지도학습">사진 명화풍 만들기 (GAN 모델)</Header>
            <HeaderNav to1="/masterpiececoncept" to2="/masterpiece" />
            <main>
                <div className="main-step-img">
                    <Step step1="화가 선택" state1={1} step2="사진 선택" state2={0} step3="모델 확인" state3={2} step4="결과 확인" state4={2}>
                        명화풍으로 변환하고 싶은 사진을 선택합니다. [풍경], [인물], [정물] 카테고리 중 원하는 사진을 선택해보세요.
                    </Step>
                </div>

                <div className="white-back">
                    <h2 style={{ textAlign: "left", margin: "1rem 2rem 0rem 2rem" }}>사진 선택</h2>
                    <Selectpicture onChange={handleChange} />
                </div>

                <div className="main-btn">
                    <GreyBtn to="/masterpiece/1"></GreyBtn>
                    {nextflag === false ? (
                        <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>) : (
                        <BlueBtn to="/masterpiece/3">다음 단계로</BlueBtn>
                    )}
                </div>

            </main>

            {showModal && (
                <div className="modal-back">
                    <div className="modal">
                        <h3>{modalMessage}</h3>
                        <button onClick={closeModal}><b>닫기</b></button>
                    </div>
                </div>
            )}
        </>
    );
}