/**
 * @filePath        : src\components\pages\supervised\prediction\predictionintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import Card from "components/sections/cards/Card";
import checkData from "assets/supervised/prediction/checkData.png";
import checkModel from "assets/supervised/prediction/checkModel.png";
import checkResult from "assets/supervised/prediction/checkResult.png";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">공유자전거 수요 예측 (딥러닝 모델)</Header>
            <HeaderNav to1="/predictionconcept" to2="/prediction" />
            <Conceptcheck>딥러닝 모델의 손실함수를 활용하여 공유자전거 수요 예측을 해봅시다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            딥러닝 모델의 손실함수는 정답을 잘 맞추도록 학습하는 과정에서 모델이 예측한 값과 실젯값이 다르다면 모델을 수정해야 하는데 이때
                            모델의 규칙을 수정하는 기준이 되는 함수입니다. <br />본 프로그램을 활용하여 날짜와 기상데이터를 통해 공유자전거 수요를 예측하는
                            모델을 학습하고 그 과정에서 손실함수가 어떻게 모델을 변화하는지 체험할 수 있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/10_공유자전거 수요 예측_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={checkData}>
                            데이터 확인
                        </Card>
                        <Card btn="Step 2" img={checkModel}>
                            모델 확인
                        </Card>
                        <Card btn="Step 3" img={checkResult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/prediction/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
