/**
 * @filePath        : src\components\pages\supervised\emotion\emotion1\EmotionData.js
 * @fileName        : EmotionData.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 데이터 관리 함수 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */

export function setData2(data) {
    sessionStorage.setItem("emotiondata", JSON.stringify(data));
}
export function getData2() {
    return JSON.parse(sessionStorage.getItem("emotiondata"));
}

export function setTrueEmotion(index, data) {
    let true_emotion = JSON.parse(sessionStorage.getItem("true_emotion"));
    if (true_emotion == null) {
        true_emotion = [];
    }
    true_emotion[index] = data;
    sessionStorage.setItem("true_emotion", JSON.stringify(true_emotion));
}
export function getTrueEmotion() {
    return JSON.parse(sessionStorage.getItem("true_emotion"));
}

export function setNewTrueEmotion(index, data) {
    let new_true_emotion = JSON.parse(sessionStorage.getItem("new_true_emotion"));
    if (new_true_emotion == null) {
        new_true_emotion = [];
    }
    new_true_emotion[index] = data;
    sessionStorage.setItem("new_true_emotion", JSON.stringify(new_true_emotion));
}
export function getNewTrueEmotion() {
    return JSON.parse(sessionStorage.getItem("new_true_emotion"));
}

export function getNewTrueEmotionIndex(index) {
    let new_true_emotion = JSON.parse(sessionStorage.getItem("new_true_emotion"));
    if (new_true_emotion == null) {
        new_true_emotion = [];
    }
    return new_true_emotion[index];
}

export function setText(index, data) {
    let text = JSON.parse(sessionStorage.getItem("text"));
    if (text == null) {
        text = [];
    }
    text[index] = data;
    sessionStorage.setItem("text", JSON.stringify(text));
}
export function getText() {
    return JSON.parse(sessionStorage.getItem("text"));
}

export function setAnsText(index, data) {
    let ans_text = JSON.parse(sessionStorage.getItem("ans_text"));
    if (ans_text == null) {
        ans_text = [];
    }
    ans_text[index] = data;
    sessionStorage.setItem("ans_text", JSON.stringify(ans_text));
}
export function getAnsText() {
    return JSON.parse(sessionStorage.getItem("ans_text"));
}

export function setDataBool(data) {
    sessionStorage.setItem("dataBool", data ? "T" : "F");
}
export function getDataBool() {
    return sessionStorage.getItem("dataBool") === "T" ? true : false;
}

export function setModelindex(data) {
    sessionStorage.setItem("modelindex", data);
}
export function getModelindex() {
    const modelindex = sessionStorage.getItem("modelindex") * 1;
    if (modelindex === 0) {
        return "A600";
    } else if (modelindex === 1) {
        return "A601";
    } else {
        return "A602";
    }
}

export function getMODELID() {
    var score = 0;

    const true_emotion = JSON.parse(sessionStorage.getItem("true_emotion"));
    const new_true_emotion = JSON.parse(sessionStorage.getItem("new_true_emotion"));

    for (var i = 0; i < true_emotion.length; i++) {
        if (new_true_emotion[i] === true_emotion[i]) {
            score += 1;
        }
    }
    if (score > 12) {
        return "M102";
    } else if (score > 6) {
        return "M101";
    } else {
        return "M100";
    }
}
