/**
 * @filePath        : src\components\pages\supervised\genderage\genderage4\Classification.js
 * @fileName        : Classification.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 결과 데이터 그리드 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Imagecard from "components/pages/supervised/genderage/genderage4/Imagecard";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";
import { getALGID } from "components/pages/supervised/genderage/genderage1/GenderageData";
export default function Classification() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const params = { ALG_ID: getALGID(), MODEL_ID: "M500" };

        axios
            .get(`${URL}/api/supervised/imagemodel/result`, { params })
            .then((result) => {
                setData(result.data.list);
            })
            .catch((error) => {
                console.error('error:', error);
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            {loading && <ProgressBar />}

            <div className="genderage4-imagecard-grid">
                {data.map((item, index) => (
                    <Imagecard
                        key={index}
                        gender={item.PRED_GENDER_NM}
                        age={item.PRED_AGE_NM}
                        src={item.IMG_PATH}
                    />
                ))}
            </div>
        </>
    );
}