/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity3\Table.js
 * @fileName        : Table.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 나와 가장 유사한 유명인 목록 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function Table(props) {
    const { receiveUserData, receiveNameList, receiveNameListCount } = props;

    return (
        <>
            <div className="celebrity3-table">
                <h3><b>•</b>나와 가장 유사한 유명인</h3>
                <div className="celebrity2-table-space">
                    <div className="celebrity2-table-container">
                        <table className="celebrity2-table">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }}><b style={{ color: "orange", fontSize: "0.8rem", margin: "0% 4%" }}>•</b>군집 {receiveUserData.LABEL}</td>
                                    <td><h3 style={{ margin: "0", textAlign: "right" }}>{receiveNameListCount.toLocaleString()} 명</h3></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div>
                        <table>
                            <tbody>
                                <tr style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px", padding: "2% 3%" }}>
                                    {receiveNameList.map((item, index) => (
                                        <td key={index}
                                            className={`celebrity3-td ${index % 2 === 0 ? "celebrity3-td-back" : ""}`} >{item.DATASET_ID}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
