/**
 * @filePath        : src\components\pages\supervised\genderage\genderage1\Classification.js
 * @fileName        : Classification.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 데이터 그리드 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Imagecard from "components/pages/supervised/genderage/genderage1/Imagecard";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function Classification() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태값

    useEffect(() => {
        axios
            .get(`${URL}/api/supervised/imagemodel/data`)
            .then((result) => {
                setData(result.data.list);
            })
            .catch((error) => {
                console.error('error:', error);
            })
            .finally(() => {
                setLoading(false); 
            });
    }, []);

    return (
        <>
            {loading && <ProgressBar />}

            <div className="genderage1-imagecard-grid">
                {data.map((item, index) => (
                    <Imagecard
                        key={index}
                        src={item.IMG_PATH}
                        new_gender={item.NEW_TRUE_GENDER}
                        true_gender={item.TRUE_GENDER}
                        new_age={item.NEW_TRUE_AGE}
                        true_age={item.TRUE_AGE}
                        index={index}
                    />
                ))}
            </div>
        </>
    );
}