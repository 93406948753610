/**
 * @filePath        : src\components\pages\supervised\weather\weather4\Cal2.js
 * @fileName        : Cal2.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 예측 전력량 데이터 달력
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import styled from "styled-components";
import Num from "components/pages/supervised/weather/weather4/Num";
import green from "assets/supervised/weather/green.png";
import yellow from "assets/supervised/weather/yellow.png";
import red from "assets/supervised/weather/red.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "Config.js";
import { getRealdata, getPredictiondata } from "components/pages/supervised/weather/weather4/Energydata";

const Day = styled.div`
    padding: 5px;
    background-color: #eeeeee;
    color: black;
    height: 2rem;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Cal2({ handleNewSignal }) {
    const [imageToggles, setImageToggles] = useState([0, 0, 0]);
    // const [dataGap, setDataGap] = useState([0, 0, 0]);
    const [weather, setWeather] = useState([]);
    const [isCorrectSignal, setIsCorrectSignal] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const realdata = getRealdata();
    const predictiondata = getPredictiondata();

    const date = [
        "8/1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
    ];

    const handleClick = (index) => {
        const updatedToggles = [...imageToggles];
        updatedToggles[index] = (updatedToggles[index] + 1) % 3;
        setImageToggles(updatedToggles);

        const newGap = [
            Math.abs(realdata[0] - predictiondata[0]).toFixed(1) < 2 ? 0 : Math.abs(realdata[0] - predictiondata[0]).toFixed(1) >= 4 ? 2 : 1,
            Math.abs(realdata[1] - predictiondata[1]).toFixed(1) < 2 ? 0 : Math.abs(realdata[1] - predictiondata[1]).toFixed(1) >= 4 ? 2 : 1,
            Math.abs(realdata[2] - predictiondata[2]).toFixed(1) < 2 ? 0 : Math.abs(realdata[2] - predictiondata[2]).toFixed(1) >= 4 ? 2 : 1,
        ];
        // setDataGap(newGap);

        if (newGap[0] === updatedToggles[0] && newGap[1] === updatedToggles[1] && newGap[2] === updatedToggles[2]) {
            handleNewSignal(true);
            setIsCorrectSignal(true);
        } else {
            handleNewSignal(false);
            setIsCorrectSignal(false);
        }
    };

    useEffect(() => {
        axios
            .get(`${URL}/api/supervised/weather/elementdata`)
            .then((result) => {
                setWeather(result.data.weather);
                setShowResult(true);
            })
            .catch((error) => {
                console.log("error: " + error);
            });
    }, []);

    useEffect(() => {
        let intervalId;

        if (isCorrectSignal && showResult) {
            intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, 100);
        }

        return () => {
            // cleanup 함수로 인터벌을 제거하여 메모리 누수를 방지
            clearInterval(intervalId);
        };
    }, [isCorrectSignal, showResult]);

    return (
        <div className="calender2">
            <Day>
                <p style={{ color: "red" }}>일</p>
            </Day>
            <Day>월</Day>
            <Day>화</Day>
            <Day>수</Day>
            <Day>목</Day>
            <Day>금</Day>
            <Day>
                <p style={{ color: "blue" }}>토</p>
            </Day>

            <div className="cal-blank" />

            {date.map((date, index) => (
                <Num key={date} weather={weather[index]} data={predictiondata[index] !== undefined ? predictiondata[index] : ""}>
                    <p style={{ margin: "0" }}>{date}</p>

                    {(index === 0 || index === 1 || index === 2) && (
                        <img
                            src={imageToggles[index] === 0 ? green : imageToggles[index] === 1 ? yellow : red}
                            alt="OX"
                            className="num-ox"
                            onClick={() => handleClick(index)}
                        />
                    )}
                    {isCorrectSignal && (index === currentIndex || index < currentIndex) && (
                        <img
                            src={
                                Math.abs(realdata[index] - predictiondata[index]).toFixed(1) < 2
                                    ? green
                                    : Math.abs(realdata[index] - predictiondata[index]).toFixed(1) >= 4
                                    ? red
                                    : yellow
                            }
                            alt="OX"
                            className="num-ox"
                        />
                    )}
                </Num>
            ))}
            <div className="cal-blank" />
            <div className="cal-blank" />
            <div className="cal-blank" />
        </div>
    );
}
