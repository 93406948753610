/**
 * @filePath        : src\components\sections\buttons\GreyBtn.js
 * @fileName        : GreyBtn.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 이전 단계 버튼 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Link } from 'react-router-dom';

export default function GreyBtn({ to , onClick }) {
    return (
        <Link className="greybtn" to={to} onClick={onClick}>
            이전 단계로
        </Link>
    );
}