/**
 * @filePath        : src\components\pages\supervised\object\labeling\Labeling.js
 * @fileName        : Labeling.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 라벨링 틀 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import { useState, useContext } from 'react';
import { LabelingContext } from "components/pages/supervised/object/object1/Main";

export default function Labeling({ img1, img2, img3, step, onStepChange, obj }) {
    const [selectedImage, setSelectedImage] = useState("null");
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const { setPerson, setBag, setBook, setComputer, setState } = useContext(LabelingContext);


    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleBeforeClick = () => {
        if (step === 2 || step === 3) {
            onStepChange(step - 1);
        }
        else {
            setState(0);
        }
    }

    const handleNextClick = () => {
        //이미지 url에 answer 를 포함할 경우 다음단계 
        if ((selectedImage.includes("answer")) && (step === 1 || step === 2)) {
            onStepChange(step + 1);
        }
        //라벨링 완료한 객체에 대해 상태값 true로 세팅
        else if ((selectedImage.includes("answer")) && step === 3) {
            if (obj === "person") { setPerson(true); }
            else if (obj === "bag") { setBag(true); }
            else if (obj === "book") { setBook(true); }
            else if (obj === "computer") { setComputer(true); }

            setState(0);
        }
        else if (selectedImage.includes("null")) {
            setModalMessage("사진을 선택해주세요 !");
            setShowModal(true);
        }
        else {
            setModalMessage("다시 선택해보세요 !");
            setShowModal(true);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className="white-back">
                {(obj === "person") && <p>“<b style={{ color: "#503CB2" }}>사람</b>”을 알맞게 라벨링한 사진을 선택하세요.
                    <br />※ 사진에서 원래의 색과 다른 색으로 색칠된 것은 대상을 라벨링한 것입니다. </p>}
                {(obj === "bag") && <p>“<b style={{ color: "#503CB2" }}>가방</b>”을 알맞게 라벨링한 사진을 선택하세요.
                    <br />※ 사진에서 원래의 색과 다른 색으로 색칠된 것은 대상을 라벨링한 것입니다. </p>}
                {(obj === "book") && <p>“<b style={{ color: "#503CB2" }}>책</b>”을 알맞게 라벨링한 사진을 선택하세요.
                    <br />※ 사진에서 원래의 색과 다른 색으로 색칠된 것은 대상을 라벨링한 것입니다. </p>}
                {(obj === "computer") && <p>“<b style={{ color: "#503CB2" }}>노트북</b>”을 알맞게 라벨링한 사진을 선택하세요.
                    <br />※ 사진에서 원래의 색과 다른 색으로 색칠된 것은 대상을 라벨링한 것입니다. </p>}

                <div className="label-space">
                    <div
                        className={`img1 ${selectedImage === img1 ? 'labeling-selected' : ''}`}
                        onClick={() => handleImageClick(img1)}
                    >
                        <img src={img1} alt="img1" />
                    </div>
                    <div
                        className={`img2 ${selectedImage === img2 ? 'labeling-selected' : ''}`}
                        onClick={() => handleImageClick(img2)}
                    >
                        <img src={img2} alt="img2" />
                    </div>
                    <div
                        className={`img3 ${selectedImage === img3 ? 'labeling-selected' : ''}`}
                        onClick={() => handleImageClick(img3)}
                    >
                        <img src={img3} alt="img3" />
                    </div>
                </div>

            </div>

            <div className="main-btn">
                <GreyBtn onClick={handleBeforeClick} />

                {(step === 1 || step === 2) && <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>}
                {(step === 3) && <BlueBtn onClick={handleNextClick}>라벨링 완료</BlueBtn>}
            </div>
            {showModal && (
                <div className="modal-back">
                    <div className="modal">
                        <h3>{modalMessage}</h3>
                        <button onClick={closeModal}><b>닫기</b></button>
                    </div>
                </div>
            )}
        </div>
    );
}