/**
 * @filePath        : src\components\pages\reinforcement\flappy\flappy1\Playgame.js
 * @fileName        : Playgame.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : Flappy Bird 모델의 게임 플레이 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useRef, useEffect } from 'react';

export default function Playgame() {
    const iframeRef = useRef(null);
    useEffect(() => {
        // 컴포넌트가 마운트될 때 iframe에 포커스를 설정
        if (iframeRef.current) {
            iframeRef.current.focus();
        }
    }, []);
    return (
        <>
                <div>
                    <iframe
                        src="/flappy.html"
                        title="game"
                        ref={iframeRef}
                        style={{ width: "30rem", height: "45rem", border: "none" }}
                    />
                </div>
        </>
    );
}