/**
 * @filePath        : src\components\pages\supervised\weather\weather4\Num.js
 * @fileName        : Num.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 날짜 개별 컴포넌트 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
export default function Num({ children, weather, data, datacolor }) {

    const textColor = datacolor === "g" ? "#aaaaaa" : "#00aeef";
    return (
        <div className="num2">
            <div className="num-ele" style={{height:"1.7rem"}}>
                <b>{children}</b>
                {weather && <img src={require(`assets/supervised/weather/${weather}.png`)} alt="weather" />}
            </div>

            <div className="num-span2" style={{height:"1rem"}}>
                <b style={{color:textColor}}>{data}</b>
                <div></div>
            </div>

        </div>
    );
}