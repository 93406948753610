/**
 * @filePath        : src\components\pages\supervised\weather\weather1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 변수 선택 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Element from "components/pages/supervised/weather/weather1/Element";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import { useState } from 'react';
import { getSelectedelementnames } from "components/pages/supervised/weather/weather1/WeatherData";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');


    const handleNextClick = () => {
        if (getSelectedelementnames().length===0) {
            setModalMessage("기상 요소를 최소 1개 이상 선택해주세요 !");
            setShowModal(true);
        }
        else{
            window.location.href=("/weather/2");
        }
    };

    // useEffect(() => {
    //     if (selectedCheckboxNames === "" || selectedCheckboxNames === null) {
    //         setStep(1);
    //     }
    //     else {
    //         setStep(2);
    //     }
    // }, [selectedCheckboxNames]);


    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="지도학습">날씨에 따른 전기 사용량 예상 (회귀 모델)</Header>
            <HeaderNav to1="/weatherconcept" to2="/weather" />

            <main>
                <div className="main-step-img">
                    <Step step1="변수 선택" state1={0} step2="모델 이해" state2={2} step3="모델 학습" state3={2} step4="모델 평가" state4={2}>
                        평균기온, 최저기온, 최고기온, 강수량, 습도, 요일, 일조 시간, 일출 시간 등은 전기 사용량 예상에 사용되는 날씨 변수들입니다. <br />
                        이 변수들은 전기 사용량을 예상하는데 영향을 많이 미치는 변수도 있고, 조금 영향을 미치는 변수들도 있습니다. <br />
                        회귀 모델에서는 날씨 변수의 선택에 따라 전기 사용량 예상의 정확도가 많이 달라집니다. <br />
                        그러므로, 전기 사용량에 영향을 많이 미치는 변수를 선택하도록 합니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="grey-back">
                        <p>ㅤㅤ• 회귀 모델에 사용하고자 하는 날씨 변수에 체크해주세요.(1개 이상 선택)</p>
                    </div>
                    <Element />
                </div>

                <div className="main-btn">
                    <GreyBtn to="/weather"></GreyBtn>

                    {/* {step === 1 && ( */}
                        <BlueBtn onClick={handleNextClick}>변수 선택 완료</BlueBtn>
                    {/*  )}
                     {step === 2 && (
                         <BlueBtn to="/weather/2">변수 선택 완료</BlueBtn>
                     )} */}

                </div>
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}