/**
 * @filePath        : src\components\pages\supervised\object\objectintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import objectlabeling from "assets/supervised/object/objectlabeling.png";
import understandmodel from "assets/supervised/object/understandmodel.png";
import experiencemodel from "assets/supervised/object/experiencemodel.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">사진에서 서로 다른 대상 찾아내기 (객체 검출)</Header>
            <HeaderNav to1="/objectconcept" to2="/object" />
            <Conceptcheck>사진에서 사람, 가방, 책과 노트북을 검출해내는 모델을 만들어보자</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            객체 검출은 이미지나 동영상에서 사람, 동물, 차량 등 의미있는 객체의 종류와 그 위치를 정확하게 찾기 위한 컴퓨터 비전 기술입니다. 앞에
                            개념 학습에서 소개된 것과 같이 사람 및 물체 인식 기술은 생활 곳곳에서 사용되고 있습니다.
                        </p>
                        <p>
                            본 프로그램에서는 사람, 가방, 책, 노트북을 검출해내는 객체검출 모델을 만들어봅니다. 모델에 넣을 사람, 가방, 책 및 노트북 이미지에
                            대한 라벨링을 하고, 학습시키는 과정을 체험합니다. 이 모델을 활용하여 사진에서 서로 다른 대상을 찾는 체험을 할 수 있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/2차/02_사진에서 서로 다른 대상 찾아내기_사용자 안내서_v1.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={objectlabeling}>
                            객체 라벨링
                        </Card>
                        <Card btn="Step 2" img={understandmodel}>
                            모델 이해
                        </Card>
                        <Card btn="Step 3" img={experiencemodel}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/object/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
