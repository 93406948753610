/**
 * @filePath        : src\components\pages\supervised\weather\weather4\Energydata.js
 * @fileName        : Energydata.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 데이터 관리 함수 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */

export function setRealdata(data) {
    const realdata = data.map((item, index) => {
        return item.toFixed(1);
    });
    sessionStorage.setItem("realdata", JSON.stringify(realdata));
}
export function getRealdata() {
    return JSON.parse(sessionStorage.getItem("realdata"));
}

export function setPredictiondata(data) {
    const predictiondata = data.map((item, index) => {
        return item.toFixed(1);
    });
    sessionStorage.setItem("predictiondata", JSON.stringify(predictiondata));
}
export function getPredictiondata() {
    return JSON.parse(sessionStorage.getItem("predictiondata"));
}

export function setScore(data) {
    sessionStorage.setItem("score", data);
}
export function getScore() {
    return sessionStorage.getItem("score") * 1;
}
