/**
 * @filePath        : src\components\pages\supervised\emotion\emotion1\Grid.js
 * @fileName        : Grid.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 리뷰데이터 확인 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";
import GridItem from "components/pages/supervised/emotion/emotion1/GridItem";
import { getData2, setData2, getDataBool, setDataBool } from "components/pages/supervised/emotion/emotion1/EmotionData";

export default function Grid() {
    const [loading, setLoading] = useState(true); // 로딩 상태값
    const [data, setData] = useState([]);

    useEffect(() => {
        //이전 데이터가 있을 시, 이전 데이터로 세팅하고 그렇지 않으면 서버에서 받아오기
        if (getDataBool()===false) {
            axios
                .get(`${URL}/api/supervised/textmodel/data`)
                .then((result) => {
                    setData(result.data.list);
                    setData2(result.data.list);
                })
                .catch((error) => {
                    console.error('error:', error);
                })
                .finally(() => {
                    setLoading(false);
                    setDataBool(true);
                });
        }
        else {
            setData(getData2());
            setLoading(false);
        }
    }, []);

    return (
        <>
            {loading && <ProgressBar />}

            <div style={{ display: "flex", justifyContent: "space-between", padding: "0% 5%" }}>
                <p>리뷰데이터 확인/입력</p>
                <p>전체 : <b style={{ color: "orange" }}>20</b> 건</p>
            </div>

            <div className="emotion1-grid-container">
                <div className="emotion1-grid" style={{ textAlign: "left" }}>
                    <p style={{ paddingLeft: "2%" }}>Document</p>
                    <p style={{ borderLeft: "solid 3px #ddd", padding: "0% 10%" }}>Label</p>
                </div>


                <div className="emotion1-grid-selectspace">
                    {data && (<>
                        {data.map((item, index) => (
                            <GridItem
                                key={index}
                                text={item.DOCUMENT}
                                index={index}
                                true_emotion={item.TRUE_LABEL}
                                ans_text={item.TOKENIZE} />
                        ))}
                    </>)}
                </div>
            </div>
        </>
    );
}

