/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatchintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 단어 맞추기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import Card from "components/sections/cards/Card";
import selectWord from "assets/supervised/wordmatch/selectWord.png";
import matchWord from "assets/supervised/wordmatch/matchWord.png";
import checkResult from "assets/supervised/wordmatch/checkResult.png";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">단어 맞추기 (의사결정트리 모델)</Header>
            <HeaderNav to1="/wordmatchconcept" to2="/wordmatch" />
            <Conceptcheck>사용자가 직접 단어를 선택하고 의사결정트리 모델을 활용해 선택한 단어를 찾아 봅시다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            의사결정트리 모델은 데이터를 분석해서 데이터 사이에 존재하는 패턴을 예측 가능한 규칙들의 조합으로 나타내는 방법입니다.
                            <br />본 프로그램을 활용하여 단어를 찾아가는 활동을 통해 의사결정트리 모델을 체험할 수 있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/09_단어 맞추기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={selectWord}>
                            단어 선택
                        </Card>
                        <Card btn="Step 2" img={matchWord}>
                            단어 맞추기
                        </Card>
                        <Card btn="Step 3" img={checkResult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/wordmatch/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
