/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece3\TableInput.js
 * @fileName        : TableInput.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 파라미더 데이터 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/ProgressBar";
import React from "react";

export default function TableInput() {
    const [modelArgParam, setModelArgParam] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const params = { ALG_ID: "A200", SLEEPTIME: 0 };
        axios
            .get(`${URL}/api/model/algresult`, { params })
            .then((result) => {
                setModelArgParam(JSON.parse(result.data.detailinfo.MODEL_ARG_PARAM));
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            {loading && <ProgressBar />}
            <div className="masterpiece3-tableinput">
                <h3><b style={{color:"#FED800"}}>• </b>파라미터</h3>
                <div className="masterpiece3-table-space">
                    <div className="masterpiece3-table-container">
                        <table className="masterpiece3-table">
                            <tbody>
                                {modelArgParam && modelArgParam.length > 0 && (<>
                                    {modelArgParam.map((item, index) => (
                                        <tr key={index}>
                                            {Object.entries(item).map(([key, value]) => (
                                                <React.Fragment key={key}>
                                                    <td style={{color:"orange"}}>•</td>
                                                    <td>{key}<b style={{color:"red", marginLeft:"5px"}}>*</b></td>
                                                    <td>
                                                        <input type="text" value={value} disabled />
                                                    </td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    ))}
                                </>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
