/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons4\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 체험하기 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Result from "components/pages/unsupervised/seasons/seasons4/Result";
import Step from "components/sections/boxs/Step";
import { useState } from "react";

export default function Main() {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [state, setState]=useState(false);

    const handleNextClick = () => {
        if (state) {
            window.location.href=("/");
        }
        else{
            setModalMessage("결과 확인 후 체험을 종료해주세요.");
            setShowModal(true);
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const handleChange = (newValue) => {
        setState(newValue);
    };
    return (
        <>
            <Header learning="비지도학습">사계절 분류하기 (군집화 모델)</Header>
            <HeaderNav to1="/seasonsconcept" to2="/seasons" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 이해" state2={1} step3="결과 확인" state3={1} step4="평가하기" state4={0}>
                        앞에서 만든 클러스터링 모델을 이용하여 다른 나라의 계절을 예측해 보고자 합니다.<br />
                        확인해보고자 하는 나라와 날짜를 선택하여 해당 클러스터링 결과를 확인해보세요.
                    </Step>
                </div>

                <div className="white-back">
                    <Result onChange={handleChange}/>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/seasons/3"></GreyBtn>
                    <BlueBtn onClick={handleNextClick}>체험 종료</BlueBtn>
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}