/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons4\Result.js
 * @fileName        : Result.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 결과 체험 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import map from "assets/unsupervised/seasons/map.png";
import Country from "./Country";
import Date from "./Date";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";
import usa from "assets/unsupervised/seasons/미국.png";
import uk from "assets/unsupervised/seasons/영국.png";
import it from "assets/unsupervised/seasons/이탈리아.png";
import jp from "assets/unsupervised/seasons/일본.png";
import au from "assets/unsupervised/seasons/호주.png";

export default function Result({onChange}) {
    const [countrytext, setCountrytext] = useState("------");
    const [countrytext2, setCountrytext2] = useState("");
    const [selecteddate, setSelectedDate] = useState("2022-01-01");
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const countryClick = (name) => {
        setData(null);
        if (name === "usa") { setCountrytext("미국"); setCountrytext2("usa"); }
        else if (name === "uk") { setCountrytext("영국"); setCountrytext2("uk"); }
        else if (name === "it") { setCountrytext("이탈리아"); setCountrytext2("it"); }
        else if (name === "jp") { setCountrytext("일본"); setCountrytext2("jp"); }
        else if (name === "au") { setCountrytext("호주"); setCountrytext2("au"); }
    };

    const handleDateChange = (formattedDate) => {
        setData(null);
        setSelectedDate(formattedDate);
    };

    const resultClick = () => {
        if (countrytext === "------") {
            setModalMessage("나라를 선택해주세요 !");
            setShowModal(true);
        }
        else {
            setLoading(true);
            const requestData = {
                Date: selecteddate,
                Country: countrytext2
            };


            axios
                .post(`${URL}/api/unsupervised/seasons/weatherdata2`, requestData)
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((error) => {
                    console.log('error: ' + error);
                })
                .finally(() => {
                    setLoading(false); 
                    onChange(true);
                });
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <>
            {loading && <ProgressBar />}
            <div className="seasons4-main">
                <div className="seasons4-main-map">
                    <img src={map} alt="map" />
                    <div onClick={() => countryClick("usa")}>
                        <Country img={usa} name="미국" idname="usa" sub="(LA)"
                            classname={`${countrytext === '미국' ? 'selected' : ''}`} />
                    </div>
                    <div onClick={() => countryClick("uk")}>
                        <Country img={uk} name="영국" idname="uk" sub="(런던)"
                            classname={`${countrytext === '영국' ? 'selected' : ''}`} />
                    </div>
                    <div onClick={() => countryClick("it")}>
                        <Country img={it} name="이탈리아" idname="it" sub="(로마)"
                            classname={`${countrytext === '이탈리아' ? 'selected' : ''}`} />
                    </div>
                    <div onClick={() => countryClick("jp")}>
                        <Country img={jp} name="일본" idname="jp" sub="(도쿄)"
                            classname={`${countrytext === '일본' ? 'selected' : ''}`} />
                    </div>
                    <div onClick={() => countryClick("au")}>
                        <Country img={au} name="호주" idname="au" sub="(캔버라)"
                            classname={`${countrytext === '호주' ? 'selected' : ''}`} />
                    </div>
                </div>
                <div className="seasons4-main-right">
                    <div className="seasons4-main-right-first">
                        <div style={{ marginBottom: "1rem" }}>
                            <b style={{ marginRight: "2rem" }}>국가</b>
                            <b>{countrytext}</b>
                        </div>
                        <div>
                            <b style={{ marginRight: "2rem" }}>날짜</b>
                            <Date onDateChange={handleDateChange} />
                        </div>
                    </div>

                    <div className="seasons4-main-right-second">
                        <button onClick={() => resultClick()}>결과 확인</button>
                    </div>

                    {data && (
                        <div className="seasons4-main-right-third">
                            <h3>{countrytext}의 해당 일자 날씨 데이터</h3>
                            <div><p style={{ width: "3rem" }}>온도</p><b>{data["Temperature (°C)"]} °C</b></div>
                            <div><p style={{ width: "3rem" }}>강수량</p><b>{data["Precipitation (in)"]} mm</b></div>
                            <div><p style={{ width: "3rem" }}>습도</p><b>{data["Humidity (%)"]} %</b></div>
                            <div style={{ color: "#503cb2", marginTop:"10px", fontSize:"1.1rem" }}><b>한국 기준으로</b><b>{data.ClusterName}</b> <b>날씨 입니다.</b></div>
                        </div>
                    )}
                </div>
            </div>

            {showModal && (
                <div className="modal-back">
                    <div className="modal">
                        <h3>{modalMessage}</h3>
                        <button onClick={closeModal}><b>닫기</b></button>
                    </div>
                </div>
            )}
        </>
    );
}