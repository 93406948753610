/**
 * @filePath        : src\components\Navigation.js
 * @fileName        : Navigation.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 상단 네비게이션 바
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Link } from "react-router-dom";
import { useState } from "react";
import {  setSelectedTabData } from "components/pages/home/SelectedTabData";
import DropdownMenu from "components/DropdownMenu";

export default function Navigation() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(true);
  };

  const OnMouseLeave = () => {
    setDropdownOpen(false);
  }


  return (
    <div className="navbar-menu">
      <Link to="/" onClick={() => setSelectedTabData(0)}>
        <img
          src={require('assets/logo.png')}
          alt="Logo"
          className="nav-image"
        ></img>
      </Link>


    <div className="nav-item">

    <Link to="/introduce"> <p className="hover-effect">EDU AI 소개</p></Link>
    </div>


      {/* <img src={require('assets/point2.png')} alt="Logo"></img> */}

      <div className="nav-item dropdown" onMouseEnter={toggleDropdown} onMouseLeave={OnMouseLeave}>

        <Link to="/" onClick={() => setSelectedTabData(0)}><p className="hover-effect">AI 체험 프로그램</p></Link>

        <div className={`dropdown-content ${isDropdownOpen ? "open" : ""}`}>
          {isDropdownOpen && (<DropdownMenu />)}
        </div>


      </div>
    </div>
  );
}
