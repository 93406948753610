/**
 * @filePath        : src\components\pages\supervised\emotion\emotion3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 모델 평가 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import emotionEstimation from "assets/supervised/emotion/emotionEstimation01.png";
import Table from "./Table";
import { getModelindex, getMODELID} from "components/pages/supervised/emotion/emotion1/EmotionData";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">텍스트 감정 분석하기 (텍스트 분류모델)</Header>
            <HeaderNav to1="/emotionconcept" to2="/emotion" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 입력" state1={1} step2="텍스트 감정 분석" state2={1} step3="모델 평가" state3={0} step4="결과 확인" state4={2}>
                        텍스트 감정분석의 경우 문장의 긍정/부정을 예측하는 것이므로 이진분류에 해당합니다.<br />
                        이진분류 모델의 성능을 평가하는 지표에는 정확도, 정밀도, 재현율 등이 있습니다.
                        학습한 모델의 평가지표를 보고 모델이 적절히 학습되었는지 평가해 봅시다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="coldpatient3-main">

                        <div className="coldpatient3-main-left">
                            <img src={emotionEstimation} alt="chart" />
                        </div>
                        <div className="coldpatient3-main-right">
                            <img src={require(`assets/supervised/emotion/${getModelindex()}_ ${getMODELID()}.png`)} alt="chart" />
                            <Table />
                        </div>
                    </div>
                </div>


                <div className="main-btn">
                    <GreyBtn to="/emotion/2"></GreyBtn>
                    <BlueBtn to="/emotion/4">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}

