/**
 * @filePath        : src\components\pages\home\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 메인 홈 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */

import TabBtn from "components/pages/home/TabBtn";
import ModelBtn from "components/pages/home/ModelBtn";
import Heading from "components/pages/home/Heading";

import weather from "assets/home/메인썸네일_날씨.png";
import object from "assets/home/메인썸네일_다른대상.png";
import coldpatient from "assets/home/메인썸네일_감기.png";
import genderage from "assets/home/genderage.jpg";
import emotion from "assets/home/emotion.jpg";

import seasons from "assets/home/seasons.jpg";
import news from "assets/home/메인썸네일_뉴스기사.png";
import celebrity from "assets/home/celebrity.jpg";
import masterpiece from "assets/home/masterpiece.jpg";
import writing from "assets/home/writing.jpg";

import cartpole from "assets/home/메인썸네일_막대중심.png";
import arctic from "assets/home/메인썸네일_북극.png";
import selfdriving from "assets/home/메인썸네일_자율주행.png";
import flappy from "assets/home/flappy.jpg";

import wordmatch from "assets/home/wordmatch.jpg";
import prediction from "assets/home/prediction.jpg";
import {
    getSelectedTabData,
    setSelectedTabData,
} from "components/pages/home/SelectedTabData";
import { useState, useEffect } from "react";

export default function Main() {
    const [selectedTab, setSelectedTab] = useState(0);
    const selecteddropdown = getSelectedTabData();

    const handleTabClick = (index) => {
        setSelectedTab(index);
        setSelectedTabData(index);
    };

    useEffect(() => {
        setSelectedTab(getSelectedTabData());
    }, [selecteddropdown]);
    return (
        <>
            <Heading />
            <div className="home-main">
                <div className="tab">
                    <div className="tab-bar-container">
                        <TabBtn
                            isActive={selectedTab === 0}
                            onClick={() => handleTabClick(0)}
                        >
                            전체
                        </TabBtn>
                        <TabBtn
                            isActive={selectedTab === 1}
                            onClick={() => handleTabClick(1)}
                        >
                            지도학습
                        </TabBtn>
                        <TabBtn
                            isActive={selectedTab === 2}
                            onClick={() => handleTabClick(2)}
                        >
                            비지도학습
                        </TabBtn>
                        <TabBtn
                            isActive={selectedTab === 3}
                            onClick={() => handleTabClick(3)}
                        >
                            강화학습
                        </TabBtn>
                    </div>
                    <div className="button-container">
                        {selectedTab === 0 && (
                            <>
                                <ModelBtn
                                    to="/weatherconcept"
                                    img={weather}
                                    learn={"지도학습"}
                                    model={"회귀 모델"}
                                >
                                    날씨에 따른 전기 사용량 예상
                                </ModelBtn>
                                <ModelBtn
                                    to="/objectconcept"
                                    img={object}
                                    learn={"지도학습"}
                                    model={"객체검출 모델"}
                                >
                                    사진에서 서로 다른 대상 찾아내기
                                </ModelBtn>
                                <ModelBtn
                                    to="/coldpatientconcept"
                                    img={coldpatient}
                                    learn={"지도학습"}
                                    model={"분류 모델"}
                                >
                                    감기환자 분류하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/genderageconcept"
                                    img={genderage}
                                    learn={"지도학습"}
                                    model={"이미지분류 모델"}
                                >
                                    성별 나이 분류하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/emotionconcept"
                                    img={emotion}
                                    learn={"지도학습"}
                                    model={"텍스트분류 모델"}
                                >
                                    텍스트 감정 분석하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/wordmatchconcept"
                                    img={wordmatch}
                                    learn={"지도학습"}
                                    model={"의사결정트리 모델"}
                                >
                                    단어 맞추기
                                </ModelBtn>
                                <ModelBtn
                                    to="/predictionconcept"
                                    img={prediction}
                                    learn={"지도학습"}
                                    model={"딥러닝 모델"}
                                >
                                    공유자전거 수요 예측
                                </ModelBtn>
                                <ModelBtn
                                    to="/seasonsconcept"
                                    img={seasons}
                                    learn={"비지도학습"}
                                    model={"군집화 모델"}
                                >
                                    사계절 분류하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/newsconcept"
                                    img={news}
                                    learn={"비지도학습"}
                                    model={"사전학습 모델"}
                                >
                                    AI를 이용한 여러 종류의 글쓰기
                                </ModelBtn>
                                <ModelBtn
                                    to="/celebrityconcept"
                                    img={celebrity}
                                    learn={"비지도학습"}
                                    model={"군집화 모델"}
                                >
                                    나와 유사한 유명인 찾기
                                </ModelBtn>
                                <ModelBtn
                                    to="/masterpiececoncept"
                                    img={masterpiece}
                                    learn={"비지도학습"}
                                    model={"GAN 모델"}
                                >
                                    사진 명화풍 만들기
                                </ModelBtn>
                                <ModelBtn
                                    to="/writingconcept"
                                    img={writing}
                                    learn={"비지도학습"}
                                    model={"언어 모델"}
                                >
                                    키워드로 작문하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/cartpoleconcept"
                                    img={cartpole}
                                    learn={"강화학습"}
                                >
                                    막대 중심 잡기
                                </ModelBtn>
                                <ModelBtn
                                    to="/arcticconcept"
                                    img={arctic}
                                    learn={"강화학습"}
                                >
                                    북극에서 살아남기
                                </ModelBtn>
                                <ModelBtn
                                    to="/selfdrivingconcept"
                                    img={selfdriving}
                                    learn={"강화학습"}
                                >
                                    자율 주행 자동차 만들기
                                </ModelBtn>
                                <ModelBtn
                                    to="/flappyconcept"
                                    img={flappy}
                                    learn={"강화학습"}
                                >
                                    Flappy Bird
                                </ModelBtn>
                            </>
                        )}
                        {selectedTab === 1 && (
                            <>
                                <ModelBtn
                                    to="/weatherconcept"
                                    img={weather}
                                    learn={"지도학습"}
                                    model={"회귀 모델"}
                                >
                                    날씨에 따른 전기 사용량 예상
                                </ModelBtn>
                                <ModelBtn
                                    to="/objectconcept"
                                    img={object}
                                    learn={"지도학습"}
                                    model={"객체검출 모델"}
                                >
                                    사진에서 서로 다른 대상 찾아내기
                                </ModelBtn>
                                <ModelBtn
                                    to="/coldpatientconcept"
                                    img={coldpatient}
                                    learn={"지도학습"}
                                    model={"분류 모델"}
                                >
                                    감기환자 분류하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/genderageconcept"
                                    img={genderage}
                                    learn={"지도학습"}
                                    model={"이미지분류 모델"}
                                >
                                    성별 나이 분류하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/emotionconcept"
                                    img={emotion}
                                    learn={"지도학습"}
                                    model={"텍스트분류 모델"}
                                >
                                    텍스트 감정 분석하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/wordmatchconcept"
                                    img={wordmatch}
                                    learn={"지도학습"}
                                    model={"의사결정트리 모델"}
                                >
                                    단어 맞추기
                                </ModelBtn>
                                <ModelBtn
                                    to="/predictionconcept"
                                    img={prediction}
                                    learn={"지도학습"}
                                    model={"딥러닝 모델"}
                                >
                                    공유자전거 수요 예측
                                </ModelBtn>
                            </>
                        )}
                        {selectedTab === 2 && (
                            <>
                                <ModelBtn
                                    to="/seasonsconcept"
                                    img={seasons}
                                    learn={"비지도학습"}
                                    model={"군집화 모델"}
                                >
                                    사계절 분류하기
                                </ModelBtn>
                                <ModelBtn
                                    to="/newsconcept"
                                    img={news}
                                    learn={"비지도학습"}
                                    model={"사전학습 모델"}
                                >
                                    AI를 이용한 여러 종류의 글쓰기
                                </ModelBtn>
                                <ModelBtn
                                    to="/celebrityconcept"
                                    img={celebrity}
                                    learn={"비지도학습"}
                                    model={"군집화 모델"}
                                >
                                    나와 유사한 유명인 찾기
                                </ModelBtn>
                                <ModelBtn
                                    to="/masterpiececoncept"
                                    img={masterpiece}
                                    learn={"비지도학습"}
                                    model={"GAN 모델"}
                                >
                                    사진 명화풍 만들기
                                </ModelBtn>
                                <ModelBtn
                                    to="/writingconcept"
                                    img={writing}
                                    learn={"비지도학습"}
                                    model={"언어 모델"}
                                >
                                    키워드로 작문하기
                                </ModelBtn>
                            </>
                        )}
                        {selectedTab === 3 && (
                            <>
                                <ModelBtn
                                    to="/cartpoleconcept"
                                    img={cartpole}
                                    learn={"강화학습"}
                                >
                                    막대 중심 잡기
                                </ModelBtn>
                                <ModelBtn
                                    to="/arcticconcept"
                                    img={arctic}
                                    learn={"강화학습"}
                                >
                                    북극에서 살아남기
                                </ModelBtn>
                                <ModelBtn
                                    to="/selfdrivingconcept"
                                    img={selfdriving}
                                    learn={"강화학습"}
                                >
                                    자율 주행 자동차 만들기
                                </ModelBtn>
                                <ModelBtn
                                    to="/flappyconcept"
                                    img={flappy}
                                    learn={"강화학습"}
                                >
                                    Flappy Bird
                                </ModelBtn>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
