/**
 * @filePath        : src\components\pages\supervised\weather\weather3\GreenB.js
 * @fileName        : GreenB.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 선택 된 기상 요소에 따른 텍스트 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { getSelectedelementnames } from 'components/pages/supervised/weather/weather1/WeatherData';

export default function GreenB() {
    const selectedCheckboxNames = getSelectedelementnames();

    return (
        <div className="green-board">
            <p>앞의 내용을 통해 회귀 분석에 대해 잘 이해했나요?</p>
            <p>전기 사용량 예상을 위한 날씨 요소로<br />
                <b>{selectedCheckboxNames.join(", ")}</b>
                을(를) 선택하였네요. <br />
            </p>
            <p>그럼 이렇게 선택한 변수를 사용하여 회귀 모델을 만들고,<br/>
                이를 이용한 전력량 예측 결과까지 확인해봅시다.
            </p>
        </div>
    );
}