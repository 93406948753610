/**
 * @filePath        : src\components\pages\home\ModelBtn.js
 * @fileName        : ModelBtn.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 메인 홈 화면의 모델 버튼 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { Link } from 'react-router-dom';

export default function ModelBtn({ to, children, img, learn, model }) {
    return (
        <Link className="model-space" to={to}>
            <img src={img} alt="background_img" />

            <div className='model-space-desc'>
                <div className='model-space-desc-class'>
                    {learn}
                </div>
                <p style={{ marginBottom: "0%", marginTop: "5%" }}><b>{children}</b></p>
                {model &&
                    <p style={{ fontSize: "12px", color: "gray", margin: "2% 0% 10% 0%" }}>· {model} </p>}
            </div>
        </Link>
    );
}