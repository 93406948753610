/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons2\ClustersItem.js
 * @fileName        : ClustersItem.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 클러스터 갯수별 분류 결과 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function ClusterItem({ image, title, thismodel, onPrevSlide, onNextSlide }) {
    return (
        <div className="clusteritem-carousel">
            <div className="clusteritem-carousel-caption">
                <div className="clusteritem-carousel-caption-desc">
                    <h2 style={{ margin: "0" }}>{title}</h2>
                    <h2 style={{ margin: "0% 1%" , color:"#503cb2" }}>{thismodel}</h2>
                </div>
            </div>
            <div className="clusteritem-carousel-img">
                <button onClick={onPrevSlide}>&lt;</button>
                <img src={image} alt="img" />
                <button onClick={onNextSlide}>&gt;</button>
            </div>

        </div>
    );
}