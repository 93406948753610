/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델의 게임플레이 단계 메인화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";

import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Playgame from "components/pages/reinforcement/arctic/arctic1/Playgame";
import Step from "components/sections/boxs/Step";
import { useState } from "react";

export default function Main() {
    const [state, setState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleNextClick = () => {
        if (state === false) {
            setModalMessage("게임을 5번 진행한 후, 다음 단계로 넘어갈 수 있습니다 !");
            setShowModal(true);
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const handleChange = (newValue) => {
        setState(newValue);
    };
    return (
        <>
            <Header learning="강화학습">북극에서 살아남기</Header>
            <HeaderNav to1="/arcticconcept" to2="/arctic" />
            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={0} step2="모델 이해" state2={2} step3="결과 확인" state3={2}>
                        {/* 강화 학습의 개념을 이해하기 위해 직접 길찾기 게임을 해봅시다.<br /> */}
                        <p>
                            안녕하세요! 
                            오늘은 강화학습과 펭귄의 놀라운 모험을 함께 해볼 거에요.<br />
                            강화학습은 컴퓨터가 배우며 경험을 통해 스스로 학습하는 특별한 방법이랍니다. <br />
                            그리고 우리는 이 강화학습을 활용하여 귀여운 펭귄이
                            얼음 위에서 어떻게 길을 찾아가는지 알아볼 거에요.
                        </p>
                    </Step>
                </div>
                <div className="white-back">
                    {/* <Desc/> */}
                    <Playgame onChange={handleChange} />
                </div>

                <div className="main-btn">
                    <GreyBtn to="/arctic"></GreyBtn>
                    {state ? (
                        <BlueBtn to="/arctic/2">다음 단계로</BlueBtn>
                    ) : (
                        <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>
                    )}
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}