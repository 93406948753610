/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrityconcept1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 개념학습 영상
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="비지도학습">나와 유사한 유명인 찾기 (군집화 모델)</Header>
            <HeaderNav to1="/celebrityconcept" to2="/celebrity" />
            <Conceptcheck>클러스터링은 하나의 데이터를 여러 개의 부분집합으로 분할하는 모델입니다.
                    영상을 통해서 자세히 알아보도록 합시다. </Conceptcheck>

            <main>

                <div className="youtubezone">
                    <iframe width="80%" height="500"
                        src="https://www.youtube.com/embed/zLt7iNXL85c?si=q6el-F5XAKK8Wfz6"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ margin: '2rem', border: "none" }}
                    ></iframe>
                </div>


                <BlueBtn to="/celebrity">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}