/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient2\Modelindex.js
 * @fileName        : Modelindex.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 선택된 모델 인덱스 관리 함수
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
var modelindex = 0;


export function setModelindex(data) {
    modelindex = data;
}
export function getModelindex() {
    return modelindex + 1;
}