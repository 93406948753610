/**
 * @filePath        : src\components\pages\supervised\genderage\genderage4\Imagecard.js
 * @fileName        : Imagecard.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 결과 데이터 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
export default function Imagecard({ src, gender, age}) {
    return (
        <>
            <div className="genderage1-imagecard">
                <div className="genderage1-imagecard-ele">
                    <p>{gender}({age})</p>
                </div>

                <img src={src} alt="img" style={{width:"100%"}} />
            </div>
        </>
    );
}

