/**
 * @filePath        : src\components\pages\supervised\prediction\prediction2\TableInput.js
 * @fileName        : TableInput.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 파라미터 테이블 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/ProgressBar";
import React from "react";

export default function TableInput() {
    const [modelArgParam, setModelArgParam] = useState(null);

    const [loading, setLoading] = useState(true); // 로딩 상태값

    useEffect(() => {
        const params = { ALG_ID: "A1000" };
        axios
            .get(`${URL}/api/model/alg`, { params })
            .then((result) => {
                setModelArgParam(JSON.parse(result.data.list[0].MODEL_ARG_PARAM));
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    }, []);

    return (
        <>
            {loading && <ProgressBar />} {/* loading 값이 true일 때만 프로그래스바 컴포넌트 표시 */}
            <div className="masterpiece3-tableinput">
                <h3>하이퍼 파라미터</h3>
                <div className="masterpiece3-table-space">
                    <div className="masterpiece3-table-container">
                        <table className="masterpiece3-table">
                            <tbody>
                                {modelArgParam && modelArgParam.length > 0 && (
                                    <>
                                        {modelArgParam.map((item, index) => (
                                            <tr key={index}>
                                                {Object.entries(item).map(([key, value]) => (
                                                    <React.Fragment key={key}>
                                                        <td style={{ color: "orange" }}>•</td>
                                                        <td>
                                                            {key}
                                                            <b style={{ color: "red", marginLeft: "5px" }}>*</b>
                                                        </td>
                                                        <td>
                                                            <input type="text" value={value} disabled />
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
