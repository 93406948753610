/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons2\ClusterDesc.js
 * @fileName        : ClusterDesc.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 모델 설명 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import clusterdescimg from "assets/unsupervised/seasons/cluster_desc_img.png";

export default function ClusterDesc() {
    return (
        <div style={{ padding: "1%" }}>
            <img src={clusterdescimg} alt="cluster description img" style={{ width: "100%" }} />

            <div className="seasons2-clusterdesc-grid">
                <p>원하는 군집 수 만큼 임의의 군집 중심점 설정 <br />(위 그림에서는 2개로 설정)</p>
                <p>각 데이터는 가장 가까운 중심점에 소속</p>
                <p>중심점에 할당된 데이터들의 평균값으로 중심점 이동</p>
                <p>각 데이터는 이동된 중심점 기준으로 가장 가까운 중심점에 소속</p>
                <div>
                    <p>다시 중심점에 할당된 데이터들의 평균 중심으로 중심점 이동</p>
                    <p style={{color:"#503cb2"}}>→ 중심점을 이동해도 데이터들의 중심점 소속 변경이 없으면 군집화 완료</p>
                </div>
            </div>
        </div>
    );
}