/**
 * @filePath        : src\components\pages\supervised\prediction\prediction3\Chart.js
 * @fileName        : Chart.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 결과 데이터 차트 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

export default function Chart(props) {
    const { yMax, label, receiveLoss, borderColor, backgroundColor } = props;

    if (receiveLoss === null) return;

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const options = {
        scales: {
            x: {
                beginAtZero: true,
                max: 60000,
                ticks: {
                    maxTicksLimit: 12,
                },
            },
            y: {
                beginAtZero: true,
                max: yMax,
                ticks: {
                    maxTicksLimit: 12,
                },
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
        },
    };

    const labels = Object.entries(receiveLoss).map(([key, value]) => {
        return (key / 1000).toFixed(1) + "k";
    });
    const loss = Object.entries(receiveLoss).map(([key, value]) => {
        return value;
    });

    const data = {
        labels,
        datasets: [
            {
                label: label,
                data: loss,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
            },
        ],
    };

    return (
        <>
            <Line options={options} data={data} />
        </>
    );
}
