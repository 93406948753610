/**
 * @filePath        : src\components\pages\supervised\emotion\emotion4\User.js
 * @fileName        : User.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 결과 확인 단계 댓글 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import good from "assets/supervised/emotion/talk_smile.png";
import bad from "assets/supervised/emotion/talk_anger.png";
export default function User({ TRUE_LABEL, NICKNAME, DOCUMENT, index, onChange, selectedindex}) {

    const handleClick = () => {
        onChange(index)
    };

    return (
        <>
            <div className="emotion4-talker-space" onClick={handleClick}>
                {TRUE_LABEL === "1" ? (
                    <img src={good} alt="emotion img" />) : (
                    <img src={bad} alt="emotion img" />
                )}

                <div className="emotion4-talker-info">
                    <div className="emotion4-talker-nickname">
                        {NICKNAME}
                    </div>
                    <div className={`emotion4-talker-document ${ selectedindex === index ? "clickedtalker" : ""}`}>
                        {DOCUMENT}
                    </div>
                </div>
            </div>
        </>
    );
}