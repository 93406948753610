/**
 * @filePath        : src\components\pages\reinforcement\arctic\arcticconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

import example1 from "assets/reinforcement/arctic/reinforcement_example1.png";
import example2 from "assets/reinforcement/arctic/reinforcement_example2.png";
import example3 from "assets/reinforcement/arctic/reinforcement_example3.png";
import example4 from "assets/reinforcement/arctic/reinforcement_example4.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "알파고",
            desc1: "2016년 이세돌과의 바둑 대국에서 승리한 알파고 역시 강화학습 알고리즘을 통해 학습한 모델입니다. 알파고는 먼저 인간 바둑 기사들의 경기를 분석한 후, 모방학습을 통해 기본 원칙과 전략을 습득했습니다.",
            desc2: "이 과정에서 알파고는 전략적인 판단과 예측, 그리고 지속적인 학습을 통해 바둑 능력을 개발하고 최종적으로 이세돌과의 대국에서 승리하는 등 화제를 모았습니다. 알파고의 이러한 성과는 강화학습 기술이 어떻게 현실 세계의 복잡한 문제를 해결할 수 있는지를 보여주며, 이후 다양한 분야에서의 응용과 발전을 촉진시키는 계기가 되었습니다.",
            page: "1/4"
        },
        {
            image: example2,
            title: "자율 주행",
            desc1: "강화 학습을 통해 자율주행 자동차는 다양한 후보 경로를 생성하고 각 경로에 대한 예측적인 보상 값을 계산합니다. 보상 값의 최대화를 목표로 최적의 경로를 찾는 작업을 수행합니다.",
            desc2: "이 과정을 통해 자율주행 자동차는 주행 도중에도 주변 환경과 상황에 따라 적절한 의사 결정을 내리며, 운전자의 개입 없이도 안전하고 효율적인 주행을 실현할 수 있습니다. ",
            page: "2/4"
        },
        {
            image: example3,
            title: "로봇 제어",
            desc1: "사람이 넘어지지 않고 자전거 타는 법을 배우는 것과 같이, 로봇 또한 보행하는 법을 배울 수 있습니다. 로봇은 낮은 에너지 소비로 효율적으로 걷는 방법을 찾아내기 위해 강화학습 알고리즘을 활용하여 학습합니다. 이때 로봇은 다양한 걷는 동작 중에서 에너지 소비가 적은 움직임을 선택하고 이를 통해 안전한 보행 전략을 개발합니다.",
            desc2: "이 과정에서 로봇은 보상을 최대화하기 위해 에너지 소비를 최소화하며 다양한 환경에서 안전하게 주변 상황에 적응하여 이동하도록 학습됩니다.",
            page: "3/4"
        },
        {
            image: example4,
            title: "파워 관리 및 에너지 절약",
            desc1:" 강화학습은 건물 및 산업 시설의 에너지 소비 관리와 최적화에 매우 유용하게 활용되고 있습니다. 이 기술은 시설의 내부 및 외부 환경을 실시간으로 모니터링하고, 에너지 소비 패턴과 사용량 데이터를 분석하여 효율적인 에너지 절약 전략을 학습하고 결정하는 데에 활용됩니다.",
            desc2: "이를 통해 시설 관리자나 자동화 시스템은 강화학습을 통해 개선된 에너지 관리 방법을 적용하여 에너지 효율성을 극대화하고 비용을 절감할 수 있습니다. 예를 들어, 건물 내부 온도, 조명 제어, 공조 시스템 운영 등을 조정하는 최적의 시나리오를 학습하여 시간대별로 자동으로 에너지 소비를 조절할 수 있습니다.",
            page: "4/4"
        },
    ];
    return (
        <>
            <Header learning="강화학습">북극에서 살아남기</Header>
            <HeaderNav to1="/arcticconcept" to2="/arctic" />
            <Conceptcheck>강화학습모델은 생활에서 활용되고 있습니다.
                    가장 대표적인 예로 알파고, 자율 주행, 로봇 제어 등이 있습니다.</Conceptcheck>
                    
            <main>
                <Carousel items={carouselItems} />

                <BlueBtn to="/arctic">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}