/**
 * @filePath        : src\components\sections\boxs\Carousel.js
 * @fileName        : Carousel.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 개념학습 예시 캐러셀 틀 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState } from "react";
import CarouselItem from "./CarouselItem";

export default function Carousel({ items }) {
    const [currentIndex, setCurrentIndex] = useState(0);


    const prevSlide = () => {
        const newIndex = currentIndex === 0 ? 0 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const newIndex = currentIndex === items.length - 1 ? items.length - 1 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const currentItem = items[currentIndex];


    return (
        <div>
            <CarouselItem
                image={currentItem.image}
                title={currentItem.title}
                desc1={currentItem.desc1}
                desc2={currentItem.desc2}
                page={currentItem.page}
                onPrevSlide={prevSlide}
                onNextSlide={nextSlide}
            />
        </div>
    );
}