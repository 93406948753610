/**
 * @filePath        : src\components\pages\unsupervised\news\news2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import PretrainedModel from "components/pages/unsupervised/news/news2/PretrainedModel";
import { useState } from "react";
import Step from "components/sections/boxs/Step";
export default function Main() {
    const [step, setStep] = useState(1);

    const handleStep = (newState) => {
        setStep(newState);
    };

    return (
        <>
            <Header learning="비지도학습">AI를 이용한 여러 종류의 글쓰기 (사전학습 모델)</Header>
            <HeaderNav to1="/newsconcept" to2="/news" />
            <main>
                <div className="main-step-img">
                    {step === 1 && (
                        <Step step1="데이터 입력" state1={1} step2="모델 선택" state2={0} step3="결과 확인" state3={2}>
                            사전 학습된 모델을 이용해 작문해봅시다. 글을 작성할 대상을 선택한 후 글의 주제가 될 단어를 4개 이하로 골라주세요.
                            <br></br>(딥러닝 모델로 만들어진 문장이므로 완전하지 않은 문장일 수 있습니다)
                        </Step>
                    )}
                    {step === 2 && (
                        <Step step1="데이터 입력" state1={1} step2="모델 선택" state2={1} step3="결과 확인" state3={0}>
                            사전 학습된 모델을 이용해 작문해봅시다. 글을 작성할 대상을 선택한 후 글의 주제가 될 단어를 4개 이하로 골라주세요.
                            <br></br>(딥러닝 모델로 만들어진 문장이므로 완전하지 않은 문장일 수 있습니다)
                        </Step>
                    )}
                </div>

                <PretrainedModel step={step} onStateChange={handleStep} />
            </main>
        </>
    );
}
