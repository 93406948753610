/**
 * @filePath        : src\components\sections\boxs\Conceptcheck.js
 * @fileName        : Conceptcheck.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 말풍선에 느낌표 있는 텍스트 컴포넌트 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import conceptcheck from "assets/conceptcheck.png";

export default function Conceptcheck({ children }) {
    return (
        <div className="conceptcheck">
            <img src = {conceptcheck} alt = "checkimg"/>
            <b>{children}</b>
        </div>
    );
}