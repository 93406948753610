/**
 * @filePath        : src\components\pages\reinforcement\flappy\flappy1\Learningmodel.js
 * @fileName        : Learningmodel.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : Flappy Bird 모델의 학습 결과 영상 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";

export default function Learningmodel({ try1, try2, try3, src1, src2, src3 }) {
    const [selectedSrc, setSelectedSrc] = useState(null);
    //const [state1, setState1] = useState(false);
    //const [state2, setState2] = useState(false);
    //const [state3, setState3] = useState(false);
    const [showtext, setShowText] = useState(false);
    const [text, setText] = useState("");
    const [isVisible, setIsVisible] = useState(true);

    const handleBtnClick = (src) => {
        if (src === src1) {
            setSelectedSrc(src1);
            setText("2배속 영상입니다");
            setShowText(true);
        }
        else if (src === src2) {
            setSelectedSrc(src2);
            setText("4배속 영상입니다");
            setShowText(true);
        }
        else {
            setSelectedSrc(src3);
            setText("4배속 영상입니다");
            setShowText(true);
        }
    };
    // 비디오 재생이 끝났을 때 실행할 액션
    const handleVideoEnded = () => {
        if (selectedSrc === src1) {
            //setState1(true);
            setShowText(false);
        }
        else if (selectedSrc === src2) {
            //setState2(true);
            setShowText(false);
        }
        else if (selectedSrc === src3) {
            //setState3(true);
            setShowText(false);
        }
    };

    //깜빡임 효과
    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prevIsVisible) => !prevIsVisible);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <>
            <div className="flappy-main" style={{ display: "flex", flexFlow: "column", justifyContent: "flex-start" }}>

                <div className="flappy-learningmodel">
                    {showtext && <p style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s', }}>{text}⏩</p>}
                    <video key={selectedSrc}
                        muted
                        autoPlay
                        height="100%"
                        onEnded={handleVideoEnded}
                        className="flappy-learningmodel-simulation">
                        <source src={selectedSrc} />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <h4>⬇️ 버튼을 클릭해 학습 결과를 확인하세요 !</h4>
                <div className="flappy-learningmodel-btn-group">
                    <div className={`flappy-learningmodel-btn ${selectedSrc === src1 ? 'flappy-button-selected' : ''}`}
                        onClick={() => handleBtnClick(src1)}>{try1}</div>

                    <div className={`flappy-learningmodel-btn ${selectedSrc === src2 ? 'flappy-button-selected' : ''}`}
                        onClick={() => handleBtnClick(src2)}>{try2}</div>

                    <div className={`flappy-learningmodel-btn ${selectedSrc === src3 ? 'flappy-button-selected' : ''}`}
                        onClick={() => handleBtnClick(src3)}>{try3}</div>
                </div>
                {/* {(state1 && state2 && state3) &&
                    <p style={{ fontWeight: "500", fontSize: "1.2rem", margin: "0" }}>⭐강화 학습의 원리와 같이, 학습 횟수가 늘어남에 따라 게임의 성능이 향상되는 것을 알 수 있습니다⭐</p>} */}

            </div>
        </>
    );
}