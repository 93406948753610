/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiececonceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/unsupervised/masterpiece/masterpiece_example1.png";
import example2 from "assets/unsupervised/masterpiece/masterpiece_example2.png";
import example3 from "assets/unsupervised/masterpiece/masterpiece_example3.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "AI 커버곡 생성",
            desc1:
                "AI의 발전은 예술에도 많은 영향을 끼치게 되었습니다. AI에게 내가 좋아하는 가수의 목소리와 창법을 학습시켜 그 가수의 목소리로 부른 노래들을 생성할 수 있습니다. 동영상 스트리밍 사이트인 유튜브에 AI 기술로 여러 가수가 다른 가수의 노래를 커버한 영상을 쉽게 접할 수 있습니다.",
            desc2:
                "이러한 기술은 AI에게 가수의 음성 데이터의 특징을 학습시키고 모방하여 원하는 목소리로 노래를 부르게 하는 음성 합성과 딥러닝으로 구현할 수 있습니다.",
            page: "1/3"
        },
        {
            image: example2,
            title: "텍스트로 웹툰 생성",
            desc1:
                "많은 학생들의 사랑을 받는 웹툰 또한 AI로 그릴 수 있을 정도로 AI는 많이 발전했습니다. AI에게 간단한 문구만 주어도 알아서 화풍, 빛의 흐름, 현재 장소, 장소의 사물과 같은 요소들을 파악하여 스스로 그림을 그릴 수 있습니다",
            desc2:
                "AI는 주어진 문구를 해석하여 사용자의 요구를 이해한 뒤에 학습을 했던 사진 데이터를 바탕으로 사용자가 원하는 그림을 제공하게 되어 하나의 웹툰을 완성 할 수 있도록 도와줍니다. GAN을 활용한 웹툰 작업은 창의성과 생산성을 높일 수 있으며, 다양한 시각적 요소를 생성하고 수정하는 데 유용한 도구로 사용될 수 있습니다.",
            page: "2/3"
        },
        {
            image: example3,
            title: "이미지 화질 개선",
            desc1:
                "AI 기술을 활용해 저화질의 사진이나 영상을 고화질로 바꾸는 기술은 옛날 사진을 복원하거나 화질이 좋지 않은 CCTV 영상을 분석할 때 매우 효과적입니다.",
            desc2:
                "이때 AI는 저화질 데이터의 고화질 상태를 예측하여 그 결과를 사용자에게 제공하므로 원본 이미지/영상의 화질을 향상시킵니다. GAN을 활용한 이미지 화질 개선 기술은 의료 영상, 영화 및 비디오 복원, 게임 그래픽과 같은 다양한 분야에서 이미지 품질을 향상시키는 데 활용됩니다.",
            page: "3/3"
        },
    ];
    return (
        <>
            <Header learning="비지도학습">사진 명화풍 만들기 (GAN 모델)</Header>
            <HeaderNav to1="/masterpiececoncept" to2="/masterpiece" />
            <Conceptcheck> GAN 모델은 생활에서 활용되고 있습니다. 
                가장 대표적인 예로 AI 커버곡 생성, 텍스트로 웹툰 생성, 이미지 화질 개선 등이 있습니다. </Conceptcheck>
            <main>

                <Carousel items={carouselItems} />

                <BlueBtn to="/masterpiece">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}