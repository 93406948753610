/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";

import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn"
import Learingmodel from "components/pages/reinforcement/arctic/arctic3/Learningmodel";
import Step from "components/sections/boxs/Step";
//import { useState } from "react";
export default function Main() {
    // const [state, setState] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    // const [modalMessage, setModalMessage] = useState('');

    // const handleNextClick = () => {
    //     if (state === false) {
    //         setModalMessage("학습 결과를 모두 확인해주세요 !");
    //         setShowModal(true);
    //     }
    // };
    // const closeModal = () => {
    //     setShowModal(false);
    // };
    // const handleChange = (newValue) => {
    //     setState(newValue);
    // };
    return (
        <>
            <Header learning="강화학습">북극에서 살아남기</Header>
            <HeaderNav to1="/arcticconcept" to2="/arctic" />
            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={1} step2="모델 이해" state2={1} step3="결과 확인" state3={0}>
                        모델 학습이 완료되었습니다.<br />
                        각각 횟수만큼 훈련 시켰을 때 길을 잘 찾게 되었는지 확인해보세요.
                    </Step>
                </div>

                <Learingmodel
                    src1={"/videos/arctic/100_training.mp4"}
                    src2={"/videos/arctic/1000_training.mp4"}
                    src3={"/videos/arctic/5000_training.mp4"}
                    // onChange={handleChange}
                >
                </Learingmodel>

                
                <div className="main-btn">
                    <GreyBtn to="/arctic/2"></GreyBtn>
                    <BlueBtn to="/">체험 종료</BlueBtn>

                    {/* {state ? (
                        <BlueBtn to="/">체험 종료</BlueBtn>
                    ) : (
                        <BlueBtn onClick={handleNextClick}>체험 종료</BlueBtn>
                    )} */}
                </div>


                {/* {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )} */}
            </main>
        </>
    );
}