/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import clusterresult from "assets/unsupervised/seasons/cluster_result.png";
import { useState } from "react";
import Table from "components/pages/unsupervised/seasons/seasons3/Table";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [step, setStep] = useState(0);

    return (
        <>
            <Header learning="비지도학습">사계절 분류하기 (군집화 모델)</Header>
            <HeaderNav to1="/seasonsconcept" to2="/seasons" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 이해" state2={1} step3="결과 확인" state3={0} step4="평가하기" state4={2}>
                        완료된 클러스터링 결과를 시각화하였습니다. 3개의 변수에 따라 4개의 계절로 나누어보았습니다.
                    </Step>
                </div>

                <div className="white-back">
                    {step === 0 && (<>
                        <div>
                            <h2>최종 모델</h2>
                            <img src={clusterresult} alt="cluster result img" style={{width:"40%"}}/>
                        </div>
                    </>)}
                    {step === 1 && (<>
                        <Table />
                    </>)}

                </div>

                <div className="main-btn">
                    {step === 0 && (<>
                        <GreyBtn to="/seasons/2"></GreyBtn>
                        <BlueBtn onClick={() => setStep(step + 1)}>다음 단계로</BlueBtn>
                    </>)}
                    {step === 1 && (<>
                        <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                        <BlueBtn to="/seasons/4">결과 확인 완료</BlueBtn>
                    </>)}
                </div>
            </main>
        </>
    );
}