/**
 * @filePath        : src\components\pages\supervised\emotion\emotion2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 모델의 텍스트 감정 분석 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import model1 from "assets/supervised/emotion/emotionAnalysis01.png";
import model2 from "assets/supervised/emotion/emotionAnalysis02.png";
import model3 from "assets/supervised/emotion/emotionAnalysis03.png";
import ClusterItem from "components/pages/supervised/coldpatient/coldpatient2/ClustersItem";
import { useState } from "react";
import { setModelindex } from "components/pages/supervised/emotion/emotion1/EmotionData";
export default function Main() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const carouselItems = [
        {
            image: model1,
            title: "BERT",
            desc: "BERT는 Bidirectional Encoder Representations from Tansformers의 약자로 2018년도 구글에서 공개된 사전학습 모델입니다. 등장과 동시에 자연어 처리분야 11개 태스크에서 가장 좋은 성능(SOTA)을 보여 주목받은 모델입니다.",
        },
        {
            image: model2,
            title: "Distil BERT",
            desc: "DistilBERT는 a distilled version of BERT의 줄임말로 지식증류(Knowledge Distillation)기법을 적용한 모델입니다. 지식증류는 큰 사이즈의 모델(선생님 모델)의 동작을 재현하는 작은 사이즈의 모델(학생 모델)을 훈련시키는 기술로 DistilBERT는 기존 BERT 모델과 비교하였을 때 40% 가량 적은 파라미터로 60%이상의 개선된 추론 속도를 얻었습니다. ",
        },
        {
            image: model3,
            title: "ELECTRA",
            desc: "ELECTRA는 Effciently Learning an Encoder that Classifies Token Replacements Accuracy의 약자로 기존의 많은 언어모델들이 학습의 과정에서 일부 입력을 MASK 토큰으로 치환하고 이를 원본 토큰으로 복원하는 masked language modeling 태스크를 통해 사전 학습하였지만 이는 많은 계산량을 필요로 합니다. ELECTRA는 이를 해결하기 위해 대체 토큰 탐지(RTD, Replaced Token Detection)이라는 사전학습 방법을 적용한 모델로 기존 모델보다 적은 연산량으로 더 훌륭한 성능을 보인 모델입니다.",
        },

    ];
    const currentItem = carouselItems[currentIndex];

    const prevItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length);
    };
    const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };
    const handleNextClick = () => {
        setModalMessage(`${currentItem.title}모델을 선택하셨습니다 !`);
        setModelindex(currentIndex);
        setShowModal(true);
    };
    return (
        <>
            <Header learning="지도학습">텍스트 감정 분석하기 (텍스트 분류모델)</Header>
            <HeaderNav to1="/emotionconcept" to2="/emotion" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 입력" state1={1} step2="텍스트 감정 분석" state2={0} step3="모델 평가" state3={2} step4="결과 확인" state4={2}>
                        최근 자연어 처리는 사전 학습된 언어모델을 통해 이루어지고 있습니다.<br />
                        BERT, DistilBERT, ELECTRA 세 가지 모델을 준비했습니다.
                        모델의 장, 단점을 확인하고 선택하여 실행해보세요.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="coldpatient2-carousel">
                        <button onClick={prevItem} style={{ fontSize: "2rem", color: "gray", cursor: "pointer" }}>◀</button>

                        <div style={{ width: "90%", height: "30rem", display: "flex" }}>
                            <ClusterItem
                                image={currentItem.image}
                                title={currentItem.title}
                                desc={currentItem.desc} />
                        </div>
                        <button onClick={nextItem} style={{ fontSize: "2rem", color: "gray", cursor: "pointer" }}>▶</button>
                    </div>
                </div>


                <div className="main-btn">
                    <GreyBtn to="/emotion/1"></GreyBtn>
                    <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3 style={{ margin: "0" }}>{modalMessage}</h3>
                            <h3 style={{ margin: "0" }}>학습을 시작합니다.</h3>
                            <BlueBtn to="/emotion/3">모델 실행</BlueBtn>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}

