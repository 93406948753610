/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 데이터 입력 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Mbti from "components/pages/unsupervised/celebrity/celebrity1/Mbti";
import Enneagram from "components/pages/unsupervised/celebrity/celebrity1/Enneagram";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";
import { useState } from "react";
import { getEI, getSN, getTF, getJP, getType1, getType2, getType3 } from "components/pages/unsupervised/celebrity/celebrity1/CelebrityData";

export default function Main() {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [state, setState] = useState(false);
    const [state2, setState2] = useState(false);

    const handleNextClick = () => {
        if (getType3() === 0) {
            setModalMessage("3번째 애니어그램 성향을 선택해주세요.");
            setShowModal(true);
        }
        if (getType2() === 0) {
            setModalMessage("2번째 애니어그램 성향을 선택해주세요.");
            setShowModal(true);
        }
        if (getType1() === 0) {
            setModalMessage("1번째 애니어그램 성향을 선택해주세요.");
            setShowModal(true);
        }
        if (getJP() === 0) {
            setModalMessage("4번째 MBTI 성향을 선택해주세요.");
            setShowModal(true);
        }
        if (getTF() === 0) {
            setModalMessage("3번째 MBTI 성향을 선택해주세요.");
            setShowModal(true);
        }
        if (getSN() === 0) {
            setModalMessage("2번째 MBTI 성향을 선택해주세요.");
            setShowModal(true);
        }
        if (getEI() === 0) {
            setModalMessage("1번째 MBTI 성향을 선택해주세요.");
            setShowModal(true);
        }
    };
    const handleChange = (newValue) => {
        setState(newValue);
    };
    const handleChange2 = (newValue) => {
        setState2(newValue);
    };
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="비지도학습">나와 유사한 유명인 찾기 (군집화 모델)</Header>
            <HeaderNav to1="/celebrityconcept" to2="/celebrity" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 입력" state1={0} step2="군집 분석" state2={2} step3="결과 확인" state3={2}>
                        나의 MBTI 성향, 애니어그램 성향을 선택합니다.
                        <br />
                        MBTI는 나와 가장 가까운 성향을, 애니어그램은 가장 적합한 세가지의 성향을 골라주세요.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="multinomial-model">
                        <div style={{ borderRight: "dashed 2px #d1d1d1" }}>
                            <Mbti onChange={handleChange} />
                        </div>

                        <div style={{ textAlign: "left", padding: "0rem 1rem" }}>
                            <Enneagram onChange={handleChange2} />
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/celebrity"></GreyBtn>
                    {state && state2 ? (
                        <BlueBtn to="/celebrity/2">다음 단계로</BlueBtn>
                    ) : (
                        <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>
                    )}

                </div>
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}
