/**
 * @filePath        : src\components\pages\supervised\object\objectconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/supervised/object/object_example1.png";
import example2 from "assets/supervised/object/object_example2.png";
import example3 from "assets/supervised/object/object_example3.png";
import example4 from "assets/supervised/object/object_example4.png";
import example5 from "assets/supervised/object/object_example5.png";
import example6 from "assets/supervised/object/object_example6.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "교통량 측정",
            desc1:
                "객체 검출 기술은 현대의 도로 관리와 교통 흐름 분석에 핵심적인 역할을 하고 있습니다. 특히 영상 내에서 감지된 객체가 어떤 종류의 차량인지를 정확하게 구분하고, 개별 객체의 이동 속도를 측정하는 기능은 교통 데이터의 생성을 위한 중요한 부분입니다.",
            desc2:
                "이렇게 수집된 데이터는 도로의 혼잡도를 평가하고 교통 흐름을 최적화하는 데에 유용하게 활용됩니다. 또한 도로의 사고나 정체 지점을 빠르게 감지하여 신속한 대응이 가능하며, 교통 시스템의 효율성과 안전성을 높이는 데에 기여합니다.",
            page: "1/6"
        },
        {
            image: example2,
            title: "보안 시스템",
            desc1:
                "CCTV나 보안 카메라에서 객체 검출 모델은 더욱 정확하고 효율적인 보안 감시 시스템을 구축하기 위한 핵심 기술로 활용됩니다. 이 모델은 주로 불법적인 활동이나 이상 행동을 식별하고 감지하는 데에 중요한 역할을 하며, 이를 통해 공공 장소나 건물 내부에서의 안전과 보안을 강화하는 데 기여합니다.",
            desc2:
                "예를 들어, 사람이나 차량과 같은 이동 객체를 식별하여 침입 사고나 사건을 신속하게 감지하고 대응할 수 있습니다.",
            page: "2/6"
        },
        {
            image: example3,
            title: "자율 주행 시스템",
            desc1:
                "사람이 운전 시 주변 환경을 주의 깊게 감시하는 것과 유사하게, 자율 주행 시스템은 객체 검출을 통해 주변 환경을 인식합니다. 이 시스템은 카메라, 레이더, LIDAR와 같은 센서들을 활용하여 차선, 주변 사물, 지나가는 보행자와 같은 다양한 요소를 식별하고 추적합니다.",
            desc2:
                "이렇게 수집된 정보는 시스템이 주행 중 상황을 이해하고 적절한 조치를 취할 수 있도록 도와줍니다. 이를 통해 자율 주행 차량은 보다 안전하고 정확한 주행을 실현하며, 운전자의 주시와 판단을 대체하는 첨단 기술의 한 예시로 주목받고 있습니다.",
            page: "3/6"
        },
        {
            image: example4,
            title: "열 감지 카메라",
            desc1:
                "열화상 카메라는 객체의 열 에너지를 감지하여 그에 따른 온도 분포를 시각화 합니다. 사람 객체의 온도를 측정하면 실내 및 실외 환경에서도 사람의 위치를 정확하게 파악할 수 있습니다.",
            desc2: 
                "예를 들어, 어떤 장소에서 사람의 분포와 움직임을 파악하려면 열화상 카메라를 통해 실시간으로 사람들의 온도를 획득하고 이를 영상화 하여 시각화 합니다. 이를 통해 큰 인원이 모이는 행사나 공공 장소에서의 인원 관리, 화재 대피 시나리오 등 다양한 상황에서 객체 감지와 온도 측정 기술은 중요한 역할을 할 것입니다.",
            page: "4/6"
        },
        {
            image: example5,
            title: "의료 이미지 분석",
            desc1:
                "의료 분야에서 객체 검출 모델은 현대 의학의 진단과 치료 과정에서 매우 중요한 역할을 수행하고 있습니다.",
            desc2: 
                "이 모델은 종양, 병변, 해부 구조와 같은 중요한 정보를 식별하고 정확하게 분석하는 데에 활용됩니다. 이는 조기 발견과 정확한 진단을 통해 환자의 생존률을 높이고 치료 과정을 개선하는 데에 도움을 줍니다. 이처럼 의료 분야에서 객체 검출 모델은 의사들의 판단을 보완하고 환자 치료에 높은 수준의 정확성과 효율성을 제공하는 데에 큰 역할을 하며, 현대 의료 기술의 발전에 기여하고 있습니다.",
            page: "5/6"
        },
        {
            image: example6,
            title: "실신 감지 시스템",
            desc1: 
                "사람의 움직임을 인식하는 시스템은 고급 컴퓨터 비전 기술과 딥러닝 알고리즘을 활용하여 구현됩니다. CCTV 카메라가 촬영한 영상 데이터를 실시간으로 분석하여 사람의 동작을 인식하고 판단합니다.",
            desc2: 
                "예를 들어, 사람이 일상적인 움직임과는 다른 비정상적인 자세를 취하거나, 넘어지는 등의 동작을 감지하면 이는 실신으로 해석될 수 있습니다. 시스템은 이런 패턴을 학습하고 실시간으로 분석하여, 실신과 같은 긴급한 사건이 발생할 경우 즉각적으로 경보를 발생시키거나 관련 담당자에게 알림을 전달합니다.",
            page: "6/6"
        },
    ];
    return (
        <>
            <Header learning="지도학습">사진에서 서로 다른 대상 찾아내기 (객체 검출)</Header>
            <HeaderNav to1="/objectconcept" to2="/object" />
            <Conceptcheck> 객체 검출은 생활에서 다양하게 활용되고 있습니다.
                가장 대표적인 예로 자율 주행 시스템, 교통량 측정, 열 감지 카메라 등이 있습니다. </Conceptcheck>
            <main>

                <Carousel items={carouselItems} />

                <BlueBtn to="/object">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}