/**
 * @filePath        : src\components\pages\reinforcement\cartpole\cartpole2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 막대 중심 잡기 모델의 모델 이해 단계 설명
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import greenboard from "assets/reinforcement/cartpole/greenboard.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn"
import Step from "components/sections/boxs/Step";

export default function Main() {

    return (
        <>
            <Header learning="강화학습">막대 중심 잡기</Header>
            <HeaderNav to1="/cartpoleconcept" to2="/cartpole" />

            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={1} step2="모델 이해" state2={0} step3="결과 확인" state3={2}>
                        아래 선생님의 설명을 읽고 강화학습의 개념을 이해해봅시다.
                    </Step>
                </div>

                <div className="cartpole2-main">
                    <img src={greenboard} alt="greenboard" />
                    <div className="cartpole2-main-desc">
                        <p>
                            강화학습은 컴퓨터가 사람이 게임을 할 때처럼 학습하는 방법입니다.
                        </p>
                        <p>
                            우리가 처음 게임을 시작할 때에는 어떤 행동을 해야할지 잘 모르는 것처럼, <br />
                            컴퓨터도 처음에는 무엇을 해야할지 모릅니다. <br />
                            하지만 게임을 하면서 경험을 쌓고 어떤 행동이 좋고 나쁜지 알게 되듯이, <br />
                            컴퓨터도 게임을 하며 스스로 경험을 통해 게임을 더 잘하는 방법을 배우게 됩니다.
                        </p>
                        <p>
                            게임을 할 때, 잘한 행동에는 점수를 받고, 잘못한 행동에는 점수가 깎입니다.<br />
                            강화학습의 원리도 같습니다. <br />
                            컴퓨터는 자신이 받은 보상을 최대화하는 방법을 스스로 찾아가면서 점점 게임을 더 잘하게 됩니다.
                        </p>
                        <p>
                            이렇게 강화학습은 컴퓨터가 스스로 학습하고 능력을 향상시키게 해주는 방법입니다.<br />
                            그럼 아래 버튼 눌러 모델을 학습시키고 결과를 확인해봅시다.
                        </p>
                    </div>
                </div>


                <div className="main-btn">
                    <GreyBtn to="/cartpole/1"></GreyBtn>
                    <BlueBtn to="/cartpole/3">모델 실행</BlueBtn>
                </div>
            </main>
        </>
    );
}