/**
 * @filePath        : src\components\pages\supervised\prediction\predictionconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/supervised/weather/weather_example1.png";
import example2 from "assets/supervised/weather/weather_example2.png";
import example3 from "assets/supervised/weather/weather_example3.png";
import example4 from "assets/supervised/weather/weather_example4.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "아파트 가격 예상",
            desc1: "아파트 가격은 아파트의 넓이, 위치, 지어진 시기, 주변의 환경 등의 요소에 의해 달라집니다. 이런 다양한 요소를 회귀 모델에서는 변수라고 합니다. ",
            desc2: "예를 들어 아파트의 가격은 넓이, 위치, 지어진 시기, 주변의 환경 등의 다양한 조합에 따라 결정되기 때문에 변수를 고르고 이들 변수들 간의 관계를 파악하는 것은 매우 중요합니다. 회귀 모델은 이러한 변수들 간의 관계를 파악하여 가격을 예상하는데 도움을 줍니다.",
            page: "1/4"
        },
        {
            image: example2,
            title: "자동차 연비 예상",
            desc1: "자동차의 연비(일정 연료로 자동차가 가는 거리)는 자동차가 만들어진 시기, 배기량, 자동차가 가진 힘의 크기, 무게 등의 요소에 의해 달라집니다. ",
            desc2: "예를 들면 자동차의 연비는 자동차가 만들어진 시기, 배기량, 자동차가 가진 힘의 크기, 무게 등의 다양한 조합에 따라 결정되기 때문에 변수를 고르고 이들 변수들 간의 관계를 파악하는 것은 매우 중요합니다. ",
            page: "2/4"
        },
        {
            image: example3,
            title: "날씨 예상",
            desc1: "날씨는 날짜, 최저기온, 최고기온, 일조시간 등의 요소에 의해 달라집니다. 예를 들어 날씨는 날짜, 최저기온, 최고기온, 일조시간 등의 다양한 조합에 따라 결정되기 때문에 변수를 고르고 이들 변수들 간의 관계를 파악하는 것은 매우 중요합니다. ",
            desc2: "이와 같은 회귀 모델은 농작물 생산, 에너지 관리, 비행 여정 계획에도 사용할 수 있습니다.",
            page: "3/4"
        },
        {
            image: example4,
            title: "질병 발생 예상",
            desc1: "질병 발생 가능성은 환자의 나이, 성별, 각종 건강 검사 결과 등의 요소에 의해 달라집니다. ",
            desc2: "예를 들면 환자의 과거 건강 기록, 유전적 요인, 생활 습관 등을 변수로 해서 회귀 모델을 만들면 당뇨병, 심혈관 질병, 암 등의 질병 발생을 예상하는 데 활용될 수 있고, 질병의 조기 예방 및 진단을 통해 치료의 효과를 높이고 환자들의 건강한 삶을 가능하게 하는데 도움을 줍니다.",
            page: "4/4"
        },
    ];

    return (
        <>
            <Header learning="지도학습">공유자전거 수요 예측 (딥러닝 모델)</Header>
            <HeaderNav to1="/predictionconcept" to2="/prediction" />
            <Conceptcheck> 회귀 분석은 가장 단순한 형태의 딥러닝 모델이라고 할 수 있습니다. <br/>
            딥러닝 모델은 생활에서 다양하게 활용되고 있습니다. 가장 대표적인 예로 아파트 가격 예상, 자동차 연비 예상, 날씨 예상 등이 있습니다. </Conceptcheck>
            <main>
                <Carousel items={carouselItems} />

                <BlueBtn to="/prediction">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}