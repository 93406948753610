/**
 * @filePath        : src\components\pages\unsupervised\news\news2\Grid.js
 * @fileName        : Grid.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 모델의 키워드 선택 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";

export default function Grid({ onItemSelected, step, onStateChange }) {
    const GridItem = ({ text, onClick, isChecked }) => {
        return (
            <div className={`news2-grid-item ${isChecked ? "checked" : ""}`} onClick={() => onClick(text)}>
                <p>{text}</p>
            </div>
        );
    };

    const [selectedItems, setSelectedItems] = useState([]);

    const handleItemClick = (text, index) => {
        if (step === 2) {
            onStateChange(step - 1);
        }
        //단어 최대 4개까지 선택 가능
        if (selectedItems.some((item) => item.text === text)) {
            setSelectedItems(selectedItems.filter((item) => item.text !== text));
        } else if (selectedItems.length < 4) {
            setSelectedItems([...selectedItems, { text, index }]);
        } else {
            const newSelectedItems = [...selectedItems];
            newSelectedItems.shift();
            setSelectedItems([...newSelectedItems, { text, index }]);
        }
    };

    useEffect(() => {
        onItemSelected(selectedItems);
    }, [selectedItems, onItemSelected]);

    return (
        <>
            <div className="news2-grid">
                <GridItem text="서울" onClick={() => handleItemClick("서울", 0)} isChecked={selectedItems.some((item) => item.text === "서울")} />
                <GridItem text="맛집" onClick={() => handleItemClick("맛집", 1)} isChecked={selectedItems.some((item) => item.text === "맛집")} />
                <GridItem text="날씨" onClick={() => handleItemClick("날씨", 2)} isChecked={selectedItems.some((item) => item.text === "날씨")} />
                <GridItem text="가격" onClick={() => handleItemClick("가격", 3)} isChecked={selectedItems.some((item) => item.text === "가격")} />
                <GridItem text="학업" onClick={() => handleItemClick("학업", 4)} isChecked={selectedItems.some((item) => item.text === "학업")} />
                <GridItem text="영화" onClick={() => handleItemClick("영화", 5)} isChecked={selectedItems.some((item) => item.text === "영화")} />
                <GridItem text="교통" onClick={() => handleItemClick("교통", 6)} isChecked={selectedItems.some((item) => item.text === "교통")} />
                <GridItem text="이번 달" onClick={() => handleItemClick("이번 달", 7)} isChecked={selectedItems.some((item) => item.text === "이번 달")} />
                <GridItem text="지난 달" onClick={() => handleItemClick("지난 달", 8)} isChecked={selectedItems.some((item) => item.text === "지난 달")} />
            </div>
        </>
    );
}
