/**
 * @filePath        : src\components\pages\supervised\object\object3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Result from "components/pages/supervised/object/object3/Result";
import Step from "components/sections/boxs/Step";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">
                사진에서 서로 다른 대상 찾아내기 (객체 검출)
            </Header>
            <HeaderNav to1="/objectconcept" to2="/object" />
            <main>
                <div className="main-step-img">
                    <Step
                        step1="객체 라벨링"
                        state1={1}
                        step2="모델 이해"
                        state2={1}
                        step3="결과 확인"
                        state3={0}
                    >
                        앞에서 라벨링한 데이터로 만들어진 객체 검출 모델에
                        새로운 이미지를 넣어 객체 검출을 진행해보았습니다.
                        <br />
                        각각의 사진에서 사람, 가방, 책 및 노트북 검출이 잘
                        이루어지는지 사진을 클릭해서 알아보세요.
                    </Step>
                </div>

                <div>
                    <Result />
                </div>
            </main>
        </>
    );
}
