/**
 * @filePath        : src\components\pages\supervised\genderage\genderageintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import objectlabeling from "assets/supervised/genderage/checkdata.png";
import understandmodel from "assets/supervised/genderage/learningmodel.png";
import learningmodel2 from "assets/supervised/genderage/learningmodel2.png";
import experiencemodel from "assets/supervised/genderage/checkresult.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">성별 나이 분류하기 (이미지 분류모델)</Header>
            <HeaderNav to1="/genderageconcept" to2="/genderage" />
            <Conceptcheck>성별이나 나이를 나타내는 다양한 이미지 데이터를 분석하여 성별 나이를 분류하여 봅시다.</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            이미지 분류 모델은 대량의 이미지 데이터를 이용해 사전에 학습한 이미지 딥러닝 모델을 사용하여 사진 속 인물의 성별이나 나이를 맞출 수
                            있습니다.
                        </p>
                        <p>본 프로그램을 활용하여 유명인사의 사진을 보고 성별이나 나이를 예측하는 모델을 체험할 수 있습니다.</p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/05_성별 나이 분류하기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={objectlabeling}>
                            데이터 확인
                        </Card>
                        <Card btn="Step 2" img={understandmodel}>
                            모델 선택
                        </Card>
                        <Card btn="Step 3" img={learningmodel2}>
                            모델 평가
                        </Card>
                        <Card btn="Step 4" img={experiencemodel}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/genderage/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
