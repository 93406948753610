/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 모델 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import ganmodel_desc from "assets/unsupervised/masterpiece/ganmodel_desc.png";
import TableInput from "components/pages/unsupervised/masterpiece/masterpiece3/TableInput";
import Step from "components/sections/boxs/Step";

export default function Main() {

    return (
        <>
            <Header learning="비지도학습">사진 명화풍 만들기 (GAN 모델)</Header>
            <HeaderNav to1="/masterpiececoncept" to2="/masterpiece" />
            <main>
                <div className="main-step-img">
                    <Step step1="화가 선택" state1={1} step2="사진 선택" state2={1} step3="모델 확인" state3={0} step4="결과 확인" state4={2}>
                        딥러닝 학습에 사용한 모델입니다. 파라미터를 조정하여 내 데이터에 맞는 최적의 모델을 만들 수 있습니다.
                    </Step>
                </div>

                <div className="white-back">
                    <h2 style={{ textAlign: "left", margin: "1rem 2rem 0rem 2rem" }}>딥러닝 모델, 파라미터 확인</h2>

                    <div className="masterpiece3-parameter-space">
                        <div className="masterpiece3-parameter-space-left">
                            <img src={ganmodel_desc} alt="parameter img" />
                        </div>

                        <div className="masterpiece3-parameter-space-right">
                            <TableInput />
                        </div>
                    </div>

                </div>

                <div className="main-btn">
                    <GreyBtn to="/masterpiece/2"></GreyBtn>
                    <BlueBtn to="/masterpiece/4">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}