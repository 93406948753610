/**
 * @filePath        : src\components\sections\cards\Card.js
 * @fileName        : Card.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 시작 화면 스텝 카드 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function Card({btn, children, img}) {
    return (
        <div className="card">
            <button style={{cursor:"default"}}><h5>{btn}</h5></button>
            <p>{children}</p>
            <img
                src={img}
                alt="img"
            ></img>
        </div>
    );
}