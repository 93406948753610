/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity2\Table.js
 * @fileName        : Table.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 군집 결과 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function Table(props) {
    const { receiveData } = props;

    return (
        <>
            <div className="celebrity2-table">
            <h3><b>•</b>군집화 결과</h3>
                <div className="celebrity2-table-space">
                    <div className="celebrity2-table-container">
                        <table className="celebrity2-table">
                            <tbody>
                                {receiveData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{textAlign:"left"}}><b style={{color:"orange", fontSize:"0.8rem", margin:"0% 4%"}}>•</b>군집 {item.LABEL}</td>
                                        <td> <h3 style={{margin:"0", textAlign:"right"}}>{item.CNT.toLocaleString()} 명</h3></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
