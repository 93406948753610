/**
 * @filePath        : src\components\pages\reinforcement\cartpole\cartpole3\Learningmodel.js
 * @fileName        : Learningmodel.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 막대 중심 잡기 모델의 학습 결과 영상 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";

export default function Learningmodel({ try1, try2, try3, src1, src2, src3, onChange }) {
    const [selectedSrc, setSelectedSrc] = useState(null);
    const [state1, setState1] = useState(false);
    const [state2, setState2] = useState(false);
    const [state3, setState3] = useState(false);
    const [showtext, setShowText] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    //렌더링 되었을때 첫번째 학습모델 자동으로 실행
    // const [selectedSrc, setSelectedSrc] = useState(src1);
    // const [trynum, setTrynum] = useState(try1);

    const handleBtnClick = (src) => {
        if (src === src1) {
            setSelectedSrc(src1);
            setShowText(false);
        }
        else if (src === src2) {
            setSelectedSrc(src2);
            setShowText(true);
        }
        else {
            setSelectedSrc(src3);
            setShowText(true);
        }
    };
    // 비디오 재생이 끝났을 때 실행할 액션
    const handleVideoEnded = () => {
        if (selectedSrc === src1) {
            setState1(true);
            setShowText(false);
        }
        else if (selectedSrc === src2) {
            setState2(true);
            setShowText(false);
        }
        else if (selectedSrc === src3) {
            setState3(true);
            setShowText(false);
        }
    };

    //3개의 결과 영상 시청 후, 다음화면으로 넘어갈 수 있는 flag 를 true로 변경
    if (state1 && state2 && state3) {
        onChange(true);
    }

    //깜빡임 효과
    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prevIsVisible) => !prevIsVisible);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <>
            <div className="white-back">
                <div style={{ height: "3rem" }}>
                    <h2>학습 결과 확인</h2>
                </div>

                <div className="cartpole3-main">

                    <div className="cartpole-learningmodel">
                        {showtext && <p style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s', }}>4배속 영상입니다⏩</p>}
                        <video key={selectedSrc}
                            muted
                            autoPlay
                            height="100%"
                            onEnded={handleVideoEnded}
                            className="cartpole-learningmodel-simulation">
                            <source src={selectedSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="cartpole-learningmodel-btn-group">
                        <h4 style={{ textAlign: "left", margin: "0" }}>⬇️ 버튼을 클릭해 학습 결과를 확인하세요 !</h4>
                        <div className="cartpole-learningmodel-btn-group-item">
                            <div className={`cartpole-learningmodel-btn ${selectedSrc === src1 ? 'cartpole-button-selected' : ''}`}
                                onClick={() => handleBtnClick(src1)}>{try1}</div>
                            <div className="cartpole3-h4">
                                {state1 && <>
                                    <h4>3.0 초 유지 🙌</h4>
                                </>}
                            </div>
                        </div>

                        <div className="cartpole-learningmodel-btn-group-item">
                            <div className={`cartpole-learningmodel-btn ${selectedSrc === src2 ? 'cartpole-button-selected' : ''}`}
                                onClick={() => handleBtnClick(src2)}>{try2}</div>
                            <div className="cartpole3-h4">
                                {state2 && <>
                                    <h4>37.9 초 유지 🙌</h4>
                                </>}
                            </div>
                        </div>

                        <div className="cartpole-learningmodel-btn-group-item">
                            <div className={`cartpole-learningmodel-btn ${selectedSrc === src3 ? 'cartpole-button-selected' : ''}`}
                                onClick={() => handleBtnClick(src3)}>{try3}</div>
                            <div className="cartpole3-h4">
                                {state3 && <>
                                    <h4>56.8 초 유지 🙌</h4>
                                </>}
                            </div>
                        </div>

                        {(state1 && state2 && state3) &&
                            <p style={{ fontWeight: "500", fontSize: "1.2rem", margin: "0" }}>⭐강화 학습의 원리와 같이, 학습 횟수가 늘어남에 따라 게임의 성능이 향상되는 것을 알 수 있습니다⭐</p>}

                    </div>
                </div>
            </div>


        </>
    );
}