/**
 * @filePath        : src\components\pages\supervised\prediction\prediction3\Table.js
 * @fileName        : Table.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 결과 데이터 테이블 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/CustomProgressBar";

export default function Table(props) {
    const { sendTrainLoss, sendValidationLoss, sendYMax } = props;

    const [list, setList] = useState([]);
    const [listcount, setListcount] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태값

    useEffect(() => {
        const params = { ALG_ID: "A1000", MODEL_ID: "M100" };
        axios
            .get(`${URL}/api/concept/prediction/result`, { params })
            .then((result) => {
                setData(result.data.list);
                setListcount(result.data.listcount);

                const trainLoss = JSON.parse(result.data.modelresult.MODEL_EVAL_RESULT).train_loss;
                const validationLoss = JSON.parse(result.data.modelresult.MODEL_EVAL_RESULT).validation_loss;
                let yMax = 0;
                for (const key in trainLoss) {
                    if (trainLoss.hasOwnProperty(key)) {
                        if (yMax < trainLoss[key]) {
                            yMax = trainLoss[key];
                        }
                    }
                }
                for (const key in validationLoss) {
                    if (validationLoss.hasOwnProperty(key)) {
                        if (yMax < validationLoss[key]) {
                            yMax = validationLoss[key];
                        }
                    }
                }

                sendTrainLoss(trainLoss);
                sendValidationLoss(validationLoss);
                sendYMax(yMax);
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (listcount > 0) {
            data.sort(function (a, b) {
                if (a.날짜 === b.날짜) {
                    return 0;
                }
                return a.날짜 > b.날짜 ? 1 : -1;
            })
        }
        setList(data);
    }, [data, listcount]);

    return (
        <>
            {loading && <ProgressBar text1="학습 및 추론중입니다." text2="잠시만 기다려 주세요." />} {/* loading 값이 true일 때만 프로그래스바 컴포넌트 표시 */}
            <p style={{ textAlign: "left", marginLeft: "3rem" }}>
                전체 : <b style={{ color: "orange" }}>{listcount}</b> 건
            </p>
            <div className="prediction1-table-space">
                <div className="prediction1-table-container">
                    <table className="prediction1-table">
                        <thead>
                            <tr>
                                <th>날짜</th>
                                <th>계절 </th>
                                <th>연도</th>
                                <th>월</th>
                                <th>시간</th>
                                <th>공휴일 여부</th>
                                <th>요일</th>
                                <th>평일여부</th>
                                <th>날씨</th>
                                <th>온도</th>
                                <th>체감온도</th>
                                <th>습도</th>
                                <th>풍속</th>
                                <th>실젯값</th>
                                <th>예측값</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.날짜}</td>
                                    <td>{item.계절}</td>
                                    <td>{item.연도}</td>
                                    <td>{item.월}</td>
                                    <td>{item.시간}</td>
                                    <td>{item.공휴일여부}</td>
                                    <td>{item.요일}</td>
                                    <td>{item.평일여부}</td>
                                    <td>{item.날씨}</td>
                                    <td>{item.온도}</td>
                                    <td>{item.체감온도}</td>
                                    <td>{item.습도}</td>
                                    <td>{item.풍속}</td>
                                    <td>{item.실제값}</td>
                                    <td>{item.예측값}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
