/**
 * @filePath        : src\components\HeaderNav.js
 * @fileName        : HeaderNav.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 각 페이지 상단 개념학습, 체험하기 헤더 네비게이션 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { NavLink } from 'react-router-dom';


export default function HeaderNav({ to1, to2 }) {
    return (
        <div className='header-nav'>
            <div className='header-nav-item'>
                <NavLink to={to1} className='header-nav-item-in'>
                    {window.location.pathname === to1 ? (
                        <img src={require('assets/headernav-to1on.png')} alt="img"></img>
                    ) :
                        <img src={require('assets/headernav-to1.png')} alt="img"></img>
                    }
                    <p>개념학습</p>
                </NavLink>
            </div>

            <div className='header-nav-item'>
                <NavLink to={to2} className='header-nav-item-in'>
                    {window.location.pathname === to2 ? (
                        <img src={require('assets/headernav-to2on.png')} alt="img"></img>
                    ) :
                        <img src={require('assets/headernav-to2.png')} alt="img"></img>
                    }
                    <p>체험하기</p>
                </NavLink>
            </div>
            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", flexGrow: "1" }}>
                <div style={{ width: "100%", height: "2px", backgroundColor: "#e9ebec" }}></div>
                <img className="header-nav-img" src={require('assets//headernav-img.png')} alt="img"></img>
            </div>

        </div>

    );
}
