/**
 * @filePath        : src\components\sections\boxs\CustomProgressBar.js
 * @fileName        : CustomProgressBar.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 텍스트 수정 가능한 프로그래스 바 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { BeatLoader } from 'react-spinners';

export default function CustomProgressBar({text1, text2 }) {
    return (
        <>
            <div className="progressbar-back">
                <div className="progressbar">
                    <BeatLoader color="#00479F" speed={10} />
                    <h3 style={{margin:"10% 0% 5% 0%"}}>{text1}</h3>
                    <p style={{margin:"0"}}>{text2}</p>
                </div>
            </div>
        </>
    );
}