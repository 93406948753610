/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasonsintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import checkdata from "assets/unsupervised/seasons/checkdata.png";
import checkmodel from "assets/unsupervised/seasons/checkmodel.png";
import checkresult from "assets/unsupervised/seasons/checkresult.png";
import experience from "assets/unsupervised/seasons/experience.png";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="비지도학습">사계절 분류하기 (군집화 모델)</Header>
            <HeaderNav to1="/seasonsconcept" to2="/seasons" />
            <Conceptcheck>클러스터링을 이용하여 사계절을 나누어 보자</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            클러스터링은 데이터의 유사성에 기반하여 그룹을 형성하며, 과학, 비즈니스, 의료 등 다양한 분야에서 패턴 분석, 고객 분류, 의사 결정
                            등에 활용되는 데이터 분석 기법입니다.
                        </p>
                        <p>
                            본 프로그램에서는 클러스터링을 활용하여 사계절을 나누는 간편하고 효과적인 방법을 제시합니다. 데이터셋으로는 연간 날씨 데이터를
                            활용하여 각 날짜를 기반으로 클러스터링을 수행하고, 이를 통해 봄, 여름, 가을, 겨울로 나누어볼 것입니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/2차/03_사계절 분류하기_사용자 안내서_v1.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={checkdata}>
                            데이터 확인
                        </Card>
                        <Card btn="Step 2" img={checkmodel}>
                            모델 이해
                        </Card>
                        <Card btn="Step 3" img={checkresult}>
                            결과 확인
                        </Card>
                        <Card btn="Step 4" img={experience}>
                            평가하기
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/seasons/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
