/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch2\Talk.js
 * @fileName        : Talk.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 단어 맞추기 모델의 케듀 질의응답 대화 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import React, { useState, useEffect } from "react";
import { getCateId } from "components/pages/supervised/wordmatch/wordmatch1/WordmatchData";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/CustomProgressBar";
import keduIco from "assets/supervised/wordmatch/keduIco.png";

export default function Talk(props) {
    const { sendDatasetId } = props;

    const [list, setList] = useState([]);
    const [nextList, setNextList] = useState([]);

    const [talkList, setTalkList] = useState([]);

    const [loading, setLoading] = useState(true); // 로딩 상태값

    useEffect(() => {
        const params = { MODEL_ID: getCateId() };
        axios
            .get(`${URL}/api/concept/wordmatch/result`, { params })
            .then((result) => {
                setList(result.data.list);
                setNextList(result.data.list);
            })
            .catch((error) => {
                console.error("error:", error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    }, []);

    useEffect(() => {
        if (list.length > 0) {
            const tempList = [];
            tempList.push({ QSTN: "", ANSR: "" });
            setTalkList(tempList);

            setTimeout(function () {
                tempList[0].QSTN = list[0]["QSTN_1"];
                setTalkList([...tempList]);
            }, 1500);
        }
        // eslint-disable-next-line
    }, [list]);

    useEffect(() => {
        const nowCol = talkList.length;
        const nextCol = nowCol + 1;

        if (nextList.length > 1) {
            const tempList = [...talkList];
            tempList.push({ QSTN: "", ANSR: "" });
            setTalkList(tempList);

            setTimeout(function () {
                tempList[nowCol]["QSTN"] = nextList[0]["QSTN_" + nextCol];
                setTalkList([...tempList]);
            }, 1000);
        } else {
            setLoading(true);

            setTimeout(function () {
                setLoading(false);

                if (nextList[0]) sendDatasetId(nextList[0].DATASET_ID);
            }, 1000);
        }
        // eslint-disable-next-line
    }, [nextList]);

    const putTalkList = (data) => {
        const nowCol = data.length;
        if (nowCol > 10) return;

        const nowTalk = data[nowCol - 1];
        const list = nextList.filter((x) => x["QSTN_" + nowCol] === nowTalk.QSTN && x["ANSR_" + nowCol] === nowTalk.ANSR);
        setNextList(list);
    };

    const answer = (key, value, index) => {
        const tempList = [...talkList];
        tempList[index][key] = value;
        setTalkList(tempList);

        putTalkList(tempList);
    };

    return (
        <>
            {loading && <ProgressBar text1="추론중입니다." text2="잠시만 기다려 주세요." />} {/* loading 값이 true일 때만 프로그래스바 컴포넌트 표시 */}
            <div className="conSection">
                <div className="testbotChatView blue-lightblue-theme blue-light">
                    <div className="dialog-body" id="dialog">
                        {talkList.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="adamTalk">
                                    <div className="profile_image">
                                        <img src={keduIco} alt="keduIco" />
                                    </div>
                                    <div className="messege-box">
                                        <div className="messege">
                                            {!item.QSTN ? (
                                                <div className="loadingMsg">
                                                    <i> • • • </i>
                                                    <i></i>
                                                    <i></i>
                                                    <i></i>
                                                    <i></i>
                                                </div>
                                            ) : null}
                                            {item.QSTN ? (
                                                <p>{item.QSTN}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {item.QSTN ? (
                                    <div className="myTalk">
                                        <div className="message-box">
                                            <div className="message-box-messege">
                                                {!item.ANSR ? (
                                                    <div className="message-box-inside">
                                                        <div className="message-box-inside-btn" onClick={() => answer("ANSR", "예", index)}>
                                                            예
                                                        </div>
                                                        <div className="message-box-inside-btn" onClick={() => answer("ANSR", "아니오", index)}>
                                                            아니오
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {item.ANSR ? <div className="mText">{item.ANSR}</div> : null}
                                        </div>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
