/**
 * @filePath        : src\components\pages\unsupervised\news\news1\Preprocessing.js
 * @fileName        : Preprocessing.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 전처리 로직 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import newspaper from "assets/unsupervised/news/newspaper.png";
import arrow from "assets/unsupervised/news/arrow.png";
import { useState, useEffect } from "react";

// Main에 대한 자식속성 -> 메인에서 동작시 여기로옴
export default function Preprocessing({ onCategoryChange, validtext, errortext, onCheckFlagChange }) {
    const [selectedCategory, setSelectedCategory] = useState("default");
    const [inputtext, setInputtext] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const category = [
        { value: "default", label: "선택" },
        { value: "student", label: "학생" },
        { value: "robot", label: "옛날 로봇" },
        { value: "king", label: "임금님" },
        { value: "halbae", label: "할아버지" },
    ];

    useEffect(() => {
        onCategoryChange(selectedCategory); // useEffect시 이 부분이 값을 세팅해오는건지 -> 자식요소라 나중에 호출되는건가
    }, [onCategoryChange, selectedCategory, inputtext]);

    // 카테고리 선택시 실행
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value); // 카테고리 값 변경됨
        onCategoryChange(event.target.value); // onCategoryChange값 넘겨줌? 세팅됨?
        setInputtext("");
    };

    // 고쳐본 문장(전처리 후) 부분 썼을 경우
    const handleTextDataChange = (event) => {
        const newTextData = event.target.value;
        setInputtext(newTextData);
    };

    const handleCheckFlag = () => {
        if(selectedCategory==="default"){
            setModalMessage("카테고리를 선택해주세요 !");
            setShowModal(true);

        }
        else if(inputtext.trim()===""){
            setModalMessage("오타를 정정한 문장을 입력해주세요 !");
            setShowModal(true);
        }
        else{
            onCheckFlagChange(true);
            return;
        }
    }
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="news1-preprocessing">
                <div className="news1-newspaper-space">
                    <img src={newspaper} alt="newspaper" />
                    <div className="news1-newspaper-inside">
                        <div className="news1-newspaper-categoryselect">
                            <p style={{ margin: "0%", color: "#555555" }}>카테고리 :</p>
                            <select value={selectedCategory} onChange={handleCategoryChange}>
                                {category.map((category) => (
                                    <option key={category.value} value={category.value}>
                                        {category.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="news1-newspaper-inside-left">
                            <h4
                                style={{
                                    margin: "0% 0% 1% 0%",
                                    color: "black",
                                }}
                            >
                                수집한 기존 문장 (전처리 전)
                            </h4>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "80%",
                                    fontStyle: "italic",
                                    width: "95%",
                                }}
                            >
                                <p style={{ fontSize: "1.1rem" }}>{errortext}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={arrow} alt="arrow" />
                <div className="news1-newspaper-space">
                    <img src={newspaper} alt="newspaper" />
                    <div className="news1-newspaper-inside">
                        <div className="news1-newspaper-inside-right">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4 style={{ margin: "0% 0% 1% 0%" }}>내가 고쳐본 문장 (전처리 후)</h4>
                            </div>

                            <textarea
                                className="news1-textfield"
                                placeholder="오타를 정정한 문장을 입력하세요..."
                                value={inputtext}
                                onChange={handleTextDataChange}
                            />
                            {validtext.length > 0 && (
                                <>
                                    <h4
                                        style={{
                                            margin: "0",
                                            textAlign: "left",
                                        }}
                                    >
                                        변경된 예시 문장
                                    </h4>
                                    <textarea className="news1-textfield" value={validtext} style={{ color: "red" }} onChange={handleTextDataChange} />
                                </>
                            )}
                            <div style={{ display: "flex", justifyContent: "right", marginTop: "0.5rem" }}>
                                <div
                                    style={{ backgroundColor: validtext.length > 0 ? "#737373" : "#22194c" }}
                                    className="news1-checkresultbtn"
                                    onClick={handleCheckFlag}>
                                    결과 확인
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}>
                                <b>닫기</b>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}