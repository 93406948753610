/**
 * @filePath        : src\components\pages\supervised\object\object3\ResultItem.js
 * @fileName        : ResultItem.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 결과 확인 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import { useEffect, useState } from "react";

export default function ResultItem({ result, person, bag, book, computer, final,onChange }) {
    const [clickimg, setClickImg] = useState(result);

    useEffect(() => {
        setClickImg(result);
      }, [result]);

    const handleButtonClick = (value) => {
        setClickImg(value);
    };
    const handleState = () => {
        onChange(0);
      };

    return (
        <>
            <div className="object3-result">
                <div style={{width:"80%"}}>
                    <img src={clickimg} alt="result" />
                </div>

                <div className="object3-result-btn-group" style={{width:"20%"}}>
                    <div className={`object3-result-btn ${clickimg === result ? 'object3-selected' : ''}`}
                    onClick={() => handleButtonClick(result)}>원본 이미지</div>
                    <div className={`object3-result-btn ${clickimg === person ? 'object3-selected' : ''}`}
                    onClick={() => handleButtonClick(person)}>사람 검출 모델</div>
                    <div className={`object3-result-btn ${clickimg === bag ? 'object3-selected' : ''}`}
                    onClick={() => handleButtonClick(bag)}>가방 검출 모델</div>
                    <div className={`object3-result-btn ${clickimg === book ? 'object3-selected' : ''}`}
                    onClick={() => handleButtonClick(book)}>책 검출 모델</div>
                    <div className={`object3-result-btn ${clickimg === computer ? 'object3-selected' : ''}`}
                    onClick={() => handleButtonClick(computer)}>노트북 검출 모델</div>
                    <div className={`object3-result-btn ${clickimg === final ? 'object3-selected' : ''}`}
                    onClick={() => handleButtonClick(final)}>최종 검출 모델</div>
                </div>
            </div>
            <BlueBtn onClick={handleState}>체험 완료</BlueBtn>
        </>
    );
}