/**
 * @filePath        : src\components\pages\reinforcement\selfdriving\selfdriving1\Playgame.js
 * @fileName        : Playgame.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 자율 주행 자동차 만들기 모델의 게임 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useRef, useState, useEffect } from 'react';
import arrow_up from "assets/reinforcement/arctic/arrow_up.png";
import arrow_down from "assets/reinforcement/arctic/arrow_down.png";
import arrow_left from "assets/reinforcement/arctic/arrow_left.png";
import arrow_right from "assets/reinforcement/arctic/arrow_right.png";

export default function Playgame({ onChange }) {
    const [trys, setTrys] = useState([]);
    // const [distances, setDistances] = useState([]);
    const iframeRef = useRef(null);

    const handleScore = (newScoreValue) => {
        setTrys(prevTrys => [...prevTrys, newScoreValue]);
    };

    //html 파일에서 last_lap_time_value 값 변화 감지해서 가져오기 
    const handleIframeLoad = () => {
        const iframeContent = iframeRef.current.contentDocument;
        const targetElement = iframeContent.querySelector('#last_lap_time_value');
        const gameCount = iframeContent.querySelector('#game_count');

        if (targetElement) {
            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.type === 'childList') {
                        handleScore(targetElement.textContent);
                    }
                });
            });
            observer.observe(gameCount, { childList: true });
        }
    };
    //게임 5번 후, 다음화면으로 넘어갈 수 있는 flag 를 true로 변경
    useEffect(() => {
        if (trys.length >= 5) {
            onChange(true);
        }
    }, [trys, onChange]);
    // 컴포넌트가 마운트될 때 iframe에 포커스를 설정
    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.focus();
        }
    }, []);

    return (
        <>
            <div className='white-back'>
                <div className="selfdriving-playgame">
                    <div className="selfdriving-playgame-left">
                        <iframe
                            src='/racing.html'
                            title="game"
                            style={{ width: "100%", height: "30rem", border: "none" }}
                            ref={iframeRef}
                            onLoad={() => handleIframeLoad()}
                            tabIndex={0}
                        />
                        <div>
                            <div />
                            <img src={arrow_up} alt="up" />
                            <div />
                            <img src={arrow_left} alt="left" />
                            <img src={arrow_down} alt="down" />
                            <img src={arrow_right} alt="right" />
                        </div>

                    </div>

                    <div className="selfdriving-playgame-right">
                        {trys[4] &&
                            <div className="selfdriving-playgame-desc">
                                <p>
                                    게임을 모두 마쳤습니다.<br />
                                    마지막 게임에서는 자동차가 처음보다<br />
                                    <b style={{ color: "#513db3" }}>
                                        {trys[4] - trys[0] >= 0
                                            ? ` ${(trys[4] - trys[0]).toFixed(1)} `
                                            : ` ${(trys[0] - trys[4]).toFixed(1)} `}
                                    </b>
                                    {trys[4] - trys[0] >= 0
                                        ? `초 더 오래 주행했어요 👏`
                                        : `초 만큼 덜 주행했어요 😥`}
                                </p>
                            </div>
                        }

                        <p style={{ display: "flex", alignItems: "center", color: "#503cb2", fontSize: "20px" }}><img src={require("assets/reinforcement/cartpole/clock.png")} alt="clock" /> <b>ㅤ플레이 한 시간</b></p>
                        <div className="selfdriving-record">
                            <div className="selfdriving-record-div">
                                <p>1번째 게임  </p>{trys[0] && <b>{trys[0]} 초</b>}
                            </div>
                            <div className="selfdriving-record-div">
                                <p>2번째 게임  </p>{trys[1] && <b>{trys[1]} 초</b>}
                            </div>
                            <div className="selfdriving-record-div">
                                <p>3번째 게임  </p>{trys[2] && <b>{trys[2]} 초</b>}
                            </div>
                            <div className="selfdriving-record-div">
                                <p>4번째 게임  </p>{trys[3] && <b>{trys[3]} 초</b>}
                            </div>
                            <div className="selfdriving-record-div">
                                <p>5번째 게임  </p>{trys[4] && <b>{trys[4]} 초</b>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}