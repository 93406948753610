/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons3\Table.js
 * @fileName        : Table.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 결과 데이터 테이블 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function Table() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        axios
            .get(`${URL}/api/unsupervised/seasons/weatherdata`)
            .then((result) => {
                setData(result.data.data);
            })
            .catch((error) => {
                console.error('error:', error);
            })
            .finally(() => {
                setLoading(false); 
            });
    }, []);

    return (
        <>
            {loading && <ProgressBar />}
            <div className="seasons1-table-space">
                <div className="seasons1-table-container">
                    <table className="seasons1-table">
                        <thead>
                            <tr>
                                <th>날짜</th>
                                <th>평균 기온 (°C) </th>
                                <th>강수량 (mm)</th>
                                <th>습도 (%)</th>
                                <th style={{backgroundColor:"#503cb2"}}>클러스터링 결과</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.날짜}</td>
                                    <td>{data.평균기온}</td>
                                    <td>{data.강수량}</td>
                                    <td>{data.습도}</td>
                                    <td>{data.ClusterName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}