/**
 * @filePath        : src\components\pages\supervised\wordmatch\wordmatch1\Selectpicture.js
 * @fileName        : Selectpicture.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 단어 맞추기 모델 선택된 카테고리에 따른 단어 이미지 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import { getCateId } from "components/pages/supervised/wordmatch/wordmatch1/WordmatchData";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function Selectpicture({ onChange }) {
    const [list, setList] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const params = { MODEL_ID: getCateId() };
        axios
            .get(`${URL}/api/concept/wordmatch/word`, { params })
            .then((result) => {
                setList(result.data.list);
            })
            .catch((error) => {
                console.log("error: " + error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    }, []);

    if (list === null) {
        return <ProgressBar />;
    }

    return (
        <>
            {loading && <ProgressBar />}
            <div>
                <div className="wordmatch1-list">
                    <>
                        {list.map((item, index) => (
                            <div key={index} className="wordmatch1-item">
                                <div className="masterpiece1-painter-text">
                                    <p>• {item.DATASET_ID}</p>
                                </div>
                                <img src={item.IMG_PATH} alt="img" />
                            </div>
                        ))}
                    </>
                </div>
            </div>
        </>
    );
}
