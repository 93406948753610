/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity1\Mbti.js
 * @fileName        : Mbti.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 MBTI 선택 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import { getEI, getSN, getTF, getJP, setEI, setSN, setTF, setJP } from "components/pages/unsupervised/celebrity/celebrity1/CelebrityData";

export default function Mbti({ onChange }) {
    const [selectedItem1, setSelectedItem1] = useState(getEI());
    const [selectedItem2, setSelectedItem2] = useState(getSN());
    const [selectedItem3, setSelectedItem3] = useState(getTF());
    const [selectedItem4, setSelectedItem4] = useState(getJP());

    const handleClick1 = (index) => {
        setSelectedItem1(index);
        setEI(index);
    };
    const handleClick2 = (index) => {
        setSelectedItem2(index);
        setSN(index);
    };
    const handleClick3 = (index) => {
        setSelectedItem3(index);
        setTF(index);
    };
    const handleClick4 = (index) => {
        setSelectedItem4(index);
        setJP(index);
    };



    useEffect(() => {
        if((selectedItem1&&selectedItem2&&selectedItem3&&selectedItem4)){
            onChange(true);
        }
    }, [selectedItem1, selectedItem2, selectedItem3, selectedItem4, onChange]);

    return (
        <>
            <div style={{ padding: "0% 2%" }}>
                <h3 style={{ textAlign: "left" }}><b style={{ color: "#FED800" }}>• </b>나의 MBTI 성향 중 더욱 적절한 곳에 체크해 주세요.</h3>
                <div className="mbtiArea">
                    <div className="mbtiList">
                        <div className="mbtiType">
                            <dl>
                                <dt>E</dt>
                                <dd>외향형</dd>
                            </dl>
                        </div>
                        <div className="mbtiNum">
                            <ul>
                                <li className={selectedItem1 === 1 ? 'selected' : ''} onClick={() => handleClick1(1)}>
                                    {selectedItem1 === 1 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                                <li className={selectedItem1 === 2 ? 'selected' : ''} onClick={() => handleClick1(2)}>
                                    {selectedItem1 === 2 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem1 === 3 ? 'selected' : ''} onClick={() => handleClick1(3)}>
                                    {selectedItem1 === 3 && (<div className="mbti-check">✓</div>)}
                                    <span>50%</span>
                                </li>
                                <li className={selectedItem1 === 4 ? 'selected' : ''} onClick={() => handleClick1(4)}>
                                    {selectedItem1 === 4 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem1 === 5 ? 'selected' : ''} onClick={() => handleClick1(5)}>
                                    {selectedItem1 === 5 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mbtiType">
                            <dl>
                                <dt>I</dt>
                                <dd>내향형</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="mbtiList">
                        <div className="mbtiType">
                            <dl>
                                <dt>S</dt>
                                <dd>감각형</dd>
                            </dl>
                        </div>
                        <div className="mbtiNum">
                            <ul>
                                <li className={selectedItem2 === 1 ? 'selected' : ''} onClick={() => handleClick2(1)}>
                                    {selectedItem2 === 1 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                                <li className={selectedItem2 === 2 ? 'selected' : ''} onClick={() => handleClick2(2)}>
                                    {selectedItem2 === 2 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem2 === 3 ? 'selected' : ''} onClick={() => handleClick2(3)}>
                                    {selectedItem2 === 3 && (<div className="mbti-check">✓</div>)}
                                    <span>50%</span>
                                </li>
                                <li className={selectedItem2 === 4 ? 'selected' : ''} onClick={() => handleClick2(4)}>
                                    {selectedItem2 === 4 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem2 === 5 ? 'selected' : ''} onClick={() => handleClick2(5)}>
                                    {selectedItem2 === 5 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mbtiType">
                            <dl>
                                <dt>N</dt>
                                <dd>직관형</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="mbtiList">
                        <div className="mbtiType">
                            <dl>
                                <dt>T</dt>
                                <dd>사고형</dd>
                            </dl>
                        </div>
                        <div className="mbtiNum">
                            <ul>
                                <li className={selectedItem3 === 1 ? 'selected' : ''} onClick={() => handleClick3(1)}>
                                    {selectedItem3 === 1 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                                <li className={selectedItem3 === 2 ? 'selected' : ''} onClick={() => handleClick3(2)}>
                                    {selectedItem3 === 2 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem3 === 3 ? 'selected' : ''} onClick={() => handleClick3(3)}>
                                    {selectedItem3 === 3 && (<div className="mbti-check">✓</div>)}
                                    <span>50%</span>
                                </li>
                                <li className={selectedItem3 === 4 ? 'selected' : ''} onClick={() => handleClick3(4)}>
                                    {selectedItem3 === 4 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem3 === 5 ? 'selected' : ''} onClick={() => handleClick3(5)}>
                                    {selectedItem3 === 5 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mbtiType">
                            <dl>
                                <dt>F</dt>
                                <dd>감정형</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="mbtiList">
                        <div className="mbtiType">
                            <dl>
                                <dt>J</dt>
                                <dd>판단형</dd>
                            </dl>
                        </div>
                        <div className="mbtiNum">
                            <ul>
                                <li className={selectedItem4 === 1 ? 'selected' : ''} onClick={() => handleClick4(1)}>
                                    {selectedItem4 === 1 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                                <li className={selectedItem4 === 2 ? 'selected' : ''} onClick={() => handleClick4(2)}>
                                    {selectedItem4 === 2 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem4 === 3 ? 'selected' : ''} onClick={() => handleClick4(3)}>
                                    {selectedItem4 === 3 && (<div className="mbti-check">✓</div>)}
                                    <span>50%</span>
                                </li>
                                <li className={selectedItem4 === 4 ? 'selected' : ''} onClick={() => handleClick4(4)}>
                                    {selectedItem4 === 4 && (<div className="mbti-check">✓</div>)}
                                    <span>75%</span>
                                </li>
                                <li className={selectedItem4 === 5 ? 'selected' : ''} onClick={() => handleClick4(5)}>
                                    {selectedItem4 === 5 && (<div className="mbti-check">✓</div>)}
                                    <span>100%</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mbtiType">
                            <dl>
                                <dt>P</dt>
                                <dd>인식형</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
