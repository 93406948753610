/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons4\Country.js
 * @fileName        : Country.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 나라 이미지 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function Country({ img, name, sub, classname, idname }) {
    return (
        <>
            <div className={`seasons4-country-space ${classname}`} id={`${idname}`}>
                <img src={img} alt="country img" style={{ width: "3rem" }} />
                <b>{name}</b>
                <p style={{ margin: "0",fontSize:"13px" }}>{sub}</p>
            </div>
        </>
    );
}
