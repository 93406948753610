/**
 * @filePath        : src\components\pages\TermsofUse.js
 * @fileName        : TermsofUse.js
 * @author          : GHYANG
 * @date            : 2023.11.22
 * @description     : 이용약관 페이지
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.22		 GHYANG				 1.0
 */
import backgroundimg from "assets/introduce_back.jpg";
import { useEffect } from 'react';
export default function TermsofUse() {
  useEffect(() => {
    // 페이지 이동 시 스크롤을 초기화
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ width: "100%", left: "0", position: "absolute" }}>
        <div className="introduce-header">
          <img src={backgroundimg} alt="background-img" />
          <p>EDU AI 서비스 이용약관</p>
        </div>
      </div>

      <div style={{ height: "11rem" }}></div>

      <div className="termsofuse-main-wrap">
        <div className="termsofuse-main">
          <p className="termsofuse-title">제 1 장 총 칙</p>
          <div className="termsofuse-sub-title">제 1 조 (목적)</div>
          <p>본 약관은 한국교육학술정보원(이하 “KERIS”라 함)이 제공하는 온라인 AI체험 플랫폼(EDU AI , 이하 “서비스”라 함)의 이용에 관한 조건 및 절차와 기타 필요한 사항을 규정하는 것을 목적으로 합니다.</p>

          <div className="termsofuse-sub-title">제 2 조 (용어의 정의)</div>
          <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
            ① EDU AI 이용자 : EDU AI에 이용자가입을 하여 EDU AI에서 제공하는 서비스를 정상적으로 사용할 수 있는 권한을 부여 받은 자<br />
            ② 이용자 : EDU AI에 접속하여 이 약관에 따라 통합이용자 서비스가 제공하는 KERIS와 이용계약을 체결한 자<br />
            ③ 이용계약 : 서비스를 제공받기 위하여 이 약관으로 KERIS와 이용자 간에 체결하는 계약<br />
            ④ 이용자번호(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 설정하고 회사가 승인하는 이메일 주소<br />
            ⑤ 비밀번호 : 이용자 자신의 비밀을 보호하기 위하여 이용자 자신이 설정한 문자와 숫자, 특수문자의 조합<br />
            ⑥ 이용정지 : 서비스 이용과 관련하여 EDU AI가 정하는 규정을 준수하지 않을 경우 서비스의 이용을 제한하는 것<br />
            ⑦ 강제탈퇴 : 서비스 이용과 관련하여 EDU AI가 정하는 규정을 준수하지 않을 경우 일정기간 이용정지 후 EDU AI 서비스 이용자 정보가 삭제되는 것<br />
            ⑧ 휴면이용자 : 이용자자격을 얻은 후 서비스 최종접속일로부터 1년 동안 로그인을 1회 이상 하지 않은 이용자</p>

          <div className="termsofuse-sub-title">제 3 조 (약관의 효력 발생 및 개정)</div>
          <p>① 이 약관은 서비스 메뉴에 게시하여 공시함으로써 효력을 발생합니다.<br />
            ② KERIS가 필요하다고 인정하는 경우에는 본 약관을 약관의 규제에 대한 법률 기타 관련 법령에 위배되지 않는 범위 내에서 본 약관을 변경할 수 있으며, 약관이 변경된 경우에는 지체없이 공지합니다. 다만, 이용자의 권리 또는 의무에 관한 중요한 내용의 변경은 최소한 7일 이전에 공지합니다.<br />
            ③ 이용자 변경된 약관에 동의하지 않으면, 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다. 약관의 효력발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경사항에 동의한 것으로 간주됩니다.<br />
            ④ 이용자는 정기적으로 EDU AI에 방문하여 약관의 변경사항을 확인하여야 합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 KERIS에서 책임지지 않습니다.</p>

          <div className="termsofuse-sub-title">제 4 조 (약관의 해석)</div>
          <p>본 약관에 명시되지 않은 사항은 관계 법령에 규정되어 있을 경우 그 규정에 따르며, 그렇지 않을 경우에는 KERIS가 제공하는 개별 서비스의 이용약관 및 운영정책에 따릅니다.</p>

          <div className="termsofuse-sub-title">제 5 조 (이용자에 대한 통지)</div>
          <p>① KERIS가 이용자에 대하여 개별 통지를 하는 경우, KERIS는 이용자가 KERIS에 제출한 전자우편 주소로 전달합니다.<br />
            ② KERIS가 불특정다수 이용자에 대하여 통지를 하는 경우 KERIS는 1주일 이상 “공지사항” 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.</p>

          <p className="termsofuse-title">제 2 장 서비스 이용 계약</p>
          <div className="termsofuse-sub-title">제 6 조 (서비스 이용계약의 성립)</div>
          <p>① 이용자가 되고자 하는 이용자가 이용자가입 페이지의 “이용약관에 동의합니다.” 와 “만 14세 이상입니다.” 두 개의 단추를 누르면 본 약관에 동의하는 것으로 간주됩니다.<br />
            ② KERIS와 이용자 간의 서비스 이용계약은 본 약관에 동의한 이용자의 이용신청에 대하여, KERIS가 요구하는 방법에 의해 본인확인이 완료된 이용자를 KERIS가 승낙함으로써 성립하고, 이용자는 KERIS가 승낙을 한 때로부터 이용자의 자격을 취득합니다.<br />
            ③ 서비스 이용계약은 이용자번호(ID)단위로 체결하며, 체결단위는 1인 이용자번호(ID)만 가능합니다.</p>

          <div className="termsofuse-sub-title">제 7 조 (서비스 이용 신청)</div>
          <p>① 이용자(개인 또는 단체)는 KERIS가 정한 양식에 따라 이용자정보를 모두 기입함으로써 이용자가입을 신청하여야 합니다.<br />
            ② 이용 신청자가 만 14세 미만인 자(민법상의 행위무능력자 포함)일 경우에는 이용자가입이 불가 합니다.<br />
            개인 이용자는 반드시 실명으로만 가입할 수 있으며, KERIS는 실명확인조치를 할 수 있습니다.<br />
            ③ 타인의 명의(이름 및 개인정보)를 도용하여 이용신청을 할 경우 해당 이용자의 이용자 번호(ID)는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.</p>

          <div className="termsofuse-sub-title">제 8 조 (서비스 이용신청의 승낙과 제한)</div>
          <p>① KERIS가 이용자에게 요구하는 정보에 대해 이용자 실명 및 실제 정보를 정확히 기재하여 이용신청을 한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다.<br />
            ② KERIS는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.</p>
          <p className="termsofuse-space">
            1. 이용 신청한 자가 만 14세 미만인 경우<br />
            2. 다른 사람의 명의를 사용하여 이용신청을 하였을 경우<br />
            3. 이용 신청 시 이용자정보를 허위로 기재하여 이용 신청한 경우<br />
            4. 사회의 안녕, 질서 혹은 미풍양속을 저해할 목적으로 이용신청을 하였을 경우<br />
            5. 부정한 용도로 본서비스를 이용하고자 하는 경우<br />
            6. 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우<br />
            7. 법령 또는 약관을 위반하여 이용계약이 해지된 적이 있는 이용자가 신청하는 경우<br />
            8. 법령에서 이용 신청을 거절할 수 있는 경우<br />
            9. 기타 KERIS에서 이용 승낙이 곤란하다고 판단되는 경우
          </p>
          <p> ③ KERIS는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다. </p>
          <p className="termsofuse-space">
            1. KERIS의 설비상 여유가 없는 경우<br />
            2. 이용 신청을 승낙하는 것에 기술상 문제가 있는 경우<br />
            3. 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우
          </p>

          <p className="termsofuse-title">제 3 장 계약 당사자의 의무</p>
          <div className="termsofuse-sub-title">제 9 조 (KERIS의 의무)</div>
          <p>① KERIS는 관련 법령을 준수하고, 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다.<br />
            ② KERIS는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안 시스템을 갖추어야 하며, 개인정보 처리방침을 공시하고 준수합니다. KERIS는 본 약관 및 개인정보 처리방침에서 정한 경우를 제외하고는 이용자의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.<br />
            ③ KERIS는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다 합니다.</p>

          <div className="termsofuse-sub-title">제 10 조 (이용자의 권리 및 의무)</div>
          <p>① 이용자는 서비스를 이용할 때 다음 각 호에 해당하는 행위를 하지 않아야 합니다.</p>
          <p className="termsofuse-space">1. 다른 이용자의 ID 및 암호를 부정하게 사용하는 행위<br />
            2. 서비스에서 얻은 정보를 KERIS의 사전승낙 없이 본래 이용 이외의 목적으로 복제 하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위<br />
            3. KERIS의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위<br />
            4. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위<br />
            5. 범죄와 결부된다고 객관적으로 판단되는 행위<br />
            6. 기타 관련 법령에 위배되는 행위</p>
          <p>② 이용자는 본 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.<br />
            ③ 이용자는 KERIS가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.<br />
            ④ 이용자는 본 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 이용자가 약관에 위반한 영업활동을 이용하여 발생한 결과에 대하여 KERIS는 책임을 지지 않습니다. 이용자는 이와 같은 영업활동으로 인하여 KERIS에 손해가 발생한 경우에는 KERIS에 대하여 그 손해를 배상하여야 합니다. 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>

          <p className="termsofuse-title">제 4 장 서비스의 이용</p>
          <div className="termsofuse-sub-title">제 11 조 (서비스 이용 시간)</div>
          <p>① 서비스의 이용 시간은 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br />
            ② 서비스 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있으며, 이 경우 사전에 공지합니다. 다만, 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br />
            ③ 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 사전에 공지한 바에 따릅니다.<br />
            ④ KERIS가 정기 점검 기타 서비스를 제공하지 못할 만한 합리적인 이유가 있는 경우에는 서비스의 제공을 중단할 수 있습니다.</p>

          <div className="termsofuse-sub-title">제 12 조 (로그인 정보 관리에 대한 의무)</div>
          <p>① 이용자번호(ID) 및 비밀번호에 대한 모든 관리책임은 이용자에게 있습니다.<br />
            ② 이용자는 명백한 사유가 있는 경우를 제외하고는 이용자가 이용자번호(ID)를 공유, 양도 또는 변경할 수 없습니다.<br />
            ③ 이용자에게 부여된 이용자번호(ID)에 의하여 발생되는 서비스 이용 상 과실 또는 제3자에 의한 부정사용 등에 대한 모든 책임은 이용자에게 있습니다.<br />
            ④ 이용자는 이용자번호(ID) 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 재단에 통지하고 재단의 안내에 따라야 합니다.<br />
            ⑤ 제3항의 경우에 해당 이용자가 KERIS에 그 사실을 통지하지 않거나, 통지한 경우에도 KERIS의 안내에 따르지 않아 발생한 불이익에 대하여 KERIS는 책임지지 않습니다.</p>

          <div className="termsofuse-sub-title">제 13 조 (서비스 이용계약의 해지 및 제한)</div>
          <p>① 이용자가 서비스 이용계약을 해지하고자 하는 때에는 온라인으로 KERIS에 해지신청을 하고, 신청 후 즉시 모든 이용자정보를 삭제합니다. 단, 제13조 제3항에 해당되어 강제 탈퇴된 경우에는 이용자 번호(ID)를 별도 관리합니다.<br />
            ② KERIS는 이용자가 이용자자격을 얻은 후 최종 접속일로부터 1년 이내에 로그인을 한 번도 하지 않은 경우 휴면이용자로 전환이 됩니다. 이용자가 휴면이용자 전환 대상자가 되는 경우, 휴면이용자 전환일로부터 1주일 전에 전자우편을 발송합니다. 휴면이용자로 전환되어 1년 이상 휴면 상태가 유지될 경우 이용자자격을 상실할 수 있습니다.<br />
            ③ KERIS는 이용자가 다음 각 호에 해당하는 경우 서비스의 이용을 즉시 정지하고 일정기간 이후 이용자 강제탈퇴 시킬 수 있으며 적법 조치를 포함한 제재를 가할 수 있습니다.</p>
          <p className="termsofuse-space">1. 타인의 이용자번호(ID), 비밀번호, 주민등록번호를 도용한 경우<br />
            2. KERIS의 운영진, 직원 또는 관계자를 사칭하는 경우<br />
            3. 서비스에 위해를 가하거나 고의로 방해하는 경우<br />
            4. 타인의 명예나 프라이버시를 침해할 수 있는 내용을 게시 또는 수신자의 의사에 반하는 광고성 정보, 전자우편을 전송하는 경우<br />
            5. "저작권법"을 위반한 불법프로그램의 제공 및 운영방해를 하는 경우<br />
            6. 선거관리위원회의 유권해석 상의 불법선거운동을 하는 경우<br />
            7. 서비스를 이용하여 얻은 정보를 KERIS의 동의 없이 상업적으로 이용하는 경우<br />
            8. "정보통신망 이용촉진 및 정보보호 등에 관한 법률"을 위반한 불법통신 및 해킹, 악성프로그램 배포, 접속권한 초과행위등을 하는 경우<br />
            9. 기타 KERIS에서 부적당하다고 판단한 경우</p>
          <p>④ 전항의 규정에 의하여 이용자의 이용을 제한하는 경우와 제한의 종류 및 기간 등 구체적인 기준은 KERIS의 공지, 서비스 이용안내 등에서 별도로 정한 바에 의합니다.</p>

          <div className="termsofuse-sub-title">제 14 조 (게시물의 관리)</div>
          <p>① KERIS는 서비스용 설비의 용량에 여유가 없다고 판단되는 경우 필요에 따라 이용자가 게재 또는 등록한 내용물을 삭제할 수 있습니다.<br />
            ② KERIS는 서비스용 설비의 용량에 여유가 없다고 판단되는 경우 이용자의 서비스 이용을 부분적으로 제한할 수 있습니다.<br />
            ③ 제 1 항 및 제 2 항의 경우에는 당해 사항을 사전에 온라인을 통해서 공지합니다.<br />
            ④ KERIS는 이용자가 게재 또는 등록하는 서비스 내의 내용물이 다음 각 호에 해당한다고 판단되는 경우에 이용자에게 사전 통지 없이 삭제할 수 있습니다.</p>
          <p className="termsofuse-space">1. 다른 이용자 또는 제 3자를 비방하거나 명예를 손상시키는 내용인 경우<br />
            2. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음향, 동영상 등을 유포 하거나 게시물에 링크시키는 경우<br />
            3. 불법 복제 또는 해킹을 조장하는 내용인 경우<br />
            4. 영리를 목적으로 하는 광고인 경우<br />
            5. 국가적, 반사회적, 범죄적 행위와 결부된다고 판단되는 경우<br />
            6. 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 경우<br />
            7. 기타 법령에 위배된다고 판단되는 경우</p>

          <p className="termsofuse-title">제 5 장 저작권 등</p>
          <div className="termsofuse-sub-title">제 15조 (게재된 자료에 대한 권리)</div>
          <p>⑤ 서비스의 저작권(프로그램의 소스코드, 실행파일 및 UI/UX, 삽입된 이미지 등 프로그램과 관련된 모든 저작물을 포함함)은 회사에게 있으며, 이용자는 본 이용약관에 따라 부여 받은 제한된 사용권 이외에 서비스와 관련하여 어떠한 권리도 취득하지 않습니다.<br />
            ⑥ 서비스에 이용자가 게재한 자료의 권리와 책임은 이용자에게 있으며, KERIS는 이용자의 동의 없이 이를 영리적으로 이용할 수 없습니다. 다만, 비영리 목적의 경우에는 그러하지 아니하며, 또한 서비스 내에 게재할 수 있습니다.<br />
            ⑦ 게시자의 사전 동의 없이 이용자는 서비스를 이용하여 얻은 정보를 가공·판매하는 행위 등 서비스에 게재된 자료를 영리 목적으로 이용할 수 없습니다. 단, 게시물의 이용허락표시 라이선스 설정에 따라 이용하는 것은 가능합니다.<br />
            ⑧ KERIS는 이용자가 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제14조 각 호에 해당된다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.</p>

          <div className="termsofuse-sub-title">제 16 조 (정보의 제공)</div>
          <p>① KERIS는 이용자에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서 전자우편이나 서신우편 등의 방법으로 이용자에게 제공할 수 있습니다.<br />
            ② KERIS는 서비스 개선 및 이용자 대상의 맞춤서비스 제공 등의 목적으로 이용자의 동의 하에 추가적인 개인정보를 요구할 수 있습니다.</p>

          <p className="termsofuse-title">제 6 장 손해배상 등</p>
          <div className="termsofuse-sub-title">제 17 조 (손해배상)</div>
          <p>① KERIS는 고의 또는 중과실로 이용자에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.<br />
            ② 이용자가 본 약관을 위반하여 KERIS에 손해를 끼친 경우, 이용자는 KERIS에 대하여 그 손해에 대하여 배상할 책임이 있습니다.</p>

          <div className="termsofuse-sub-title">제 18 조 (면책)</div>
          <p>① KERIS는 이용자가 서비스를 이용하여 기대하는 이익을 얻지 못하였거나 서비스에 게재된 자료 이용으로 인해 발생하는 손해에 대해서는 책임을 지지 않습니다.<br />
            ② KERIS는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.<br />
            ③ KERIS는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 등의 불가항력으로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임을 지지 않습니다.<br />
            ④ KERIS는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임을 지지 않습니다.<br />
            ⑤ KERIS는 이용자의 게시물을 등록 전에 사전심사 하거나 상시적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지 않습니다.</p>

          <p className="termsofuse-title">[ 부칙 ]</p>
          <p>① (시행일) 본 약관은 2022년 11월 30일부터 시행합니다.<br />
            ② (경과조치) 본 약관 시행일 전 종전의 EDU AI 이용약관에 따라 가입한 고객은 변경된 시행일부터 이 약관의 적용을 받습니다.</p>
        </div>
      </div>
    </>
  );
}
