/**
 * @filePath        : src\components\DropdownMenu.js
 * @fileName        : DropdownMenu.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 상단 드롭다운 메뉴 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Link } from "react-router-dom";
import {  setSelectedTabData } from "components/pages/home/SelectedTabData";
import { useNavigate } from 'react-router-dom';
export default function DropdownMenu() {

    const navigate = useNavigate();
    const handleClick = (index) => {
        setSelectedTabData(index);
        navigate("/");
    };
    return (
        <>
            <div>
                <div className="dropdown-grid">

                    <div className="dropdown-grid-item">
                        <div className="model-space-desc-class" id="dropdown-model-class" onClick={() => handleClick(1)}>지도학습</div>
                        <p>
                            · <Link to="/weatherconcept">날씨에 따른 전기 사용량 예상</Link> (회귀 모델)
                        </p>
                        <p>
                            · <Link to="/objectconcept">사진에서 서로 다른 대상 찾아내기</Link> (객체검출 모델)
                        </p>
                        <p>
                            · <Link to="/coldpatientconcept">감기환자 분류하기</Link> (분류 모델)
                        </p>
                        <p>
                            · <Link to="/genderageconcept">성별 나이 분류하기</Link> (이미지분류 모델)
                        </p>
                        <p>
                            · <Link to="/emotionconcept">텍스트 감정 분석하기</Link> (텍스트분류 모델)
                        </p>
                        <p>
                            · <Link to="/wordmatchconcept">단어 맞추기</Link> (의사결정트리 모델)
                        </p>
                        <p>
                            · <Link to="/predictionconcept">공유자전거 수요 예측</Link> (딥러닝 모델)
                        </p>
                    </div>
                    <div className="dropdown-grid-item">
                        <div className="model-space-desc-class" id="dropdown-model-class"  onClick={() => handleClick(2)}>비지도학습</div>
                        <p>
                            · <Link to="/seasonsconcept">사계절 분류하기</Link> (군집화 모델)
                        </p>
                        <p>
                            · <Link to="/newsconcept">AI를 이용한 여러 종류의 글쓰기</Link> (사전학습 모델)
                        </p>
                        <p>
                            · <Link to="/celebrityconcept">나와 유사한 유명인 찾기</Link> (군집화 모델)
                        </p>
                        <p>
                            · <Link to="/masterpiececoncept">사진 명화풍 만들기</Link> (GAN 모델)
                        </p>
                        <p>
                            · <Link to="/writingconcept">키워드로 작문하기</Link> (언어 모델)
                        </p>
                    </div>

                    <div className="dropdown-grid-item">
                        <div className="model-space-desc-class" id="dropdown-model-class"  onClick={() => handleClick(3)}>강화학습</div>
                        <p>
                            · <Link to="/cartpoleconcept">막대 중심 잡기</Link>
                        </p>
                        <p>
                            · <Link to="/arcticconcept">북극에서 살아남기</Link>
                        </p>
                        <p>
                            · <Link to="/selfdrivingconcept">자율 주행 자동차 만들기</Link>
                        </p>
                        <p>
                            · <Link to="/flappyconcept">Flappy Bird</Link> 
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
