/**
 * @filePath        : src\components\sections\boxs\Step.js
 * @fileName        : Step.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 각 단계별 스텝 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import check from "assets/step_check.png";
import ing from "assets/step_ing.png";

export default function Step({ children, step1, state1, step2, state2, step3, state3, step4, state4 }) {
    return (
        <>
            <div className="step-space">
                <div className="step-box">
                    {children}
                </div>
                <div className="step-line">
                    <div className="step-line-circle">
                        {state1 === 0 && <div><img src={ing} alt="img" /></div>}
                        {state1 === 1 && <div><img src={check} alt="img" /></div>}
                        {state1 === 2 && <div id="step-circle-div"></div>}
                    </div>
                    <div className="step-line-border" />
                    <div className="step-line-circle">
                        {state2 === 0 && <div><img src={ing} alt="img" /></div>}
                        {state2 === 1 && <div><img src={check} alt="img" /></div>}
                        {state2 === 2 && <div id="step-circle-div"></div>}
                    </div>
                    <div className="step-line-border" />
                    <div className="step-line-circle">
                        {state3 === 0 && <div><img src={ing} alt="img" /></div>}
                        {state3 === 1 && <div><img src={check} alt="img" /></div>}
                        {state3 === 2 && <div id="step-circle-div"></div>}
                    </div>
                    {step4 && (<>
                        <div className="step-line-border" />
                        <div className="step-line-circle">
                            {state4 === 0 && <div><img src={ing} alt="img" /></div>}
                            {state4 === 1 && <div><img src={check} alt="img" /></div>}
                            {state4 === 2 && <div id="step-circle-div"></div>}
                        </div>
                    </>)}
                </div>
                <div className="step-text">
                    <p>{step1}</p><div />
                    <p>{step2}</p><div />
                    <p>{step3}</p>
                    {step4 && (<><div /><p>{step4}</p></>)}
                </div>
            </div>
        </>
    );
}