/**
 * @filePath        : src\components\pages\reinforcement\cartpole\cartpoleintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 막대 중심 잡기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import playgame from "assets/reinforcement/cartpole/playgame.png";
import understandmodel from "assets/reinforcement/cartpole/understandmodel.png";
import checkresult from "assets/reinforcement/cartpole/checkresult.png";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Card from "components/sections/cards/Card";
import Conceptcheck from "components/sections/boxs/Conceptcheck";

export default function Main() {
    return (
        <>
            <Header learning="강화학습">막대 중심 잡기</Header>
            <HeaderNav to1="/cartpoleconcept" to2="/cartpole" />
            <Conceptcheck>막대 중심 잡기 게임을 해보자</Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="main-desc-p">
                        <p>
                            강화학습은 컴퓨터가 게임을 하면서 스스로 학습해내는 모델입니다. 잘한 행동에는 칭찬을 받고, 못한 행동에는 벌을 받음으로써 컴퓨터
                            스스로 점점 더 주어진 임무를 잘할 수 있게 됩니다.
                        </p>
                        <p>
                            본 프로그램에서는 학생 스스로 게임을 해보면서 강화 학습의 개념을 이해하고 실제 학습 결과를 확인하며 강화 학습 과정 전체를 체험할 수
                            있습니다.
                        </p>
                    </div>
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/2차/05_막대 중심 잡기_사용자 안내서_v1.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={playgame}>
                            게임 플레이
                        </Card>
                        <Card btn="Step 2" img={understandmodel}>
                            모델 이해
                        </Card>
                        <Card btn="Step 3" img={checkresult}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/cartpole/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
