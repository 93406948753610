/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece2\Selectpicture.js
 * @fileName        : Selectpicture.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 카테고리에 따른 이미지 그리드 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import TabBtn from "components/pages/unsupervised/masterpiece/masterpiece2/TabBtn";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import { getPainterindex, setResultImg } from "components/pages/unsupervised/masterpiece/masterpiece1/MasterpieceData";
import ProgressBar from "components/sections/boxs/ProgressBar";

export default function Selectpicture({ onChange }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);


    const handleTabClick = (index) => {
        setSelectedTab(index);
        setLoading(true);
        setSelectedImage(null);
        onChange(false);
        let selectname = null;
        const painterindex = getPainterindex();
        if (index === 0) { selectname = "nature" }
        else if (index === 1) { selectname = "people" }
        else if (index === 2) { selectname = "object" }

        const params = { DATASET_CATE: selectname, ARTIST_NM: `ATS_NM_${painterindex}` };
        axios
            .get(`${URL}/api/unsupervised/gan/picture`, { params })
            .then((result) => {
                setData(result.data.list);
            })
            .catch((error) => {
                console.error('error:', error);
            })
            .finally(() => {
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
            });
    };

    useEffect(() => {
        const painterindex = getPainterindex();
        const params = { DATASET_CATE: "nature", ARTIST_NM: `ATS_NM_${painterindex}` };
        axios
            .get(`${URL}/api/unsupervised/gan/picture`, { params })
            .then((result) => {
                setData(result.data.list);
            })
            .catch((error) => {
                console.log('error: ' + error);
            });
    }, []);

    if (data === null) {
        return <ProgressBar />;
    }
    const handleImageClick = (image) => {
        setResultImg(image);
        setSelectedImage(image);
        onChange(true);
    };
    return (
        <>
            {loading && <ProgressBar />}
            <div className="masterpiece2-tab">
                <div className="masterpiece2-tab-bar-container">
                    <TabBtn isActive={selectedTab === 0} onClick={() => handleTabClick(0)}>
                        풍경
                    </TabBtn>
                    <TabBtn isActive={selectedTab === 1} onClick={() => handleTabClick(1)}>
                        인물
                    </TabBtn>
                    <TabBtn isActive={selectedTab === 2} onClick={() => handleTabClick(2)}>
                        정물
                    </TabBtn>

                </div>
                <div className="masterpiece2-picture-container">
                    {selectedTab === 0 && data && data.length > 0 && (
                        <>
                            {data.map((item, index) => (
                                <div key={index}
                                    className={`masterpiece2-picture-card ${selectedImage === item.TRANS_IMG_PATH ? 'selected' : ''}`}
                                    onClick={() => handleImageClick(item.TRANS_IMG_PATH)}>
                                    <img src={item.ORIGIN_IMG_PATH} alt="img" />
                                </div>
                            ))}
                        </>
                    )}
                    {selectedTab === 1 && (
                        <>
                            {data.map((item, index) => (
                                <div key={index}
                                    className={`masterpiece2-picture-card ${selectedImage === item.TRANS_IMG_PATH ? 'selected' : ''}`}
                                    onClick={() => handleImageClick(item.TRANS_IMG_PATH)}>
                                    <img src={item.ORIGIN_IMG_PATH} alt="img" />
                                </div>
                            ))}
                        </>
                    )}
                    {selectedTab === 2 && (
                        <>
                            {data.map((item, index) => (
                                <div key={index}
                                    className={`masterpiece2-picture-card ${selectedImage === item.TRANS_IMG_PATH ? 'selected' : ''}`}
                                    onClick={() => handleImageClick(item.TRANS_IMG_PATH)}>
                                    <img src={item.ORIGIN_IMG_PATH} alt="img" />
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}