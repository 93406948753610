/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece4\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 결과 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import kedu from "assets/unsupervised/masterpiece/keduIco.png";
import { getResultImg } from "components/pages/unsupervised/masterpiece/masterpiece1/MasterpieceData";
import { Link } from "react-router-dom";
import { getPainterindex } from "components/pages/unsupervised/masterpiece/masterpiece1/MasterpieceData";
import { useState, useEffect } from "react";
import Step from "components/sections/boxs/Step";

export default function Main() {
  const [painter, setPainter] = useState("");

  useEffect(() => {
    if (getPainterindex() === 0) {
      setPainter("폴 세잔");
    } else if (getPainterindex() === 1) {
      setPainter("클로드 모네");
    } else if (getPainterindex() === 2) {
      setPainter("우키 요에");
    } else if (getPainterindex() === 3) {
      setPainter("빈센트 반 고흐");
    }
  }, []);

  return (
    <>
      <Header learning="비지도학습">사진 명화풍 만들기 (GAN 모델)</Header>
      <HeaderNav to1="/masterpiececoncept" to2="/masterpiece" />
      <main>
        <div className="main-step-img">
          <Step
            step1="화가 선택"
            state1={1}
            step2="사진 선택"
            state2={1}
            step3="모델 확인"
            state3={1}
            step4="결과 확인"
            state4={0}
          >
            여러분이 선택한 사진을 명화풍으로 바꾼 결과입니다. 결과가 마음에
            드시나요?
          </Step>
        </div>

        <div className="white-back">
          <div className="coldpatient4-re-btn">
            <Link onClick={() => (window.location.href = "/masterpiece")}>
              다시하기
            </Link>
          </div>

          <div className="masterpiece4-main">
            <div className="masterpiece4-main-left">
              <img src={kedu} alt="kedu icon" />
              <div className="masterpiece4-main-left-textdiv">
                <p>[{painter}] 풍의 그림으로 그려드렸어요 !</p>
              </div>
            </div>
            <div className="masterpiece4-main-right">
              <img src={getResultImg()} alt="resultimg" />
            </div>
          </div>
        </div>

        <div className="main-btn">
          <GreyBtn to="/masterpiece/3"></GreyBtn>
          <BlueBtn to="/">체험 종료</BlueBtn>
        </div>
      </main>
    </>
  );
}
