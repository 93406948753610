/**
 * @filePath        : src\components\pages\supervised\weather\weather5\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 점수 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import { getScore } from "components/pages/supervised/weather/weather4/Energydata";
import { Link } from "react-router-dom";
export default function Main() {
  const score = getScore();

  return (
    <>
      <Header learning="지도학습">
        날씨에 따른 전기 사용량 예상 (회귀 모델)
      </Header>
      <HeaderNav to1="/weatherconcept" to2="/weather" />
      <main>
        <div className="scoreboard-space">
          <div className="scoreboard-score">
            <img
              src={require("assets/supervised/weather/socrepoint.png")}
              alt="point"
            />
            <div className="weather5-score-text">
              <p
                style={{ fontSize: "1rem", color: "rgba(255, 255, 255, 0.5)" }}
              >
                ㅤㅤ전력량 예측 모델의 점수는...
              </p>
              <h1 style={{ fontSize: "3rem", color: "white" }}>{score} 점</h1>
            </div>

            <div className="weatehr5-rscore-space">
              <p>
                <b>*점수는 R² 값을 백분율로 부여함</b>
              </p>
              <p>
                R² (R-square) 값은 회귀 모델의 성능을 평가하는 통계적 지표로,
                0과 1 사이 범위의 값을 갖습니다. 1은 모델이 주어진 데이터를
                완벽하게 설명하는 것을 의미하며, 0은 모델이 주어진 데이터를
                설명하지 못한다는 것을 나타냅니다.
              </p>

              <div className="weatehr5-rscore-grid">
                <div id="weather5-grid1">
                  <p>0-40점 : 개선 필요</p>
                </div>
                <div id="weather5-grid2">
                  <p>40-70점 : 보통</p>
                </div>
                <div id="weather5-grid3">
                  <p>70-100점 : 좋음</p>
                </div>
              </div>
            </div>
          </div>
          <div className="scoreboard-score">
            <div className="score-description">
              <p>이번 체험에서 만든 모델은 약 {score}%의 정확도를 보입니다.</p>

              {score >= 70 ? (
                <>
                  <p>
                    해당 모델이 날씨 데이터를 사용해{" "}
                    <b>높은 수준으로 전력량을 예측</b>하는 것을 확인할 수
                    있습니다.
                  </p>
                  <p>
                    전력량에 영향을 끼치는 중요한 변수를 잘 선택하여 알맞은
                    모델로 예측을 진행했네요. 잘했습니다. 😊
                  </p>
                </>
              ) : score >= 40 ? (
                <>
                  <p>
                    해당 모델이 날씨 데이터를 사용해{" "}
                    <b>상당히 높은 수준으로 전력량을 예측</b>하는 것을 확인할 수
                    있습니다.
                  </p>
                  <p>
                    하지만 전력량에 영향을 끼치는 요소를 다시 선택해서, 더욱
                    적합한 모델로 예측을 진행한다면 더 높은 정확도를 기대해볼 수
                    있겠네요.{" "}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    해당 모델이 날씨 데이터를 사용해{" "}
                    <b>낮은 수준으로 전력량을 예측</b>하는 것을 확인할 수
                    있습니다.
                  </p>
                  <p>
                    하지만 전력량에 영향을 끼치는 요소를 다시 선택해서, 더욱
                    적합한 모델로 예측을 진행한다면 더 높은 정확도를 기대해볼 수
                    있겠네요.{" "}
                  </p>
                </>
              )}
              <p>
                회귀 모델의 변수를 바꿔보고 싶다면 변수 선택 단계로 돌아가
                모델을 다시 한번 만들어보세요!
              </p>
              <div className="back-selectdata">
                <Link to="/weather">다시하기</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="main-btn">
          <BlueBtn to="/">체험 종료</BlueBtn>
        </div>
      </main>
    </>
  );
}
