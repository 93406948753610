/**
 * @filePath        : src\components\pages\reinforcement\selfdriving\selfdriving1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 자율 주행 자동차 만들기 모델의 게임플레이 단계 메인화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn"
import Playgame from "components/pages/reinforcement/selfdriving/selfdriving1/Playgame";
import Step from "components/sections/boxs/Step";
import { useState } from "react";
export default function Main() {
    const [state, setState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleNextClick = () => {
        if (state === false) {
            setModalMessage("게임을 5번 진행한 후, 다음 단계로 넘어갈 수 있습니다 !");
            setShowModal(true);
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const handleChange = (newValue) => {
        setState(newValue);
    };
    return (
        <>
            <Header learning="강화학습">자율 주행 자동차 만들기</Header>
            <HeaderNav to1="/selfdrivingconcept" to2="/selfdriving" />

            <main>
                <div className="main-step-img">
                    <Step step1="게임 플레이" state1={0} step2="모델 이해" state2={2} step3="결과 확인" state3={2}>
                        강화 학습의 개념을 이해하기 위해 직접 운전 게임을 해봅시다.<br />
                        자동차가 사고 없이 오랫동안 주행할 수 있도록 플레이해보세요.
                    </Step>
                </div>

                <div>
                    <Playgame onChange={handleChange}/>
                </div>


                <div className="main-btn">
                    <GreyBtn to="/selfdriving"></GreyBtn>
                    {state ? (
                        <BlueBtn to="/selfdriving/2">다음 단계로</BlueBtn>
                    ) : (
                        <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>
                    )}
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}