/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 화가 선택 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import painter1 from "assets/unsupervised/masterpiece/painter1.png";
import painter2 from "assets/unsupervised/masterpiece/painter2.png";
import painter3 from "assets/unsupervised/masterpiece/painter3.png";
import painter4 from "assets/unsupervised/masterpiece/painter4.png";
import { useState } from "react";
import { setPainterindex } from "components/pages/unsupervised/masterpiece/masterpiece1/MasterpieceData";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleImageClick = (index) => {
        setPainterindex(index);
        setSelectedImage(index);
    };
    const handleNextClick = () => {
        setModalMessage("화가를 선택해주세요 !");
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="비지도학습">사진 명화풍 만들기 (GAN 모델)</Header>
            <HeaderNav to1="/masterpiececoncept" to2="/masterpiece" />
            <main>
                <div className="main-step-img">
                    <Step step1="화가 선택" state1={0} step2="사진 선택" state2={2} step3="모델 확인" state3={2} step4="결과 확인" state4={2}>
                        유명 화가들의 작품을 보고 따라 그리고 싶은 화가를 선택해보세요.
                    </Step>
                </div>

                <div className="white-back">

                    <h2 style={{ textAlign: "left", margin: "1rem 2rem 0rem 2rem" }}>화가 선택</h2>
                    <div className="masterpiece1-select-painter">
                        <div className={`masterpiece1-painter ${selectedImage === 0 ? 'selected' : ''}`}
                            onClick={() => handleImageClick(0)}>
                            <div className="masterpiece1-painter-text"><p>• 폴 세잔</p></div>
                            <img src={painter2} alt="painter2" />
                        </div>
                        <div className={`masterpiece1-painter ${selectedImage === 1 ? 'selected' : ''}`}
                            onClick={() => handleImageClick(1)}>
                            <div className="masterpiece1-painter-text"><p>• 클로드 모네</p></div>
                            <img src={painter4} alt="painter4" />
                        </div>
                        <div className={`masterpiece1-painter ${selectedImage === 2 ? 'selected' : ''}`}
                            onClick={() => handleImageClick(2)}>
                            <div className="masterpiece1-painter-text"><p>• 우키요에</p></div>
                            <img src={painter3} alt="painter3" />
                        </div>
                        <div className={`masterpiece1-painter ${selectedImage === 3 ? 'selected' : ''}`}
                            onClick={() => handleImageClick(3)}>
                            <div className="masterpiece1-painter-text"><p>• 빈센트 반 고흐</p></div>
                            <img src={painter1} alt="painter1" />
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/masterpiece"></GreyBtn>
                    {selectedImage === null ? (
                        <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>) : (
                        <BlueBtn to="/masterpiece/2">다음 단계로</BlueBtn>
                    )}
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}><b>닫기</b></button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}