/**
 * @filePath        : src\components\pages\supervised\weather\weatherconcept1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 개념학습 영상 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";


export default function Main() {

    return (
        <>
            <Header learning="지도학습">날씨에 따른 전기 사용량 예상 (회귀 모델)</Header>
            <HeaderNav to1="/weatherconcept" to2="/weather" />
            <Conceptcheck>회귀는 입력 변수와 출력 변수 간의 관계를 모델링하여 새로운 입력에 대한
                    출력을 예상하는 방법입니다. 영상을 통해서 자세히 알아보도록 합시다. </Conceptcheck>
            <main>

                <div className="youtubezone">
                    <iframe width="80%" height="500"
                        src="https://www.youtube.com/embed/F4cg7MA1Msg?si=00pRWxX9ylYujuMi"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ margin: '2rem', border: "none" }}
                    ></iframe>
                </div>

                <BlueBtn to="/weather">
                    체험하기
                </BlueBtn>
            </main>
        </>
    );
}