/**
 * @filePath        : src\components\pages\supervised\prediction\prediction2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 모델 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import bicycleModelImg from "assets/supervised/prediction/bicycleModelImg.png";
import TableInput from "components/pages/supervised/prediction/prediction2/TableInput";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">공유자전거 수요 예측 (딥러닝 모델)</Header>
            <HeaderNav to1="/predictionconcept" to2="/prediction" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 확인" state2={0} step3="결과 확인" state3={2}>
                        모델을 학습하는 과정입니다. 공유자전거 수요예측은 분류와 달리 수량을 예측해야 하는 회귀문제 입니다.
                        <br />
                        회귀문제에서는 모델이 예측한 값과 실젯값을 비교하여 오차(loss)를 계산하고, 이를 이용해 모델을 수정합니다.
                        <br />
                        오차를 계산하는 방법으로는 MAE, MSE, RMSE 등 다양한 방법이 있습니다.
                    </Step>
                </div>

                <div className="white-back">
                    <h2 style={{ textAlign: "left", margin: "1rem 2rem 0rem 2rem" }}>딥러닝 모델, 파라미터 확인</h2>

                    <div className="masterpiece3-parameter-space">
                        <div className="masterpiece3-parameter-space-left">
                            <img src={bicycleModelImg} alt="bicycle model img" />
                        </div>

                        <div className="masterpiece3-parameter-space-right">
                            <TableInput />
                        </div>
                    </div>
                </div>

                <div className="main-btn">
                    <GreyBtn to="/prediction/1"></GreyBtn>
                    <BlueBtn to="/prediction/3">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}
