/**
 * @filePath        : src\components\pages\unsupervised\seasons\seasons2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사계절 분류하기 모델의 모델 이해 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import cluster1 from "assets/unsupervised/seasons/cluster_example1.png";
import cluster2 from "assets/unsupervised/seasons/cluster_example2.png";
import cluster3 from "assets/unsupervised/seasons/cluster_example3.png";
import cluster4 from "assets/unsupervised/seasons/cluster_example4.png";
import { useState } from "react";
import ClusterDesc from "./ClusterDesc";
import ClusterItem from "./ClustersItem";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [step, setStep] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselItems = [
        {
            image: cluster1,
            title: "클러스터 1개일 때 분류 결과",
            desc: "데이터가 1개의 그룹으로 분석됩니다.",
        },
        {
            image: cluster2,
            title: "클러스터 2개일 때 분류 결과",
            desc: "데이터가 2개의 그룹으로 분석됩니다.",
        },
        {
            image: cluster3,
            title: "클러스터 3개일 때 분류 결과",
            desc: "데이터가 3개의 그룹으로 분석됩니다.",
        },
        {
            image: cluster4,
            title: "클러스터 4개일 때 분류 결과",
            desc: "데이터가 4개의 그룹으로 분석됩니다.",
        },
    ];
    const currentItem = carouselItems[currentIndex];

    const prevItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length);
    };
    const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };

    return (
        <>
            <Header learning="비지도학습">사계절 분류하기 (군집화 모델)</Header>
            <HeaderNav to1="/seasonsconcept" to2="/seasons" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 이해" state2={0} step3="결과 확인" state3={2} step4="평가하기" state4={2}>
                        클러스터의 개수를 2로 정하고 5개의 데이터를 클러스터링한 결과는 다음과 같습니다. 클러스터의 개수는 변경할 수 있습니다.<br />
                        이번 모델 체험에서는 날씨를 사계절로 나누어 볼 예정이므로 4개의 클러스터로 나눈 모델을 최종적으로 사용하겠습니다.
                    </Step>
                </div>

                <div className="white-back">
                    {step === 0 && (<>
                        <ClusterDesc />
                    </>)}
                    {step === 1 && (<>
                        <div style={{ position: "relative" }}>
                            <ClusterItem
                                image={currentItem.image}
                                title={currentItem.title}
                                desc={currentItem.desc}
                                thismodel={currentItem.thismodel}
                                onPrevSlide={prevItem}
                                onNextSlide={nextItem} />
                            {/* {currentIndex === 3 && (<>
                                <div style={{ position: "absolute", top: "20%", left: "15%" }}>
                                    <h2>💫해당 모델 사용💫</h2>
                                </div>
                            </>)} */}
                        </div>
                    </>)}


                </div>

                <div className="main-btn">
                    {step === 0 && (<>
                        <GreyBtn to="/seasons/1"></GreyBtn>
                        <BlueBtn onClick={() => setStep(step + 1)}>다음 단계로</BlueBtn>
                    </>)}
                    {step === 1 && (<>
                        <GreyBtn onClick={() => setStep(step - 1)}></GreyBtn>
                        <BlueBtn to="/seasons/3">모델 실행</BlueBtn>
                    </>)}
                </div>
            </main>
        </>
    );
}