/**
 * @filePath        : src\components\pages\unsupervised\writing\writingintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 키워드로 작문하기 모델의 시작 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import Card from "components/sections/cards/Card";
import BlueBtn from "components/sections/buttons/BlueBtn";
import dataexample1 from "assets/unsupervised/keywords/keywordImg01.png";
import dataexample2 from "assets/unsupervised/keywords/keywordImg02.png";
import dataexample3 from "assets/unsupervised/keywords/keywordImg03.png";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import HeaderNav from "components/HeaderNav";

export default function Main() {
    return (
        <>
            <Header learning="비지도학습">키워드로 작문하기 (언어 모델)</Header>
            <HeaderNav to1="/writingconcept" to2="/writing" />
            <Conceptcheck>
                사전학습 모델 중 트랜스포머(Transformer)모델은 문장 속 단어와 같은 순차 데이터 내의 관계를 추적해 맥락과 의미를 학습하는 딥러닝 모델입니다.
                <br />본 프로그램은 사전 학습된 딥러닝 모델을 이용하여 입력 받은 키워드로 짧거나 긴 문장을 작문하는 과정을 체험합니다.
            </Conceptcheck>

            <main>
                <div className="main-desc">
                    <div className="manual-space">
                        <div className="manual-btn">
                            <a href="/manual/1차/03_키워드로 작문하기_사용자 안내서_v4.1.pdf" download>
                                사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>
                    <div className="main-card">
                        <Card btn="Step 1" img={dataexample1}>
                            데이터 입력
                        </Card>
                        <Card btn="Step 2" img={dataexample2}>
                            모델 추론
                        </Card>
                        <Card btn="Step 3" img={dataexample3}>
                            결과 확인
                        </Card>
                    </div>
                </div>

                <BlueBtn to="/writing/1" onClick={() => sessionStorage.clear()}>
                    시작하기
                </BlueBtn>
            </main>
        </>
    );
}
