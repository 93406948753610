/**
 * @filePath        : src\components\pages\supervised\emotion\emotion1\Result.js
 * @fileName        : Result.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 텍스트 감정 분석하기 토큰화 설명 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { getText, getAnsText } from "components/pages/supervised/emotion/emotion1/EmotionData";

export default function Result() {
    const text = getText();
    const ans_text = getAnsText();

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0% 5%" }}>
                <p>토큰화 설명</p>
                <p>전체 : <b style={{ color: "orange" }}>20</b> 건</p>
            </div>

            <div className="emotion1-grid-container">
                <div className="emotion1-grid-result" style={{ textAlign: "left" }}>
                    <p style={{ paddingLeft: "2%" }}>Document</p>
                    <p style={{ borderLeft: "solid 3px #ddd", padding: "0% 2%" }}>Electra Tokens</p>
                </div>


                <div className="emotion1-grid-selectspace">
                    {text.map((item, index) => (
                        <div className="emotion1-grid-result"  key={index}>
                            <p style={{ textAlign: "right" }}>{item}</p>
                            <p style={{ textAlign: "right" }} >{ans_text[index]}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

