/**
 * @filePath        : src\components\pages\reinforcement\cartpole\cartpole1\Playgame.js
 * @fileName        : Playgame.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 막대 중심 잡기 모델의 게임 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useRef, useState, useEffect } from 'react';

export default function Playgame({ onChange }) {
    const [trys, setTrys] = useState([]);
    const iframeRef = useRef(null);

    const handleScore = (newScoreValue) => {
        setTrys(prevTrys => [...prevTrys, newScoreValue]);
    };

    //html 파일에서 score 값 변화 감지해서 가져오기 
    const handleIframeLoad = () => {
        const iframeContent = iframeRef.current.contentDocument;
        const targetElement = iframeContent.querySelector('#score');

        if (targetElement) {
            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.type === 'childList') {
                        handleScore(targetElement.textContent);
                    }
                });
            });
            observer.observe(targetElement, { childList: true });
        }
    };

    //게임 5번 후, 다음화면으로 넘어갈 수 있는 flag 를 true로 변경
    useEffect(() => {
        if (trys.length > 4) {
            onChange(true);
        }
    }, [onChange,trys]);


    // 컴포넌트가 마운트될 때 iframe에 포커스를 설정
    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.focus();
        }
    }, []);

    return (
        <>
            <div className="cartpole-playgame">
                <div className="cartpole-playgame-left">
                    <iframe
                        src="/cartpole.html"
                        title="game"
                        style={{ width: "30rem", height: "30rem", border: "none" }}
                        ref={iframeRef}
                        onLoad={() => handleIframeLoad()}
                    />
                </div>

                <div className="cartpole-playgame-right">
                    {trys[4] &&
                        <div className="cartpole-playgame-desc">
                            <p>
                                게임을 모두 마쳤습니다.<br />
                                마지막 게임에서는 막대가 처음보다
                                <b style={{ color: "#513db3" }}>
                                    {trys[4] - trys[0] >= 0
                                        ? ` ${(trys[4] - trys[0]).toFixed(1)} `
                                        : ` ${(trys[0] - trys[4]).toFixed(1)} `}
                                </b>
                                {trys[4] - trys[0] >= 0
                                    ? `초 더 서있었어요 👏`
                                    : `초 더 빨리 떨어졌어요 😥`}
                            </p>
                        </div>
                    }


                    <p style={{ display: "flex", alignItems: "center", color: "#503cb2", fontSize: "20px" }}><img src={require("assets/reinforcement/cartpole/clock.png")} alt="clock" /> <b>ㅤ플레이 한 시간</b></p>
                    <div className="cartpole-record">
                        <div className="cartpole-record-div">
                            <p>1번째 게임  </p>{trys[0] && <b>{trys[0]} 점</b>}
                        </div>
                        <div className="cartpole-record-div">
                            <p>2번째 게임  </p>{trys[1] && <b>{trys[1]} 점</b>}
                        </div>
                        <div className="cartpole-record-div">
                            <p>3번째 게임  </p>{trys[2] && <b>{trys[2]} 점</b>}
                        </div>
                        <div className="cartpole-record-div">
                            <p>4번째 게임  </p>{trys[3] && <b>{trys[3]} 점</b>}
                        </div>
                        <div className="cartpole-record-div">
                            <p>5번째 게임  </p>{trys[4] && <b>{trys[4]} 점</b>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}