/**
 * @filePath        : src\components\pages\unsupervised\masterpiece\masterpiece2\TabBtn.js
 * @fileName        : TabBtn.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 사진 명화풍 만들기 모델의 카테고리 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function TabBtn({ isActive, onClick, children }) {
    return (
      <button
        className={`masterpiece2-tab-button ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        <p>{children}</p>
      </button>
    );
  }