/**
 * @filePath        : src\components\pages\reinforcement\arctic\arctic1\Playgame.js
 * @fileName        : Playgame.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 북극에서 살아남기 모델의 게임 로직 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from 'react';
import GridCell from './GridCell';
import Penguin from './Penguin';

export default function Playgame({ onChange }) {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isMineHit, setIsMineHit] = useState(false);
    const [trys, setTrys] = useState([]);
    const [trynum, setTryNum] = useState(1);
    const [selectedMineSet, setSelectedMineSet] = useState(null);


    //컴포넌트가 렌더링 되었을 때, 6개의 지뢰 세트 중 하나를 랜덤으로 세팅
    useEffect(() => {
        const mineSets = [
            [{ x: 0, y: 1 }, { x: 2, y: 1 }, { x: 2, y: 2 }, { x: 2, y: 4 }, { x: 3, y: 3 }, { x: 4, y: 1 },],
            [{ x: 0, y: 2 }, { x: 1, y: 0 }, { x: 2, y: 3 }, { x: 3, y: 1 }, { x: 3, y: 2 }, { x: 3, y: 3 },],
            [{ x: 0, y: 3 }, { x: 1, y: 1 }, { x: 3, y: 1 }, { x: 3, y: 2 }, { x: 2, y: 4 }, { x: 4, y: 3 },],
            [{ x: 1, y: 2 }, { x: 1, y: 4 }, { x: 2, y: 0 }, { x: 2, y: 2 }, { x: 2, y: 4 }, { x: 4, y: 2 },],
            [{ x: 0, y: 2 }, { x: 2, y: 1 }, { x: 2, y: 3 }, { x: 2, y: 4 }, { x: 3, y: 3 }, { x: 4, y: 0 },],
            [{ x: 1, y: 0 }, { x: 1, y: 2 }, { x: 1, y: 4 }, { x: 2, y: 2 }, { x: 3, y: 3 }, { x: 4, y: 1 },],
        ];
        const randomSetIndex = Math.floor(Math.random() * mineSets.length);

        if (mineSets[randomSetIndex].length > 0) {
            setSelectedMineSet(mineSets[randomSetIndex]);
        }

    }, []);

    const handleMove = (dx, dy) => {
        const newX = position.x + dx;
        const newY = position.y + dy;


        if (newX >= 0 && newX < 5 && newY >= 0 && newY < 5) {
            setTryNum(trynum + 1);
            setPosition({ x: newX, y: newY });

            //지뢰를 밟았을 때
            if (
                (newX === selectedMineSet[0].x && newY === selectedMineSet[0].y) ||
                (newX === selectedMineSet[1].x && newY === selectedMineSet[1].y) ||
                (newX === selectedMineSet[2].x && newY === selectedMineSet[2].y) ||
                (newX === selectedMineSet[3].x && newY === selectedMineSet[3].y) ||
                (newX === selectedMineSet[4].x && newY === selectedMineSet[4].y) ||
                (newX === selectedMineSet[5].x && newY === selectedMineSet[5].y)
            ) {
                setIsMineHit({ x: newX, y: newY });

                setTimeout(() => {
                    setIsMineHit(null);
                    setPosition({ x: 0, y: 0 }); // Reset position
                }, 500);
            }
            //도착지점에 도착했을 때
            else if ((newX === 4 && newY === 4)) {
                setTrys(prevTrys => [...prevTrys, trynum]);
                setTryNum(1);

                setTimeout(() => {
                    setIsMineHit(null);
                    setPosition({ x: 0, y: 0 }); // Reset position
                }, 500);
            }
        }
    };

    //게임 5번 후, 펭귄 이동 막기
    const handleMove2 = () => {
        return;
    };

    //게임 5번 후, 다음화면으로 넘어갈 수 있는 flag 를 true로 변경
    useEffect(() => {
        if (trys.length > 4) {
            onChange(true);
        }
    }, [trys, onChange]);

    return (

        <div className="cartpole-playgame">
            <div className="cartpole-playgame-left">
                <div className='arctic-game-space'>
                    <h1 style={{ margin: "1rem" }}>Penguin Game</h1>
                    <div className="arctic-grid">
                        {[0, 1, 2, 3, 4].map((y) =>
                            [0, 1, 2, 3, 4].map((x) => (
                                <GridCell
                                    key={`${x}-${y}`}
                                    isPenguin={position.x === x && position.y === y}
                                    isMine={
                                        isMineHit &&
                                        ((x === selectedMineSet[0].x && y === selectedMineSet[0].y) ||
                                            (x === selectedMineSet[1].x && y === selectedMineSet[1].y) ||
                                            (x === selectedMineSet[2].x && y === selectedMineSet[2].y) ||
                                            (x === selectedMineSet[3].x && y === selectedMineSet[3].y) ||
                                            (x === selectedMineSet[4].x && y === selectedMineSet[4].y) ||
                                            (x === selectedMineSet[5].x && y === selectedMineSet[5].y))
                                    }
                                    isMineHit={position.x === x && position.y === y}
                                    isGoal={x === 4 && y === 4}
                                />
                            ))
                        )}
                    </div>
                    {(trys.length < 5) ? (
                        <Penguin onMove={handleMove} />) : (
                        <Penguin onMove={handleMove2} />)}
                </div>
            </div>

            <div className="cartpole-playgame-right">
                {trys[4] &&
                    <div className="cartpole-playgame-desc">
                        <p>
                            게임을 모두 마쳤습니다.<br />
                            마지막 게임에서는 펭귄이 처음보다
                            <b style={{ color: "#513db3" }}>
                                {trys[0] - trys[4] >= 0
                                    ? ` ${trys[0] - trys[4]} `
                                    : ` ${trys[4] - trys[0]} `}
                            </b>
                            {trys[0] - trys[4] >= 0
                                ? `칸 더 적게 이동했어요 👏`
                                : `칸 더 많이 이동했어요 😥`}
                        </p>
                    </div>
                }

                <p style={{ display: "flex", alignItems: "center", color: "#503cb2", fontSize: "20px" }}><img src={require("assets/reinforcement/arctic/direction.png")} alt="clock" /> <b>ㅤ펭귄이 이동한 칸 수</b></p>
                <div className="cartpole-record">
                    <div className="cartpole-record-div">
                        <p>1번째 게임  </p>{trys[0] && <b>{trys[0]} 칸</b>}
                    </div>
                    <div className="cartpole-record-div">
                        <p>2번째 게임  </p>{trys[1] && <b>{trys[1]} 칸</b>}
                    </div>
                    <div className="cartpole-record-div">
                        <p>3번째 게임  </p>{trys[2] && <b>{trys[2]} 칸</b>}
                    </div>
                    <div className="cartpole-record-div">
                        <p>4번째 게임  </p>{trys[3] && <b>{trys[3]} 칸</b>}
                    </div>
                    <div className="cartpole-record-div">
                        <p>5번째 게임  </p>{trys[4] && <b>{trys[4]} 칸</b>}
                    </div>
                </div>
            </div>
        </div>

    );
};


