/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 모델성능평가 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import { getModelindex } from "components/pages/supervised/coldpatient/coldpatient2/Modelindex";
import emotionEstimation from "assets/supervised/coldpatient/emotionEstimation.png";
import Table from "components/pages/supervised/coldpatient/coldpatient3/Table";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const modelindex = getModelindex();

    return (
        <>
            <Header learning="지도학습">감기환자 분류하기 (분류 모델)</Header>
            <HeaderNav to1="/coldpatientconcept" to2="/coldpatient" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 선택" state2={1} step3="모델성능평가" state3={0} step4="결과 확인" state4={2}>
                        학습한 모델은 정상과 감기환자, 둘 중 어느것인지 예측하는 모델로 예측하는 클래스가 2가지 이므로 이진분류라고 부릅니다.<br />
                        이진분류모델의 성능을 평가하는 지표에는 정확도, 정밀도, 재현율 등이 있습니다. 학습한 모델의 평가지표를 보고 모델이 적절히 학습되었는지 평가해봅시다.
                    </Step>
                </div>
                <div className="white-back">
                    <div className="coldpatient3-main">

                        <div className="coldpatient3-main-left">
                            <img src={emotionEstimation} alt="chart" />
                        </div>
                        <div className="coldpatient3-main-right">
                            <img src={require(`assets/supervised/coldpatient/modelchart${modelindex}.png`)} alt="chart" />
                            <Table />
                        </div>
                    </div>
                </div>
                <div className="main-btn">
                    <GreyBtn to="/coldpatient/2"></GreyBtn>
                    <BlueBtn to="/coldpatient/4">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}