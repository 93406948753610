/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrityconceptintro\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 개념학습
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import Conceptcheck from "components/sections/boxs/Conceptcheck";
import example1 from "assets/unsupervised/seasons/seasons_example1.png";
import example2 from "assets/unsupervised/seasons/seasons_example2.png";
import example3 from "assets/unsupervised/seasons/seasons_example3.png";
import example4 from "assets/unsupervised/seasons/seasons_example4.png";
import Carousel from "components/sections/boxs/Carousel";

export default function Main() {
    const carouselItems = [
        {
            image: example1,
            title: "추천 시스템",
            desc1: "클러스터링은 현대 데이터 분석 분야에서 중요한 역할을 하는 기술 중 하나로, 이를 활용하여 소셜 네트워크 서비스(SNS) 사용자들을 공통된 관심사를 가진 그룹으로 묶을 수 있습니다. 이렇게 그룹화된 사용자들은 유사한 관심사와 행동 패턴을 가질 가능성이 높습니다. 이러한 클러스터(묶어진 그룹)를 기반으로 추천 시스템을 구축할 수 있습니다.",
            desc2: "예를 들어, 특정 관심사를 가진 사용자 그룹에게 해당 관심사에 관련된 제품이나 콘텐츠를 추천하거나, 그룹 내에서 인기 있는 항목을 강조하여 보여줄 수 있습니다.",
            page: "1/4"
        },
        {
            image: example2,
            title: "검색 엔진",
            desc1: "검색 엔진은 핵심어를 이용해서 인터넷상의 정보를 찾아 주는 도구 또는 서비스를 말합니다.",
            desc2: "인터넷상의 문자 데이터 내에서 핵심적인 정보를 찾아내고 관련성이 있는 내용을 그룹화 또는 분류합니다. 이런 그룹화와 분류를 통해 사용자가 요구하는 특정 주제에 대한 효과적인 정보를 연결하여 제공합니다. 이런 과정을 통하여 개인에게 맞는 정보 검색 결과를 제공해주는 시스템이 검색 엔진입니다. ",
            page: "2/4"
        },
        {
            image: example3,
            title: "관광 서비스 플랫폼",
            desc1: "관광 및 여행 분야에서 클러스터링 기술은 특정 그룹의 관광객들을 유사한 특성을 가진 군집으로 묶어 내어 군집별 특징을 파악하는 데에 큰 역할을 하고 있습니다.",
            desc2: "예를 들어, 이들의 여행 성향이 관광지 중심인지 휴양지 중심인지를 분석하여 비슷한 성향을 가진 관광객들을 함께 묶어낼 수 있습니다. 이러한 군집 분석을 통해 해당 군집의 특성을 파악하고, 그들이 선호하는 여행 스타일이나 관심사를 추론할 수 있습니다.",
            page: "3/4"
        },
        {
            image: example4,
            title: "별자리",
            desc1: "클러스터링은 별자리와 유사한 개념을 데이터 분석에 적용하는 방식으로 활용될 수 있습니다. 데이터 클러스터링은 데이터의 숨겨진 패턴을 발견하고, 비슷한 특성을 가진 데이터들의 그룹을 식별하여 의사 결정을 지원하는 데 사용됩니다.",
            desc2: "따라서 데이터 클러스터링은 별자리의 개념을 데이터 분석 영역으로 확장한 것으로 볼 수 있으며, 두 개념은 데이터와 천문학 모두에서 유사한 아이디어를 공유하고 있음을 나타냅니다.",
            page: "4/4"
        },
    ];
    return (
        <>
            <Header learning="비지도학습">나와 유사한 유명인 찾기 (군집화 모델)</Header>
            <HeaderNav to1="/celebrityconcept" to2="/celebrity" />
            <Conceptcheck> 클러스터링(군집화)은 비지도학습의 대표적인 방법으로 주어진 데이터를 비슷한 유형끼리 몇 개의 그룹으로 묶어서 구별하는 기법입니다.
                <br />클러스터링은 일상생활에서 다양하게 활용되고 있습니다.
                가장 대표적인 예로 추천 시스템, 검색 엔진 등이 있습니다. </Conceptcheck>

            <main >
                <Carousel items={carouselItems} />

                <BlueBtn to="/celebrityconcept/1">
                    개념영상 시청하기
                </BlueBtn>
            </main>
        </>
    );
}