/**
 * @filePath        : src\components\Header.js
 * @fileName        : Header.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 각 페이지 상단 학습 분류 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
export default function Header({children,learning}) {
    return (
        <header>
            <div style={{
                fontSize: "13px", color: "gray",
                marginTop:"10px",
                display: "flex",
                justifyContent: "right"
            }}>
                <img src={require('assets/headernav-house.png')} alt="img"></img>
                ㅤ&gt;ㅤAI 체험 프로그램ㅤ&gt;ㅤ{learning}
            </div>
            <p style={{ textAlign: "left", marginTop: "0" }}><b>{children}</b></p>
        </header>
    );
}