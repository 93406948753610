/**
 * @filePath        : src\components\pages\supervised\genderage\genderage4\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Step from "components/sections/boxs/Step";
import Classification from "components/pages/supervised/genderage/genderage4/Classification";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">성별 나이 분류하기 (이미지 분류모델)</Header>
            <HeaderNav to1="/genderageconcept" to2="/genderage" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 선택" state2={1} step3="모델 평가" state3={1} step4="결과 확인" state4={0}>
                        모델이 추론한 결과를 확인해봅시다. 성별과 나이 잘 예측했나요?<br />
                        만일 그렇지 않다면 레이블링을 변경하여 결과를 다시 확인해보세요.
                    </Step>
                </div>

                <div className="white-back">
                    <Classification />
                </div>


                <div className="main-btn">
                    <GreyBtn to="/genderage/3"></GreyBtn>
                    <BlueBtn to="/">체험 종료</BlueBtn>
                </div>
            </main>
        </>
    );
}

