/**
 * @filePath        : src\components\pages\Introduce.js
 * @fileName        : Introduce.js
 * @author          : GHYANG
 * @date            : 2023.11.22
 * @description     : EDU AI 소개 페이지
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.22		 GHYANG				 1.0
 */
import backgroundimg from "assets/introduce_back.jpg";
import weather from "assets/home/메인썸네일_날씨.png";
import object from "assets/home/메인썸네일_다른대상.png";
import coldpatient from "assets/home/메인썸네일_감기.png";
import genderage from "assets/home/genderage.jpg";
import emotion from "assets/home/emotion.jpg";
import seasons from "assets/home/seasons.jpg";
import news from "assets/home/메인썸네일_뉴스기사.png";
import celebrity from "assets/home/celebrity.jpg";
import masterpiece from "assets/home/masterpiece.jpg";
import writing from "assets/home/writing.jpg";
import cartpole from "assets/home/메인썸네일_막대중심.png";
import arctic from "assets/home/메인썸네일_북극.png";
import selfdriving from "assets/home/메인썸네일_자율주행.png";
import flappy from "assets/home/flappy.jpg";
import wordmatch from "assets/home/wordmatch.jpg";
import prediction from "assets/home/prediction.jpg";

export default function Introduce() {
    return (
        <>
            <div style={{ width: "100%", left: "0", position: "absolute" }}>
                <div className="introduce-header">
                    <img src={backgroundimg} alt="background-img" />
                    <p>EDU AI 소개</p>
                </div>
            </div>

            <main>
                <div style={{ height: "11rem" }}></div>
                <div className="introduce-main">
                    <div style={{ width: "86%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ width: "14rem" }} />
                        <p style={{ fontSize: "2rem", fontWeight: "500", marginBottom: "15px" }}>인공지능 학습 플랫폼 EDU AI</p>
                        <div className="introduce-manual-btn">
                            <a href="/manual/통합 버전_사용자 안내서_v1.0.pdf" download>
                                통합 버전 사용자 안내서 다운로드
                            </a>
                        </div>
                    </div>

                    <div style={{ height: "2px", width: "3rem", backgroundColor: "#00479F" }} />
                    <p>
                        인공지능 기술에 대한 국내 중고등생들의 흥미와 이해를 높이고 인공지능 역량을 강화하기 위한 인공지능 학습 플랫폼 EDU AI를 소개합니다.
                        <br />
                        EDU AI는 체험과 실습 중심으로 인공지능의 개념의 이해와 활용을 학습목표로 코딩 없이 머신러닝 학습 모델을 쉽게 생성할 수 있는 웹
                        도구입니다.
                    </p>

                    <div className="introduce-class">
                        <p style={{ color: "gray" }}>
                            <b style={{ fontSize: "1.5rem", marginRight: "1rem", color: "black" }}>지도학습</b> Supervised Learning
                        </p>
                        <div style={{ height: "2px", width: "2rem", backgroundColor: "#333333" }} />
                        <p>알고리즘에 정답 데이터를 제공한 후, 제공된 데이터에서 규칙과 패턴을 스스로 학습하는 방식입니다.</p>

                        <div className="introduce-model-grid">
                            <div className="introduce-grid-item">
                                <p id="introduce-green">날씨에 따른 전기 사용량 예상</p>
                                <img src={weather} alt="model-img" />
                                <p id="introduce-blue">
                                    회귀 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">온도, 습도 등의 날씨 데이터를 기반으로 전기 사용량을 예상하는 회귀 모델을 만들어 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">사진에서 서로 다른 대상 찾아내기</p>
                                <img src={object} alt="model-img" />
                                <p id="introduce-blue">
                                    객체검출 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">사람, 가방, 책, 노트북에 대한 라벨링을 하고 학습한 후, 이를 식별해내는 객체검출모델을 만들어 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">감기환자 분류하기</p>
                                <img src={coldpatient} alt="model-img" />
                                <p id="introduce-blue">
                                    분류 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">감기증상 데이터를 이용하여 감기환자를 분류하는 다양한 인공지능 모델을 체험해 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">성별 나이 분류하기</p>
                                <img src={genderage} alt="model-img" />
                                <p id="introduce-blue">
                                    이미지분류 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">
                                    얼굴 이미지에 성별, 나이를 레이블링하고 이를 활용하여 사람의 얼굴로 성별, 나이를 예측하는 모델을 학습해 봅니다.
                                </p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">텍스트 감정 분석하기</p>
                                <img src={emotion} alt="model-img" />
                                <p id="introduce-blue">
                                    텍스트분류 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">사전학습된 언어모델을 활용하여 주어진 문장이 긍정인지 부정인지 예측하는 모델을 학습해 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">단어 맞추기</p>
                                <img src={wordmatch} alt="model-img" />
                                <p id="introduce-blue">
                                    의사결정트리 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">의사 결정 트리의 개념을 이용해 내가 생각한 단어를 맞추는 모델을 만들어 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">공유자전거 수요 예측</p>
                                <img src={prediction} alt="model-img" />
                                <p id="introduce-blue">
                                    딥러닝 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">날짜와 날씨 데이터를 활용하여 공유자전거 수요를 예측해보고 모델이 어떻게 학습하는지 확인해 봅니다.</p>
                            </div>
                        </div>
                    </div>

                    <div className="introduce-class">
                        <p style={{ color: "gray" }}>
                            <b style={{ fontSize: "1.5rem", marginRight: "1rem", color: "black" }}>비지도학습</b> Unsupervised Learning
                        </p>
                        <div style={{ height: "2px", width: "2rem", backgroundColor: "#333333" }} />
                        <p>주어진 데이터에 대해 레이블(정답)을 제공하지 않고 학습하는 방식입니다.</p>

                        <div className="introduce-model-grid">
                            <div className="introduce-grid-item">
                                <p id="introduce-green">사계절 분류하기</p>
                                <img src={seasons} alt="model-img" />
                                <p id="introduce-blue">
                                    군집화 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">
                                    날씨 데이터를 활용하여 군집화를 수행하고, 이를 통해 새로 주어진 날씨를 봄, 여름, 가을, 겨울로 나누어 봅니다.
                                </p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">AI를 이용한 여러 종류의 글쓰기</p>
                                <img src={news} alt="model-img" />
                                <p id="introduce-blue">
                                    사전학습 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">
                                    데이터를 전처리하는 과정을 학습한 후, 만들어진 모델을 이용해서 새로운 단어를 제시해 주었을 때 학생, 옛날 로봇, 임금님,
                                    할아버지의 어투로 AI가 글을 작성해 주는 과정을 체험해 봅니다.
                                </p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">나와 유사한 유명인 찾기</p>
                                <img src={celebrity} alt="model-img" />
                                <p id="introduce-blue">
                                    군집화 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">나의 MBTI와 애니어그램을 입력하여 나와 유사한 군집에 속한 유명인을 찾아 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">사진 명화풍 만들기</p>
                                <img src={masterpiece} alt="model-img" />
                                <p id="introduce-blue">
                                    GAN 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">내 사진을 유명한 화가의 그림처럼 그려주는 딥러닝 모델을 만들어 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">키워드로 작문하기</p>
                                <img src={writing} alt="model-img" />
                                <p id="introduce-blue">
                                    언어 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">선택한 단어에 따라 자동으로 작문해주는 딥러닝 모델을 만들어 봅니다.</p>
                            </div>
                        </div>
                    </div>

                    <div className="introduce-class">
                        <p style={{ color: "gray" }}>
                            <b style={{ fontSize: "1.5rem", marginRight: "1rem", color: "black" }}>강화학습</b> Reinforcement Learning
                        </p>
                        <div style={{ height: "2px", width: "2rem", backgroundColor: "#333333" }} />
                        <p>강화학습은 에이전트가 주어진 환경에서 반복적인 시행착오를 통해 학습하는 방식입니다.</p>

                        <div className="introduce-model-grid">
                            <div className="introduce-grid-item">
                                <p id="introduce-green">막대 중심 잡기</p>
                                <img src={cartpole} alt="model-img" />
                                <p id="introduce-blue">
                                    강화학습 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">막대 중심 잡기 게임을 통해 강화학습 모델이 어떻게 학습되는지 알아봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">북극에서 살아남기</p>
                                <img src={arctic} alt="model-img" />
                                <p id="introduce-blue">
                                    강화학습 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">
                                    펭귄이 길을 찾아가는 과정을 게임으로 체험하고, 모델이 스스로 학습하는 과정을 확인하며 강화학습의 개념을 이해해 봅니다.
                                </p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">자율 주행 자동차 만들기</p>
                                <img src={selfdriving} alt="model-img" />
                                <p id="introduce-blue">
                                    강화학습 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">자율주행 자동차가 스스로 길 찾는 방법을 학습하는 과정을 확인하며 강화학습의 개념을 이해해 봅니다.</p>
                            </div>
                            <div className="introduce-grid-item">
                                <p id="introduce-green">Flappy Bird</p>
                                <img src={flappy} alt="model-img" />
                                <p id="introduce-blue">
                                    강화학습 모델
                                    <br /> <b>• </b>
                                </p>
                                <p id="introduce-desc">Flappy Bird 게임을 통해 강화학습 모델이 어떻게 학습되는지 알아봅니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
