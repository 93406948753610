/**
 * @filePath        : src\components\pages\supervised\genderage\genderage1\Imagecard.js
 * @fileName        : Imagecard.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 데이터 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import { setTrueGender, setNewTrueGender, setTrueAge, setNewTrueAge } from "components/pages/supervised/genderage/genderage1/GenderageData";

export default function Imagecard({ src, new_gender, true_gender, new_age, true_age, index }) {
    const [selectedGender, setSelectedGender] = useState(new_gender); // 초기 선택값
    const [selectedAge, setSelectedAge] = useState(new_age);

    const handleChangeGender = (event) => {
        setSelectedGender(event.target.value);
    };
    const handleChangeAge = (event) => {
        setSelectedAge(event.target.value);
    };


    useEffect(() => {
        setNewTrueGender(index, selectedGender);
        setTrueGender(index, true_gender);
        setNewTrueAge(index, selectedAge);
        setTrueAge(index, true_age);

    }, [index, selectedGender, selectedAge, true_gender, true_age]);




    return (
        <>
            <div className="genderage1-imagecard">
                <div className="genderage1-imagecard-ele">
                    <p><b style={{ color: "orange" }}>·</b> 성별</p>
                    <select value={selectedGender} onChange={handleChangeGender}
                        className={`${(true_gender === selectedGender) ? "" : "genderage1-select-false"}`}>
                        <option value="M">남</option>
                        <option value="F">여</option>
                    </select>
                </div>
                <div className="genderage1-imagecard-ele">
                    <p><b style={{ color: "orange" }}>·</b> 나이</p>
                    <select value={selectedAge} onChange={handleChangeAge}
                        className={`${(true_age === selectedAge) ? "" : "genderage1-select-false"}`}>
                        <option value="0~2">0~2</option>
                        <option value="3~8">3~8</option>
                        <option value="9~14">9~14</option>
                        <option value="15~24">15~24</option>
                        <option value="25~29">25~29</option>
                        <option value="30~44">30~44</option>
                        <option value="45~59">45~59</option>
                        <option value="60~100">60~100</option>
                    </select>
                </div>
                <img src={src} alt="img" />
            </div>
        </>
    );
}

