/**
 * @filePath        : src\components\pages\unsupervised\news\news1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : AI를 이용한 여러 종류의 글쓰기 모델의 데이터 입력 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Preprocessing from "components/pages/unsupervised/news/news1/Preprocessing";
import { useState, useEffect } from "react";
import Step from "components/sections/boxs/Step";
import ProgressBar from "components/sections/boxs/ProgressBar";
import { getNewsTextdata } from "components/pages/unsupervised/news/news1/NewsTextdata";
import back from "assets/unsupervised/news/news_back.png";

export default function Main() {
    const [selectedCategory, setSelectedCategory] = useState("default");
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [text, setText] = useState("");
    const [step, setStep] = useState(0);
    const [data, setData] = useState([]); // 초기 데이터
    const [state, setState] = useState(false);
    const [errortext, setErrortext] = useState("");
    const [selectedindex, setSelectedIndex] = useState(0);
    const [checkflag, setCheckFlag] = useState(false);

    function getRandomItems(arr, num) {
        const result = [];
        const shuffled = arr.slice(); // 배열을 복제하여 섞어줍니다.

        for (let i = arr.length - 1; i >= arr.length - num; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1));
            const item = shuffled[randomIndex];

            result.push(item);
            shuffled[randomIndex] = shuffled[i]; //중복 제거
        }
        return result;
    }

    useEffect(() => {
        setData(getRandomItems(getNewsTextdata(), 8));
        setData(getNewsTextdata());
    }, []);

    useEffect(() => {
        setText("");
        if (selectedCategory === "default") {
            setErrortext("");
        } else {
            let i;
            for (i = 0; i < data.length; i++) {
                if (data[i].category === selectedCategory) {
                    setSelectedIndex(i);
                    setErrortext(data[i].errortext);
                    return;
                }
            }
            // console.log(data.length);
            if (i === data.length) {
                setModalMessage("다른 카테고리도 선택해보세요 !");
                setShowModal(true);
                setErrortext("");
            }
        }
        // eslint-disable-next-line
    }, [selectedCategory, selectedindex]);

    useEffect(() => {
        if (step === 4) {
            setState(true);
        } else {
            setState(false);
        }
    }, [step]);

    if (data[0] === undefined || data === undefined) return <ProgressBar />;

    const handleCategoryChange = (data) => {
        setSelectedCategory(data);
    };

    const handleNextClick = () => {
        if (checkflag) {
            setStep(step + 1);
            setText("");
            setCheckFlag(false);

            const tmpArray = [...data]; // 기존 배열의 복사본을 만듬
            tmpArray.splice(selectedindex, 1); // 해당 인덱스 요소를 삭제
            setData(tmpArray);
        } else {
            setModalMessage("결과를 확인해주세요 !");
            setShowModal(true);
        }
    };

    const handleCheckFlagChange = (flag) => {
        setCheckFlag(flag);
        setText(data[selectedindex].validtext);
    };

    // const handleBeforeClick = () => {
    //     // if (step === 0) {
    //         window.location.href = "/news";
    //     // } else {
    //     //     setStep(step - 1);
    //     //     setText("");
    //     // }
    // };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Header learning="비지도학습">AI를 이용한 여러 종류의 글쓰기 (사전학습 모델)</Header>
            <HeaderNav to1="/newsconcept" to2="/news" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 입력" state1={0} step2="모델 선택" state2={2} step3="결과 확인" state3={2}>
                        사전학습 모델을 사용하기 위해서는 우선 데이터를 수집해서 모델에 입력하는 과정을 완료해야 합니다.
                        <br />
                        해당 <b style={{ color: "red" }}>데이터를 전처리</b>하는 과정을 체험해보세요.
                    </Step>
                </div>

                {step < 4 && (
                    <>
                        <div className="white-back">
                            <div className="grey-back">
                                <p>
                                    ㅤㅤ• 수집한 데이터를 모델에 활용할 수 있게 <b style={{ color: "red" }}>전처리</b> 해봅시다. 문장의 종류에 맞게 카테고리를
                                    부여하고, 단어의 어순이나 오타를 정정하여 문장을 수정해주세요.
                                    <br />
                                    ㅤㅤㅤ
                                    <b>(카테고리: 학생, 옛날 로봇, 임금님, 할아버지)</b>
                                </p>
                            </div>
                            {step === 0 && (
                                <Preprocessing
                                    onCategoryChange={handleCategoryChange} // 카테고리 선택관련
                                    errortext={errortext} // 데이터 띄우기 관련
                                    validtext={text} // 변경된 예시 문장 결과 관련
                                    onCheckFlagChange={handleCheckFlagChange}
                                />
                            )}
                            {step === 1 && (
                                <Preprocessing
                                    onCategoryChange={handleCategoryChange}
                                    errortext={errortext}
                                    validtext={text}
                                    onCheckFlagChange={handleCheckFlagChange}
                                />
                            )}

                            {step === 2 && (
                                <Preprocessing
                                    onCategoryChange={handleCategoryChange}
                                    errortext={errortext}
                                    validtext={text}
                                    onCheckFlagChange={handleCheckFlagChange}
                                />
                            )}
                            {step === 3 && (
                                <Preprocessing
                                    onCategoryChange={handleCategoryChange}
                                    errortext={errortext}
                                    validtext={text}
                                    onCheckFlagChange={handleCheckFlagChange}
                                />
                            )}
                        </div>
                    </>
                )}

                {step === 4 && (
                    <>
                        <div className="newsgood-main">
                            <img src={back} alt="background" />
                            <div className="newsgood-text">
                                <p>
                                    잘했습니다! 이로써 데이터 전처리 과정을 모두 마쳤습니다. 실제 학습에는 이와 같은 데이터가 수천 문장 이상 전처리 된 후
                                    이용됩니다.
                                </p>
                                <h3>이제 완성된 모델을 확인하고 사용해봅시다.</h3>
                            </div>
                        </div>
                    </>
                )}

                <div className="main-btn">
                    <GreyBtn to="/news" />
                    {state ? <BlueBtn to="/news/2">다음 단계로</BlueBtn> : <BlueBtn onClick={handleNextClick}>다음 단계로</BlueBtn>}
                </div>

                {showModal && (
                    <div className="modal-back">
                        <div className="modal">
                            <h3>{modalMessage}</h3>
                            <button onClick={closeModal}>
                                <b>닫기</b>
                            </button>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}
