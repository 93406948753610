/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient1\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 데이터 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Table from "components/pages/supervised/coldpatient/coldpatient1/Table";
import Step from "components/sections/boxs/Step";

export default function Main() {
    return (
        <>
            <Header learning="지도학습">감기환자 분류하기 (분류 모델)</Header>
            <HeaderNav to1="/coldpatientconcept" to2="/coldpatient" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={0} step2="모델 선택" state2={2} step3="모델성능평가" state3={2} step4="결과 확인" state4={2}>
                        감기환자 분류 모델에서 학습에 활용하는 데이터입니다.<br />
                        훈련 데이터에 각각 레이블(정답)을 지정하여 학습합니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="grey-back">
                        <p>
                            ㅤㅤ • 학습 데이터를 살펴보면 환자별로 기침, 근육통, 피로감 등 감기와 관련된 데이터와 구토, 설사, 충혈 등 관련이 없어 보이는 데이터들도 있습니다.<br />
                            ㅤㅤㅤ데이터와 Target을 잘 살펴봅시다. <b>(0은 증상이 없다를 의미하고 1은 증상이 있다를 의미합니다.
)</b>
                        </p>
                    </div>

                    <Table />
                </div>

                <div className="main-btn">
                    <GreyBtn to="/coldpatient"></GreyBtn>
                    <BlueBtn to="/coldpatient/2">다음 단계로</BlueBtn>
                </div>
            </main>
        </>
    );
}