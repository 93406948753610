/**
 * @filePath        : src\components\pages\supervised\object\labeling\Computerlabeling.js
 * @fileName        : Computerlabeling.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 노트북 라벨링 3단계 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Labeling from "components/pages/supervised/object/labeling/Labeling";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';

export default function Computerlabeling() {
    const [step, setStep] = useState(1);
    const [imgs, setImgs] = useState([]);

    const handleStep = (newState) => {
        setStep(newState);
    }

    useEffect(() => {
        // API 요청을 통해 이미지 URL 리스트 가져오기
        axios.get(`${URL}/api/supervised/object/s3/computerlabeling`)
            .then((response) => {
                const objects = response.data; // API 응답이 이미지 키 리스트라고 가정

                // 이미지를 3장씩 묶어서 세트로 만들기
                const imageSets = [];
                for (let i = 0; i < objects.length; i += 3) {
                    const imageSet = objects.slice(i, i + 3);
                    imageSets.push(imageSet);
                }

                // 이미지 세트 중 3개를 랜덤하게 선택
                const selectedSets = [];
                while (selectedSets.length < 3) {
                    const randomIndex = Math.floor(Math.random() * imageSets.length);
                    const selectedSet = imageSets[randomIndex];
                    selectedSets.push(selectedSet);
                    // 중복 선택 방지를 위해 선택된 세트를 제거
                    imageSets.splice(randomIndex, 1);
                }

                // API 응답에 서명된 URL이 포함되어 있다고 가정
                const urls = selectedSets.flatMap(imageSet => {
                    return imageSet.map(item => item); // API가 URL 리스트를 반환한다고 가정
                });
                // 선택된 이미지 URL로 상태 업데이트
                setImgs(urls);
            })
            .catch((error) => {
                console.error('이미지 URL을 가져오는 중 오류 발생:', error);
            });
    }, []);


    return (
        <>
                <div>
                    {step === 1 && (
                        <Labeling
                            img1={imgs[0]}
                            img2={imgs[1]}
                            img3={imgs[2]}
                            step={step}
                            onStepChange={handleStep}
                            obj="computer"
                        />
                    )}
                    {step === 2 && (
                        <Labeling
                            img1={imgs[3]}
                            img2={imgs[4]}
                            img3={imgs[5]}
                            step={step}
                            onStepChange={handleStep}
                            obj="computer"
                        />

                    )}
                    {step === 3 && (
                        <Labeling
                            img1={imgs[6]}
                            img2={imgs[7]}
                            img3={imgs[8]}
                            step={step}
                            onStepChange={handleStep}
                            obj="computer"
                        />
                    )}
                </div>
        </>
    );
}