import 'css/App.css';
import 'css/App_weather.css';
import 'css/App_object.css';
import 'css/App_selfdriving.css';
import 'css/App_arctic.css';
import 'css/App_cartpole.css';
import 'css/App_news.css';
import 'css/App_seasons.css';
import 'fonts/pretendard.css';
import 'css/App_coldpatient.css';
import 'css/App_masterpiece.css';
import 'css/App_prediction.css';
import 'css/App_celebrity.css';
import 'css/App_writing.css';
import 'css/App_wordmatch.css';
import 'css/App_flappy.css';
import 'css/App_genderage.css';
import 'css/App_emotion.css';
import 'css/App_introduce.css';

import Navigation from 'components/Navigation';
import Routing from "components/Routing";
import Footer from "components/Footer";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from "axios";
import { URL } from 'Config.js';

function App() {
  useNavigate();
  // const [shouldRedirect, setShouldRedirect] = useState(true);
  // const pathname = window.location.pathname;
  // const parts = pathname.split('/');
  // const modelname = parts[1];
  // const [newpath, setNewpath] = useState(pathname);
  const curlURL = window.location.href;

  useEffect(() => {
    const requestData = {
      url: curlURL
    };
    const Xauth = {
      headers: { 'X-Auth-Moms-Token': 'token' }
    }

    axios
      .post(`${URL}/comm/insertCommonLog`, requestData, Xauth)
      .then((response) => {
        // console.log('success', curlURL);
      })
      .catch((error) => {
        console.log('error: ' + error);
      })

  }, [curlURL]);

  // useEffect(() => {
  //   if (window.performance.navigation.type === 1) {
  //     setShouldRedirect(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (pathname.includes("/1") || pathname === "/" || pathname.includes("concept") || pathname === `/${modelname}`) {
  //     setShouldRedirect(false);
  //   }
  // }, [pathname, modelname]);

  // useEffect(() => {
  //   if (pathname.includes("/2") || pathname.includes("/3") || pathname.includes("/4") || pathname.includes("/5")) {
  //     setNewpath(`/${modelname}/1`);
  //   }

  //   if (shouldRedirect) {
  //     navigate(newpath);
  //   }
  // }, [shouldRedirect, navigate, newpath, modelname, pathname]);


  return (
    <>
      <Navigation />
      <div className='routing'>
        <Routing />
      </div>
      <Footer />
    </>
  );
}

export default App;