/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import {
  getMbti,
  getType1,
  getType2,
  getCluster2,
} from "components/pages/unsupervised/celebrity/celebrity1/CelebrityData";
import axios from "axios";
import { URL } from "Config.js";
import ProgressBar from "components/sections/boxs/CustomProgressBar";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Chart from "components/pages/unsupervised/celebrity/celebrity3/Chart";
import Table from "components/pages/unsupervised/celebrity/celebrity3/Table";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";
import { Link } from "react-router-dom";

export default function Main() {
  const [userData, setUserData] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [nameListCount, setNameListCount] = useState([]);
  const [chartList, setChartList] = useState([]);

  const [countList, setCountList] = useState([]);

  const [loading, setLoading] = useState(true); // 로딩 상태값

  useEffect(() => {
    const params = {
      MBTI: getMbti(),
      TYPE1: getType1(),
      TYPE2: getType2(),
      CLUSTER: getCluster2(),
    };
    axios
      .post(`${URL}/api/unsupervised/clustering/result`, params)
      .then((result) => {
        setUserData(result.data.userdata);
        setNameList(result.data.namelist);
        setNameListCount(result.data.namelistcount);
        setChartList(result.data.chartlist);

        setCountList(result.data.countlist);
      })
      .catch((error) => {
        console.error("error:", error);
      })
      .finally(() => {
        setLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 설정
      });
  }, []);

  return (
    <>
      {loading && (
        <ProgressBar text1="학습중입니다." text2="잠시만 기다려 주세요." />
      )}{" "}
      {/* loading 값이 true일 때만 프로그래스바 컴포넌트 표시 */}
      <Header learning="비지도학습">
        나와 유사한 유명인 찾기 (군집화 모델)
      </Header>
      <HeaderNav to1="/celebrityconcept" to2="/celebrity" />
      <main>
        <div className="main-step-img">
          <Step
            step1="데이터 입력"
            state1={1}
            step2="군집 분석"
            state2={1}
            step3="결과 확인"
            state3={0}
          >
            나와 유사한 유명인을 확인해볼 차례입니다. 나와 가까운 군집과 나와
            멀리 떨어진 군집의 유명인을 확인해보세요.
            <br />
            결과가 마음에 들지 않는다면 군집의 수를 조정하여 다시 실행해 볼 수
            있습니다.
          </Step>
        </div>

        <div className="white-back">
          <div className="celebrity3-re-btn">
            <Link onClick={() => (window.location.href = "/celebrity")}>
              다시하기
            </Link>
          </div>
          <div className="celebrity2-cluster-space">
            <div className="celebrity2-cluster-space-left">
              <Chart
                receiveUserData={userData}
                receiveChartList={chartList}
                receiveCountList={countList}
              />
            </div>

            <div className="celebrity2-cluster-space-right">
              <Table
                receiveUserData={userData}
                receiveNameList={nameList}
                receiveNameListCount={nameListCount}
              />
            </div>
          </div>
        </div>

        <div className="main-btn">
          <GreyBtn to="/celebrity/2"></GreyBtn>
          <BlueBtn to="/">체험 종료</BlueBtn>
        </div>
      </main>
    </>
  );
}
