/**
 * @filePath        : src\components\pages\supervised\prediction\prediction3\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 공유자전거 수요 예측 모델의 결과 확인 단계 메인 화면
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState } from "react";
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import Step from "components/sections/boxs/Step";
import Chart from "components/pages/supervised/prediction/prediction3/Chart";
import Table from "components/pages/supervised/prediction/prediction3/Table";
import GreyBtn from "components/sections/buttons/GreyBtn";
import BlueBtn from "components/sections/buttons/BlueBtn";

export default function Main() {
    const [trainLoss, setTrainLoss] = useState(null);
    const [validationLoss, setValidationLoss] = useState(null);
    const [yMax, setYMax] = useState(null);

    const handleTrainLoss = (data) => {
        setTrainLoss(data);
    };

    const handleValidationLoss = (data) => {
        setValidationLoss(data);
    };

    const handleYMax = (data) => {
        setYMax(data);
    };

    return (
        <>
            <Header learning="지도학습">공유자전거 수요 예측 (딥러닝 모델)</Header>
            <HeaderNav to1="/predictionconcept" to2="/prediction" />
            <main>
                <div className="main-step-img">
                    <Step step1="데이터 확인" state1={1} step2="모델 확인" state2={1} step3="결과 확인" state3={0}>
                        모델 학습이 완료되었습니다. 모델이 학습하는 단계별로 RMSE값이 어떻게 변화하는지 확인해봅시다.
                        <br />
                        왼쪽에 있는 그래프는 학습데이터세트에 대한 RMSE값이며, 오른쪽의 그래프는 검증데이터세트에 대한 RMSE값 입니다.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="prediction3-chart-space">
                        <div className="prediction3-chart-left">
                            <Chart
                                yMax={yMax}
                                label="학습데이터 RMSE"
                                receiveLoss={trainLoss}
                                borderColor="rgb(54, 162, 235)"
                                backgroundColor="rgba(54, 162, 235, 0.5)"
                            />
                        </div>

                        <div className="prediction3-chart-right">
                            <Chart
                                yMax={yMax}
                                label="검증데이터 RMSE"
                                receiveLoss={validationLoss}
                                borderColor="rgb(246, 206, 236)"
                                backgroundColor="rgba(246, 206, 236, 0.5)"
                            />
                        </div>
                    </div>

                    <Table sendTrainLoss={handleTrainLoss} sendValidationLoss={handleValidationLoss} sendYMax={handleYMax} />
                </div>

                <div className="main-btn">
                    <GreyBtn to="/prediction/2"></GreyBtn>
                    <BlueBtn to="/">체험 종료</BlueBtn>
                </div>
            </main>
        </>
    );
}
