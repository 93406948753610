/**
 * @filePath        : src\components\pages\supervised\coldpatient\coldpatient2\ClustersItem.js
 * @fileName        : ClustersItem.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 감기환자 분류하기 모델의 학습 모델 컴포넌트 
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
export default function ClusterItem({ image, title, desc }) {
    return (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ display: "flex",justifyContent:"center", alignItems:"center", width: "70%" }}>
                <img src={image} alt="img"/>
            </div>
            <div style={{ width: "30%", textAlign: "left",marginTop:"5%" }}>
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
        </div>
    );
}