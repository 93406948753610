/**
 * @filePath        : src\components\sections\buttons\BlueBtn.js
 * @fileName        : BlueBtn.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 다음 단계 버튼 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Link } from 'react-router-dom';

export default function BlueBtn({ to, children, onClick }) {
    return (
            <Link className="bluebtn" to={to} onClick={onClick}>
                {children}
            </Link>
    );
}