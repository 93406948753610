/**
 * @filePath        : src\components\pages\supervised\genderage\genderage3\TableAge.js
 * @fileName        : TableAge.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 성별 나이 분류하기 모델의 나이 라벨링에 따른 모델 데이터 테이블
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from 'Config.js';
import ProgressBar from "components/sections/boxs/ProgressBar";
import { getALGID } from "components/pages/supervised/genderage/genderage1/GenderageData";
export default function Table() {
    const [data, setData] = useState(null);

    //라벨링 결과에 따른 파라미터 값을 서버에 전달하고 그에 맞는 데이터 받기
    useEffect(() => {
        const params = { ALG_ID: getALGID(), MODEL_ID: "M501" };

        axios
            .get(`${URL}/api/model/algresult`, { params })
            .then((result) => {
                const responseData = JSON.parse(result.data.detailinfo.MODEL_EVAL_RESULT);
                setData(responseData);

            })
            .catch((error) => {
                console.error('error:', error);
            });
    }, []);

    if (data === null) {
        return <ProgressBar />;
    }

    return (
        <>
            <div className="coldpatient3-table-space" >
                <div className="coldpatient3-table-container"
                    id="genderage3-table-scollbar">
                    <table className="coldpatient3-table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Precision</th>
                                <th scope="col">Recall</th>
                                <th scope="col">F1-score</th>
                                <th scope="col">Support</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>0~2</td>
                                <td>{data["0~2"]["precision"].toFixed(2)}</td>
                                <td>{data["0~2"]["recall"].toFixed(2)}</td>
                                <td>{data["0~2"]["f1-score"].toFixed(2)}</td>
                                <td>{data["0~2"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>3~8</td>
                                <td>{data["3~8"]["precision"].toFixed(2)}</td>
                                <td>{data["3~8"]["recall"].toFixed(2)}</td>
                                <td>{data["3~8"]["f1-score"].toFixed(2)}</td>
                                <td>{data["3~8"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>9~14</td>
                                <td>{data["9~14"]["precision"].toFixed(2)}</td>
                                <td>{data["9~14"]["recall"].toFixed(2)}</td>
                                <td>{data["9~14"]["f1-score"].toFixed(2)}</td>
                                <td>{data["9~14"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>15~24</td>
                                <td>{data["15~24"]["precision"].toFixed(2)}</td>
                                <td>{data["15~24"]["recall"].toFixed(2)}</td>
                                <td>{data["15~24"]["f1-score"].toFixed(2)}</td>
                                <td>{data["15~24"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>25~29</td>
                                <td>{data["25~29"]["precision"].toFixed(2)}</td>
                                <td>{data["25~29"]["recall"].toFixed(2)}</td>
                                <td>{data["25~29"]["f1-score"].toFixed(2)}</td>
                                <td>{data["25~29"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>30~44</td>
                                <td>{data["30~44"]["precision"].toFixed(2)}</td>
                                <td>{data["30~44"]["recall"].toFixed(2)}</td>
                                <td>{data["30~44"]["f1-score"].toFixed(2)}</td>
                                <td>{data["30~44"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>45~59</td>
                                <td>{data["45~59"]["precision"].toFixed(2)}</td>
                                <td>{data["45~59"]["recall"].toFixed(2)}</td>
                                <td>{data["45~59"]["f1-score"].toFixed(2)}</td>
                                <td>{data["45~59"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>60~100</td>
                                <td>{data["60~100"]["precision"].toFixed(2)}</td>
                                <td>{data["60~100"]["recall"].toFixed(2)}</td>
                                <td>{data["60~100"]["f1-score"].toFixed(2)}</td>
                                <td>{data["60~100"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>Accuracy</td>
                                <td></td>
                                <td></td>
                                <td>{data["accuracy"].toFixed(2)}</td>
                                <td>{data["macro avg"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>Macro avg</td>
                                <td>{data["macro avg"]["precision"].toFixed(2)}</td>
                                <td>{data["macro avg"]["recall"].toFixed(2)}</td>
                                <td>{data["macro avg"]["f1-score"].toFixed(2)}</td>
                                <td>{data["macro avg"]["support"].toFixed(0)}</td>
                            </tr>
                            <tr>
                                <td>Weighted avg</td>
                                <td>{data["weighted avg"]["precision"].toFixed(2)}</td>
                                <td>{data["weighted avg"]["recall"].toFixed(2)}</td>
                                <td>{data["weighted avg"]["f1-score"].toFixed(2)}</td>
                                <td>{data["weighted avg"]["support"].toFixed(0)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}


