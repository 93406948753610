/**
 * @filePath        : src\components\pages\supervised\weather\weather1\Weatherdata.js
 * @fileName        : Weatherdata.js
 * @author          : GHYANG
 * @date            : 2023.12.06
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 선택된 날씨 요소 데이터 관리 파일
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.12.06		 GHYANG				 1.0
 */




export function setSelectedelementnames(data) {
    sessionStorage.setItem("selectedelementnames", JSON.stringify(data));
}

export function getSelectedelementnames() {
    let selectedelementnames = JSON.parse(sessionStorage.getItem("selectedelementnames"));
    if (selectedelementnames === null) {
        selectedelementnames = [];
    }
    return selectedelementnames;
}



