/**
 * @filePath        : src\components\pages\supervised\weather\weather4\Cal1.js
 * @fileName        : Cal1.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 날씨에 따른 전기 사용량 예상 모델의 실제 전력량 데이터 달력
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import styled from "styled-components";
import Num from "components/pages/supervised/weather/weather4/Num";
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "Config.js";
import { getRealdata } from "components/pages/supervised/weather/weather4/Energydata";

const Day = styled.div`
    padding: 5px;
    background-color: #eeeeee;
    color: black;
    height: 2rem;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Cal() {
    const [weather, setWeather] = useState([]);
    const realdata = getRealdata();

    const date = [
        "8/1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
    ];

    useEffect(() => {
        axios
            .get(`${URL}/api/supervised/weather/elementdata`)
            .then((result) => {
                setWeather(result.data.weather);
            })
            .catch((error) => {
                console.log("error: " + error);
            });
    }, []);

    return (
        <div className="calender2">
            <Day>
                <p style={{ color: "red" }}>일</p>
            </Day>
            <Day>월</Day>
            <Day>화</Day>
            <Day>수</Day>
            <Day>목</Day>
            <Day>금</Day>
            <Day>
                <p style={{ color: "blue" }}>토</p>
            </Day>

            <div className="cal-blank" />
            {date.map((date, index) => (
                <Num key={date} weather={weather[index]} data={realdata[index] !== undefined ? realdata[index] : ""} datacolor={"g"}>
                    {date}
                </Num>
            ))}
            <div className="cal-blank" />
            <div className="cal-blank" />
            <div className="cal-blank" />
        </div>
    );
}
