/**
 * @filePath        : src\components\pages\unsupervised\celebrity\celebrity3\Chart.js
 * @fileName        : Chart.js
 * @author          : GHYANG
 * @date            : 2023.11.16
 * @description     : 나와 유사한 유명인 찾기 모델의 군집분석 결과 컴포넌트
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.16		 GHYANG				 1.0
 */
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Scatter } from "react-chartjs-2";

export default function Chart(props) {
    const { receiveUserData, receiveChartList, receiveCountList } = props;

    ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, ChartDataLabels);

    const options = {
        scales: {
            x: {
                beginAtZero: true,
                max: 18,
                title: {
                    display: true,
                    text: "애니어그램",
                },
            },
            y: {
                beginAtZero: true,
                max: 18,
                title: {
                    display: true,
                    text: "MBTI",
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const name = context.raw.name;
                        const x = context.raw.x.toFixed(2);
                        const y = context.raw.y.toFixed(2);
                        return name + ": " + x + ", " + y;
                    },
                },
            },
            datalabels: {
                formatter: function (value) {
                    return value.label;
                },
                align: "top",
                padding: 10,
                font: {
                    size: "12px",
                },
                color: "#444",
            },
        },
    };

    const backgroundColors = ["orange", "green", "yellow", "blue", "purple"];
    const datasets = receiveCountList.map((item, index) => {
        const label = "군집 " + receiveCountList[index].LABEL;
        const list = receiveChartList.filter((i) => i.LABEL === item.LABEL);
        const data = list.map((item2, index2) => {
            return { x: item2.ATYPE, y: item2.PERSONALITY, id: item2.DATASET_ID, name: item2.DATASET_ID, label: "" };
        });

        return { label: label, data: data, backgroundColor: backgroundColors[index] };
    });

    datasets.unshift({
        label: "나의 데이터",
        data: [
            {
                x: receiveUserData.ATYPE,
                y: receiveUserData.PERSONALITY,
                id: receiveUserData.DATASET_ID,
                name: "나",
                label: "나의 데이터",
            },
        ],
        backgroundColor: "red",
        pointRadius: 15,
        pointHoverRadius: 18,
    });

    const data = {
        datasets: datasets,
    };

    return (
        <>
            <div className="celebrity2-chart">
                <h3><b>•</b>군집분석 결과</h3>
                <Scatter options={options} data={data} />
            </div>
        </>
    );
}
