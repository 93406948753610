/**
 * @filePath        : src\components\pages\supervised\object\object2\Main.js
 * @fileName        : Main.js
 * @author          : GHYANG
 * @date            : 2023.11.15
 * @description     : 사진에서 서로 다른 대상 찾아내기 모델의 모델 이해 단계 스텝형식으로 설명
 * =================================================
 * DATE              AUTHOR             VERSION
 * -------------------------------------------------
 * 2023.11.15		 GHYANG				 1.0
 */
import Header from "components/Header";
import HeaderNav from "components/HeaderNav";
import understandmodel_desc1 from "assets/supervised/object/understandmodel_desc_step1.png";
import understandmodel_desc2 from "assets/supervised/object/understandmodel_desc_step2.png";
import understandmodel_desc3 from "assets/supervised/object/understandmodel_desc_step3.png";
import understandmodel_desc4 from "assets/supervised/object/understandmodel_desc_step4.png";
import BlueBtn from "components/sections/buttons/BlueBtn";
import GreyBtn from "components/sections/buttons/GreyBtn";
import { useState, useEffect } from "react";
import Step from "components/sections/boxs/Step";

export default function Main() {
    const [step, setStep] = useState(1);
    const [btntext, setBtntext] = useState("다음 단계로");

    const handleNextClick = () => {
        setStep(step + 1);
    };
    const handleBeforeClick = () => {
        setStep(step - 1);
    }

    useEffect(() => {
        if (step === 3) {
            setBtntext("모델 실행");
        }
        else {
            setBtntext("다음 단계로");
        }
    }, [step]);

    return (
        <>
            <Header learning="지도학습">사진에서 서로 다른 대상 찾아내기 (객체 검출)</Header>
            <HeaderNav to1="/objectconcept" to2="/object" />
            <main>
                <div className="main-step-img">
                    <Step step1="객체 라벨링" state1={1} step2="모델 이해" state2={0} step3="결과 확인" state3={2}>
                        사진에서 사람을 찾아 라벨링하는 작업을 완료하였습니다. <br />
                        이제 이 데이터를 이용해 모델을 학습시키겠습니다. 모델 구조를 확인해보세요.
                    </Step>
                </div>

                <div className="white-back">
                    <div className="grey-back">
                        <p>ㅤㅤ• 본 체험프로그램에서는 객체 검출 알고리즘 중 하나인 ‘YOLO’를 활용해서 객체 검출을 합니다. </p>
                        <p>ㅤㅤ• "YOLO" 의 객체 검출 과정은 다음과 같습니다.</p>
                    </div>

                    <div className="object2-main" style={{height:"32rem"}}>
                        <div className="object2-main-item" style={{ width: "55%" }}>
                            {step === 1 && (
                                <img src={understandmodel_desc1} alt="img" />
                            )}
                            {step === 2 && (<>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={understandmodel_desc2} alt="img" />
                                    <img src={understandmodel_desc3} alt="img" />
                                </div>

                            </>)}
                            {step === 3 && (
                                <img src={understandmodel_desc4} style={{ width: "100%" }} alt="img" />
                            )}

                        </div>
                        <div className="object2-main-item" style={{ width: "35%" }}>
                            <div style={{ padding: "2% 10%", textAlign: "left" }}>
                                {step === 1 && (<>
                                    <h2>Step 1.</h2>
                                    <p>
                                        사진이 입력되면 가로, 세로를 동일한 그리드 영역(사각형 영역)으로 나눕니다.
                                    </p>
                                </>)}
                                {step === 2 && (<>
                                    <h2>Step 2.</h2>
                                    <p>
                                    박스를 그린 후 각 그리드 영역에 대해서 어디에 사물이 존재하는지 바운딩박스와 박스에 대한 신뢰도 점수를 예측합니다. 
                                    신뢰도가 높을수록 굵게 박스를 그려 줍니다.
                                    </p>
                                    <h2>Step 3.</h2>
                                    <p>
                                        이와 동시에 (2)에서와 같이 어떤 사물인지에 대한 식별 작업이 진행됩니다.
                                    </p>
                                    <h2>Step 4.</h2>
                                    <p>
                                        이 과정을 거친 후 굵은 박스들만 남기고 얇은 박스 즉,
                                        사물이 있을 확률이 낮은 박스들은 지워 줍니다.
                                    </p>
                                </>)}
                                {step === 3 && (<>
                                    <h2>Step 5.</h2>
                                    <p>
                                        최종 경계박스들을 NMS (Non- Maximum Suppression)
                                        알고리즘을 이용해 선별하면 (4) 이미지처럼 객체를 식별하는
                                        박스 3개만 남게 됩니다.
                                    </p>
                                </>)}

                            </div>
                        </div>
                    </div>
                </div>


                <div className="main-btn">
                    {step === 1 ? (
                        <GreyBtn to="/object/1"></GreyBtn>) : (
                        <GreyBtn onClick={handleBeforeClick}></GreyBtn>)}

                    {step === 3 ? (
                        <BlueBtn to="/object/3">{btntext}</BlueBtn>) : (
                        <BlueBtn onClick={handleNextClick}>{btntext}</BlueBtn>)}
                </div>
            </main>
        </>
    );
}

